import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { authUser as authUserApi, loginUser as loginUserApi } from '../api/user'
import { AuthUser, LoginData, User } from '../types/users'

//auth user
export const authUser = createAsyncThunk('auth/auth', async (_, thunkApi) => {
  try {
    return await authUserApi()
  } catch (e) {
    return thunkApi.rejectWithValue('step Error')
  }
})

// user login
export const loginUser = createAsyncThunk(
  'auth/login',
  async (loginData: LoginData, thunkApi) => {
    try {
      return await loginUserApi(loginData)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface authSliceState {
  user?: AuthUser
  isAuthLoading: boolean
  isAuthError?: SerializedError
  isLoginFail: boolean
  loginLoading: boolean
  loginError?: SerializedError
}

const initialState: authSliceState = {
  user: undefined,
  isAuthLoading: true,
  isAuthError: undefined,
  isLoginFail: false,
  loginLoading: false,
  loginError: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoginFail: (state, action: PayloadAction<boolean>) => {
      state.isLoginFail = action.payload
    },
  },
  extraReducers: {
    // auth user
    [authUser.fulfilled.type]: (
      state,
      action: PayloadAction<AuthUser | undefined>,
    ) => {
      state.user = action.payload
      state.isAuthLoading = false
      state.isAuthError = undefined
    },

    [authUser.pending.type]: (state) => {
      state.isAuthLoading = true
      state.isAuthError = undefined
    },

    [authUser.rejected.type]: (state, action) => {
      state.isAuthLoading = false
      state.isAuthError = action.payload
    },

    // login user
    [loginUser.fulfilled.type]: (
      state,
      action: PayloadAction<AuthUser | undefined>,
    ) => {
      state.user = action.payload
      state.isLoginFail = !action.payload
      state.loginLoading = false
      state.loginError = undefined
    },

    [loginUser.pending.type]: (state) => {
      state.loginLoading = true
      state.loginError = undefined
    },

    [loginUser.rejected.type]: (state, action) => {
      state.loginLoading = false
      state.loginError = action.payload
    },
  },
})

export const { setIsLoginFail } = authSlice.actions
export default authSlice.reducer
