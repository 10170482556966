import instance from '../../../api/axios'
import { AuthUser, LoginData } from '../types/users'

export const authUser = async (): Promise<AuthUser | undefined> => {
  const response = await instance.get('/users/auth')
  return response.data
}

export const loginUser = async (
  loginData: LoginData,
): Promise<AuthUser | undefined> => {
  const response = await instance.post('/users/login', loginData)
  return response.data
}
