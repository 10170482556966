import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './Tag.module.scss'
import { TagColors } from '../../types'

interface TagProps {
  children?: ReactNode
  name?: string
  color: TagColors
}

const Tag = (props: TagProps) => {
  const { children, name = 'tag', color } = props
  const tag = useRef<any>()
  const [textColor, setTextColor] = useState<string>('')
  const [backgroundColor, setBackgroundColor] = useState<string>('')

  useEffect(() => {
    if (color) {
      if (color === 'Green') {
        setTextColor('#1cc719')
        setBackgroundColor('#ccfdd7')
      } else if (color === 'Yellow') {
        setTextColor('#a7a31d')
        setBackgroundColor('#f9fac7')
      } else if (color === 'Red') {
        setTextColor('#ff4040')
        setBackgroundColor('#fdc8c8')
      } else if (color === 'Orange') {
        setTextColor('#e37d10')
        setBackgroundColor('#f5c591')
      } else if (color === 'Blue') {
        setTextColor('#050fd2')
        setBackgroundColor('#afb2eb')
      } else if (color === 'Grey') {
        setTextColor('#727272')
        setBackgroundColor('#eaeaea')
      } else if (color === 'Sienna') {
        setTextColor('#a0522d')
        setBackgroundColor('#fecfb9')
      }
    }
  }, [color])

  useEffect(() => {
    if (tag) {
      tag.current.style.backgroundColor = backgroundColor
      tag.current.style.color = textColor
    }
  }, [tag, textColor, backgroundColor])

  return (
    <>
      {children ? (
        <div ref={tag} className={styles.tag}>
          <div className={styles.icon}>{children}</div>
          {name}
        </div>
      ) : (
        <div ref={tag} className={styles.tag}>
          {name}
        </div>
      )}
    </>
  )
}

export default Tag
