import React, { useEffect, useRef } from 'react'
import styles from './IdeasFilters.module.scss'
import { Form } from 'react-bootstrap'
import { RootState } from '../../../../../redux/store'
import { connect } from 'react-redux'
import { IdeaFilters } from '../../types/idea'
import { filterIdeas, setIdeaFilters } from '../../store/idea.slice'

interface IdeasFiltersProps {
  filters: IdeaFilters
  setIdeaFilters: (memoryFilters: IdeaFilters) => void
  filterIdeas: () => void
}

const IdeasFilters = (props: IdeasFiltersProps) => {
  const { filters, setIdeaFilters, filterIdeas } = props

  const timeoutId = useRef<NodeJS.Timeout>()

  const onFilter = () => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      filterIdeas()
    }, 600)
  }

  useEffect(() => {
    onFilter()
  }, [filters])

  return (
    <div className={styles.root}>
      <Form.Control
        value={filters.name}
        onChange={(e: any) =>
          setIdeaFilters({ ...filters, name: e.target.value })
        }
        placeholder="filter by name"
        className={styles.textInput}
      />
      <Form.Control
        value={filters.tag}
        onChange={(e: any) =>
          setIdeaFilters({ ...filters, tag: e.target.value })
        }
        placeholder="filter by tag"
        className={styles.textInput}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  filters: state.idea.filters,
})

export default connect(mapStateToProps, { setIdeaFilters, filterIdeas })(
  IdeasFilters,
)
