import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import MainSelect from '../../../../components/MainSelect/MainSelect'
import styles from './FormStep.module.scss'
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker'
import { getObjective, addStep, updateStep } from '../../store/objective.slice'
import { Option } from '../../../../types'
import { RootState } from '../../../../redux/store'
import { CreateStep, Step } from '../../types/objective'

export interface FormStepProps {
  title: string
  objectiveId: number
  step?: Step
  onClickCancel: () => void
  getObjective: (id: number) => void
  addStep: (step: CreateStep) => void
  updateStep: (step: Step) => void
}

const FormStep = (props: FormStepProps) => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [status, setStatus] = useState<string | null>('')
  const [deadline, setDeadline] = useState<Date | undefined>(undefined)
  const [statuses] = useState<Option[]>([
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Passive',
      value: 'Passive',
    },
  ])
  const {
    title,
    objectiveId,
    step,
    onClickCancel,
    getObjective,
    addStep,
    updateStep,
  } = props

  useEffect(() => {
    if (step) {
      setName(step.name)
      setDescription(step.description)
      setStatus(step.status)
      setDeadline(step.deadline ? new Date(step.deadline) : undefined)
    }
  }, [])

  const onAddNewStep = async () => {
    if (name && description && status) {
      const step: CreateStep = {
        objectiveId,
        name,
        description,
        status,
        createdAt: new Date().toISOString(),
        deadline: deadline ? deadline.toISOString() : undefined,
      }

      await addStep(step)
      getObjective(objectiveId)
      onClickCancel()
    }
  }

  const onUpdateNewStep = async () => {
    if (name && description && status) {
      const newStep: Step = {
        id: step!.id,
        objectiveId,
        name,
        description,
        status,
        createdAt: step!.createdAt,
        deadline: deadline ? deadline.toISOString() : undefined,
        finishedAt: step!.finishedAt,
      }

      await updateStep(newStep)
      getObjective(objectiveId)
      onClickCancel()
    }
  }

  const onSetDeadline = (date: Date) => {
    if (date >= new Date()) {
      setDeadline(date)
    } else {
      setDeadline(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <h2 className={styles.title}>{title}</h2>
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Step name..."
          />
        </Form.Group>
        <MainSelect
          label={'Status'}
          select={
            status ? { label: status, value: status } : { label: '', value: '' }
          }
          setSelect={(status) => setStatus(status.value as string)}
          selects={statuses}
          required
        />
        <Form.Group className={styles.dataPickerContainer}>
          <Form.Label>DeadLine</Form.Label>
          <DatePicker
            onChange={onSetDeadline}
            value={deadline}
            className={styles.picker}
          />
        </Form.Group>
        <Form.Group
          className={styles.textareaContainer}
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Description*</Form.Label>
          <Form.Control
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={styles.textarea}
            as="textarea"
            rows={3}
            placeholder="Step description..."
          />
        </Form.Group>
        <div className={styles.btnContainer}>
          <Button onClick={onClickCancel} variant={'light'}>
            Cancel
          </Button>
          <div className={styles.btnSeparator}></div>
          {step ? (
            <Button
              onClick={onUpdateNewStep}
              className={styles.createBtn}
              variant="success"
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={onAddNewStep}
              className={styles.createBtn}
              variant="success"
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  getObjective,
  addStep,
  updateStep,
})(FormStep)
