import React from 'react'
import { Offcanvas } from 'react-bootstrap'
import styles from './RightModal.module.scss'

interface RightDrawerProps {
  title: string
  show: boolean
  size?: 's' | 'sm' | 'm' | 'ml' | 'l' | 'x' | 'full'
  children: React.ReactNode
  onHide: () => void
}

const RightDrawer = (props: RightDrawerProps) => {
  const {
    show = false,
    title = 'drawer title',
    children,
    size = 'm',
    onHide,
  } = props
  return (
    <div className={styles.root}>
      <Offcanvas
        show={show}
        placement={'end'}
        onHide={onHide}
        className={
          size === 's'
            ? styles.drawerS
            : size === 'sm'
            ? styles.drawerSM
            : size === 'm'
            ? styles.drawerM
            : size === 'ml'
            ? styles.drawerML
            : size === 'l'
            ? styles.drawerL
            : size === 'x'
            ? styles.drawerX
            : styles.drawerFull
        }
      >
        <Offcanvas.Header closeButton className={styles.drawerHeader}>
          <Offcanvas.Title className={styles.drawerTitle}>
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default RightDrawer
