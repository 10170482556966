import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styles from './ObjectivesMain.module.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Nav } from 'react-bootstrap'
import {
  deleteObjective,
  getObjectives,
  refreshObjective,
  setObjective,
  setObjectives,
  updateObjective,
} from '../../store/objective.slice'
import IconButton from '../../../../components/IconButton/IconButton'
import Confirm from '../../../../components/Confirm/Confirm'
import MyLoader from '../../../../components/MyLoader/MyLoader'
import { RootState } from '../../../../redux/store'
import { Objective } from '../../types/objective'
import {
  sortObjectivesByDefault,
  sortObjectivesByStatus,
  sortObjectivesByPriority,
  sortObjectivesByDate,
  sortObjectivesByName,
} from '../../utils/objectivesSorts'
import { BiTask } from 'react-icons/bi'
import { ObjectiveListItem } from '../../components/ObjectiveListItem/ObjectiveListItem'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import FormAddObjective from '../../components/FormAddObjective/FormAddObjective'
import ObjectiveDetails from '../../components/ObjectiveDetails/ObjectiveDetails'
import FormAddWhiteboard from '../../../Whiteboard/components/FormAddObjective/FormAddWhiteboard'
import { Whiteboard } from '../../../Whiteboard/types/whiteboard'
import { deleteWhiteboard } from '../../../Whiteboard/store/whiteboard.slice'

interface ObjectivesMainProps {
  objectives: Objective[]
  objective?: Objective
  whiteboard?: Whiteboard
  getObjectives: () => void
  setObjectives: (objectives: Objective[]) => void
  refreshObjective: (objectiveId: number) => void
  setObjective: (objectives?: Objective) => void
  deleteObjective: (objectiveId: number) => void
  updateObjective: (objective: Objective) => void
  deleteWhiteboard: (whiteboardId: string) => void
}

const ObjectivesMain = ({
  objectives,
  objective,
  whiteboard,
  getObjectives,
  setObjective,
  refreshObjective,
  setObjectives,
  deleteObjective,
  updateObjective,
  deleteWhiteboard,
}: ObjectivesMainProps) => {
  const [objectiveId, setObjectiveId] = useState<number | undefined>()
  const [selectedStatus, setSelectedStatus] = useState<string>('Default')
  const [selectedProperty, setSelectedProperty] = useState<string>('')
  const [selectedDateSortType, setSelectedDateSortType] = useState<string>('')
  const [selectedNameSortType, setSelectedNameSortType] = useState<string>('')
  const [activeObjectives, setActiveObjectives] = useState<Objective[]>([])
  const [pausedObjectives, setPausedObjectives] = useState<Objective[]>([])
  const [finishedObjectives, satFinishedObjectives] = useState<Objective[]>([])
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>('Active')
  const [isActiveAddObjectiveDrawer, setIsActiveAddObjectiveDrawer] =
    useState<boolean>(false)
  const [isActiveEditObjectiveDrawer, setIsActiveEditObjectiveDrawer] =
    useState<boolean>(false)
  const [selectedObjectiveId, setSelectedObjectiveId] = useState<
    number | undefined
  >()
  const [objectiveDetails, setObjectiveDetails] = useState<
    Objective | undefined
  >()

  const [
    isActiveAddObjectiveWhiteboardDrawer,
    setIsActiveAddObjectiveWhiteboardDrawer,
  ] = useState<boolean>(false)
  const [
    isActiveEditObjectiveWhiteboardDrawer,
    setIsActiveEditObjectiveWhiteboardDrawer,
  ] = useState<boolean>(false)
  const [selectedWhiteboardIdForDelete, setSelectedWhiteboardIdForDelete] =
    useState<string | undefined>()

  useEffect(() => {
    getObjectives()
    const screenWidth = window.innerWidth
    screenWidth < 1200 ? setIsSmallScreen(true) : setIsSmallScreen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (objectives?.length > 0) {
      setActiveObjectives(
        objectives.filter((objective) => objective.status === 'Active'),
      )
      setPausedObjectives(
        objectives.filter(
          (objective) =>
            objective.status === 'Paused' || objective.status === 'Passive',
        ),
      )
      satFinishedObjectives(
        objectives.filter((objective) => objective.status === 'Finished'),
      )
    }
  }, [objectives])

  useEffect(() => {
    if (selectedStatus) {
      if (selectedStatus === 'Active') {
        setObjectives(sortObjectivesByStatus(objectives, 'Active'))
      } else if (selectedStatus === 'Paused' || selectedStatus === 'Passive') {
        setObjectives(sortObjectivesByStatus(objectives, 'Paused'))
      } else if (selectedStatus === 'Finished') {
        setObjectives(sortObjectivesByStatus(objectives, 'Finished'))
      } else {
        setObjectives(sortObjectivesByDefault(objectives))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus])

  useEffect(() => {
    if (selectedProperty) {
      if (selectedProperty === 'High') {
        setObjectives(sortObjectivesByPriority(objectives, 'High'))
      } else if (selectedProperty === 'Medium') {
        setObjectives(sortObjectivesByPriority(objectives, 'Medium'))
      } else if (selectedProperty === 'Low') {
        setObjectives(sortObjectivesByPriority(objectives, 'Low'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty])

  useEffect(() => {
    if (selectedDateSortType) {
      if (selectedDateSortType === 'New') {
        setObjectives(sortObjectivesByDate(objectives, 'New'))
      } else if (selectedDateSortType === 'Old') {
        setObjectives(sortObjectivesByDate(objectives, 'Old'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateSortType])

  useEffect(() => {
    if (selectedNameSortType) {
      if (selectedNameSortType === 'A-Z') {
        setObjectives(sortObjectivesByName(objectives, 'A-Z'))
      } else if (selectedNameSortType === 'Z-A') {
        setObjectives(sortObjectivesByName(objectives, 'Z-A'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNameSortType])

  useEffect(() => {
    if (objective && objectiveDetails) {
      setObjectiveDetails(objective)
    }
  }, [objective])

  const onOpenAddObjectiveTable = () => {
    setIsActiveAddObjectiveDrawer(true)
  }

  const onClickCancel = () => {
    setIsActiveAddObjectiveDrawer(false)
  }

  const onOpenObjective = (objective: Objective) => {
    setObjective(objective)
    setIsActiveEditObjectiveDrawer(true)
  }

  const onCancelObjectiveDelete = () => {
    setObjectiveId(undefined)
  }

  const onDeleteObjective = () => {
    objectiveId && deleteObjective(objectiveId)
    setObjectiveId(undefined)
  }

  const onOpenAddWhiteboardDrawer = () => {
    setObjectiveDetails(undefined)
    setIsActiveAddObjectiveWhiteboardDrawer(true)
  }

  const onCloseAddWhiteboardDrawer = () => {
    setObjectiveDetails(objective)
    setIsActiveAddObjectiveWhiteboardDrawer(false)
  }

  const onOpenEditWhiteboardDrawer = () => {
    setObjectiveDetails(undefined)
    setIsActiveEditObjectiveWhiteboardDrawer(true)
  }

  const onCloseEditWhiteboardDrawer = () => {
    setObjectiveDetails(objective)
    setIsActiveEditObjectiveWhiteboardDrawer(false)
  }

  const onDeleteWhiteBoard = async () => {
    await deleteWhiteboard(selectedWhiteboardIdForDelete!)
    await refreshObjective(objective?.id!)
    setSelectedWhiteboardIdForDelete(undefined)
  }

  const onOpenObjectiveDetails = (objective: Objective) => {
    setObjectiveDetails(objective)
    setObjective(objective)
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        {/* <div className={styles.filters}>
          <div className={styles.firstGroupFilters}>
            <Dropdown
              onSelect={(priority: any) =>
                priority && setSelectedProperty(priority)
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                By priority
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={'Low'}>Low</Dropdown.Item>
                <Dropdown.Item eventKey={'Medium'}>Medium</Dropdown.Item>
                <Dropdown.Item eventKey={'High'}>High</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={styles.secondGroupFilters}>
            <Dropdown
              onSelect={(date: any) => date && setSelectedDateSortType(date)}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                By date
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={'New'}>New</Dropdown.Item>
                <Dropdown.Item eventKey={'Old'}>Old</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              onSelect={(value: any) => value && setSelectedNameSortType(value)}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                By name
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey={'A-Z'}>A-Z</Dropdown.Item>
                <Dropdown.Item eventKey={'Z-A'}>Z-A</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}
        <div title={'Create New Objective'} className={styles.addObjective}>
          <IconButton onClick={onOpenAddObjectiveTable}>
            <BiTask className={styles.icon} size={30} />
          </IconButton>
        </div>
      </div>
      <div className={styles.main}>
        {objectives ? (
          <>
            {isSmallScreen ? (
              <div className={styles.smallScreenColumnsContainer}>
                <div className={styles.smallScreenTabs}>
                  <Nav
                    variant="tabs"
                    defaultActiveKey="Active"
                    activeKey={selectedTab}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Paused"
                        onClick={() => setSelectedTab('Paused')}
                      >
                        Paused
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Active"
                        onClick={() => setSelectedTab('Active')}
                      >
                        Active
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Finished"
                        onClick={() => setSelectedTab('Finished')}
                      >
                        Finished
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className={styles.smallScreenColumn}>
                  {selectedTab === 'Active' ? (
                    <div className={styles.items}>
                      {activeObjectives.length > 0 ? (
                        activeObjectives.map((objective, index) => (
                          <ObjectiveListItem
                            key={`${index}-${objective.id}`}
                            objective={objective}
                            updateObjective={updateObjective}
                            openUpdateModal={() => onOpenObjective(objective)}
                            setObjectiveId={setObjectiveId}
                            onDetails={() => onOpenObjectiveDetails(objective)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  ) : selectedTab === 'Paused' ? (
                    <div className={styles.items}>
                      {pausedObjectives.length > 0 ? (
                        pausedObjectives.map((objective, index) => (
                          <ObjectiveListItem
                            key={`${index}-${objective.id}`}
                            objective={objective}
                            updateObjective={updateObjective}
                            openUpdateModal={() => onOpenObjective(objective)}
                            setObjectiveId={setObjectiveId}
                            onDetails={() => onOpenObjectiveDetails(objective)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  ) : (
                    <div className={styles.items}>
                      {finishedObjectives.length > 0 ? (
                        finishedObjectives.map((objective, index) => (
                          <ObjectiveListItem
                            key={`${index}-${objective.id}`}
                            objective={objective}
                            updateObjective={updateObjective}
                            openUpdateModal={() => onOpenObjective(objective)}
                            setObjectiveId={setObjectiveId}
                            onDetails={() => onOpenObjectiveDetails(objective)}
                          />
                        ))
                      ) : (
                        <span>There is no tasks yet</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.columnsContainer}>
                <div className={`${styles.column} ${styles.pausedObjective}`}>
                  <div className={styles.columnTitle}>Paused</div>
                  <div className={`${styles.items} ${styles.pausedObjective}`}>
                    {pausedObjectives.length > 0 ? (
                      pausedObjectives.map((objective, index) => (
                        <ObjectiveListItem
                          key={`${index}-${objective.id}`}
                          objective={objective}
                          updateObjective={updateObjective}
                          openUpdateModal={() => onOpenObjective(objective)}
                          setObjectiveId={setObjectiveId}
                          onDetails={() => onOpenObjectiveDetails(objective)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
                <div className={`${styles.column} ${styles.activeObjective}`}>
                  <div className={styles.columnTitle}>Active</div>
                  <div className={`${styles.items} ${styles.activeObjective}`}>
                    {activeObjectives.length > 0 ? (
                      activeObjectives.map((objective, index) => (
                        <ObjectiveListItem
                          key={`${index}-${objective.id}`}
                          objective={objective}
                          updateObjective={updateObjective}
                          openUpdateModal={() => onOpenObjective(objective)}
                          setObjectiveId={setObjectiveId}
                          onDetails={() => onOpenObjectiveDetails(objective)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
                <div className={`${styles.column} ${styles.finishedObjective}`}>
                  <div className={styles.columnTitle}>Finished</div>
                  <div
                    className={`${styles.items} ${styles.finishedObjective}`}
                  >
                    {finishedObjectives.length > 0 ? (
                      finishedObjectives.map((objective, index) => (
                        <ObjectiveListItem
                          key={`${index}-${objective.id}`}
                          objective={objective}
                          updateObjective={updateObjective}
                          openUpdateModal={() => onOpenObjective(objective)}
                          setObjectiveId={setObjectiveId}
                          onDetails={() => onOpenObjectiveDetails(objective)}
                        />
                      ))
                    ) : (
                      <span>There is no tasks yet</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <MyLoader />
        )}
      </div>
      <RightDrawer
        title={'Add Objective'}
        size="l"
        show={isActiveAddObjectiveDrawer}
        onHide={onClickCancel}
      >
        <FormAddObjective onClickCancel={onClickCancel} />
      </RightDrawer>

      <RightDrawer
        title={'Edit Objective'}
        size="l"
        show={isActiveEditObjectiveDrawer}
        onHide={() => setIsActiveEditObjectiveDrawer(false)}
      >
        {isActiveEditObjectiveDrawer && objective && (
          <FormAddObjective
            objective={objective}
            onClickCancel={() => setIsActiveEditObjectiveDrawer(false)}
          />
        )}
      </RightDrawer>

      <RightDrawer
        title={`${objectiveDetails?.name}`}
        size="l"
        show={!!objectiveDetails}
        onHide={() => setObjectiveDetails(undefined)}
      >
        {objectiveDetails && (
          <ObjectiveDetails
            objective={objectiveDetails}
            openAddWhiteboard={onOpenAddWhiteboardDrawer}
            openEditWhiteboardDrawer={onOpenEditWhiteboardDrawer}
            openDeleteWhiteboardDrawer={(id) =>
              setSelectedWhiteboardIdForDelete(id)
            }
          />
        )}
      </RightDrawer>

      {selectedObjectiveId && (
        <Confirm
          confirm="Are you really want to delete this Objective?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteObjective}
          onCancel={() => setSelectedObjectiveId(undefined)}
        />
      )}
      {objectiveId && (
        <Confirm
          confirm="Are you really want to delete this objective?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteObjective}
          onCancel={onCancelObjectiveDelete}
        />
      )}

      {/* whiteboard drawer and modals */}
      <RightDrawer
        title={'Add Whiteboard'}
        size="m"
        show={isActiveAddObjectiveWhiteboardDrawer}
        onHide={onCloseAddWhiteboardDrawer}
      >
        <FormAddWhiteboard
          onClickCancel={onCloseAddWhiteboardDrawer}
          objectiveId={objective?.id}
        />
      </RightDrawer>

      <RightDrawer
        title={'Edit Whiteboard'}
        size="m"
        show={isActiveEditObjectiveWhiteboardDrawer}
        onHide={onCloseEditWhiteboardDrawer}
      >
        <FormAddWhiteboard
          onClickCancel={onCloseEditWhiteboardDrawer}
          whiteboard={whiteboard}
          objectiveId={objective?.id}
        />
      </RightDrawer>

      {selectedWhiteboardIdForDelete && (
        <Confirm
          confirm="Are you really want to delete this Whiteboard?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteWhiteBoard}
          onCancel={() => setSelectedWhiteboardIdForDelete(undefined)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  objectives: state.objective.objectives,
  objective: state.objective.objective,
  whiteboard: state.whiteboard.whiteboard,
})

export default connect(mapStateToProps, {
  getObjectives,
  deleteObjective,
  refreshObjective,
  updateObjective,
  setObjectives,
  setObjective,
  deleteWhiteboard,
})(ObjectivesMain)
