import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { loginUser, setIsLoginFail } from '../store/auth.slice'
import { RootState } from '../../../redux/store'
import { LoginData } from '../types/users'
import styles from './LoginPage.module.scss'

interface LoginPageProps {
  isLoginFail: boolean
  loginLoading: boolean
  setIsLoginFail: (value: boolean) => void
  loginUser: (loginData: LoginData) => void
}

const LoginPage = ({
  isLoginFail,
  loginLoading,
  setIsLoginFail,
  loginUser,
}: LoginPageProps) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const onLogin = () => {
    setIsLoginFail(false)
    if (email && password) {
      loginUser({ email, password, rememberMe })
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
            />
            {isLoginFail ? (
              <Form.Text className={styles.loginFailText}>
                Wrong email or password
              </Form.Text>
            ) : (
              <div className={styles.emptyText}></div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
            {isLoginFail ? (
              <Form.Text className={styles.loginFailText}>
                Wrong email or password
              </Form.Text>
            ) : (
              <div className={styles.emptyText}></div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              type="checkbox"
              label="Remember me"
            />
          </Form.Group>
          <div className={styles.btnContainer}>
            <Button disabled={loginLoading} onClick={onLogin} variant="primary">
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  loginLoading: state.auth.loginLoading,
  isLoginFail: state.auth.isLoginFail,
})

export default connect(mapStateToProps, { loginUser, setIsLoginFail })(
  LoginPage,
)
