import { Link } from '../types/link'

export const sortLinks = (linksList: Link[]) => {
  const folders = linksList
    .filter((link) => link.type === 'folder')
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

  const links = linksList
    .filter((link) => link.type === 'link')
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))

  return [...folders, ...links]
}
