import React from 'react'
import { Event } from '../../types/event'
import styles from './EventCalendarItem.module.scss'

const EventCalendarItem = (event: any) => {
  return (
    <div className={styles.root}>
      <div>{event.event.title}</div>
    </div>
  )
}

export default EventCalendarItem
