import { RiTodoLine } from 'react-icons/ri'
import { MenuItem } from '../types'
import { BiMemoryCard } from 'react-icons/bi'
import { FaRegLightbulb } from 'react-icons/fa'
import { AiOutlineLink } from 'react-icons/ai'
import { MdOutlineLocationOn, MdPeopleOutline } from 'react-icons/md'
import { PiBowlFood } from 'react-icons/pi'

const notesMenu: MenuItem[] = [
  {
    icon: RiTodoLine,
    name: 'To Dos',
    path: '/notes/to-dos',
  },
  {
    icon: BiMemoryCard,
    name: 'Memories',
    path: '/notes/memories',
  },
  {
    icon: FaRegLightbulb,
    name: 'Ideas',
    path: '/notes/ideas',
  },
  {
    icon: AiOutlineLink,
    name: 'Links',
    path: '/notes/links',
  },
  {
    icon: MdOutlineLocationOn,
    name: 'Locations',
    path: '/notes/locations',
  },
  {
    icon: PiBowlFood,
    name: 'Recipes',
    path: '/notes/recipes',
  },
  {
    icon: MdPeopleOutline,
    name: 'People',
    path: '/notes/people',
  },
]

export default notesMenu
