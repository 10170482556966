import React, { useEffect, useRef } from 'react'
import styles from './MemoriesFilters.module.scss'
import { Form } from 'react-bootstrap'
import { RootState } from '../../../../../redux/store'
import { connect } from 'react-redux'
import { MemoryFilters } from '../../types/memory'
import { filterMemories, setMemoryFilters } from '../../store/memory.slice'

interface MemoriesFiltersProps {
  filters: MemoryFilters
  setMemoryFilters: (memoryFilters: MemoryFilters) => void
  filterMemories: () => void
}

const MemoriesFilters = (props: MemoriesFiltersProps) => {
  const { filters, setMemoryFilters, filterMemories } = props

  const timeoutId = useRef<NodeJS.Timeout>()

  const onFilter = () => {
    clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => {
      filterMemories()
    }, 600)
  }

  useEffect(() => {
    onFilter()
  }, [filters])

  return (
    <div className={styles.root}>
      <Form.Control
        value={filters.name}
        onChange={(e: any) =>
          setMemoryFilters({ ...filters, name: e.target.value })
        }
        placeholder="filter by name"
        className={styles.textInput}
      />
      <Form.Control
        value={filters.tag}
        onChange={(e: any) =>
          setMemoryFilters({ ...filters, tag: e.target.value })
        }
        placeholder="filter by tag"
        className={styles.textInput}
      />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  filters: state.memory.filters,
})

export default connect(mapStateToProps, { setMemoryFilters, filterMemories })(
  MemoriesFilters,
)
