import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'

import { CreateLink, Link } from '../types/link'
import {
  loadLinksTree,
  loadLinksTreeById,
  modifyLink,
  removeLink,
  saveLink,
} from '../api/link'
import { RootState } from '../../../../redux/store'
import { sortLinks } from '../utils/sortLinks'

interface GetLinkById {
  linkTree: Link[]
  parentId: string
}

//get linksTree
export const getLinksTree = createAsyncThunk(
  'links/getLinksTree',
  async (_, thunkApi) => {
    try {
      return await loadLinksTree()
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//get linksTreeById
export const getLinksTreeById = createAsyncThunk(
  'links/getLinksTreeById',
  async ({ id }: { id: string }, thunkApi) => {
    try {
      const linkTree = await loadLinksTreeById(id)
      return { linkTree: linkTree.children, parentId: id }
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//goBackToParentFolder
export const goBackToParentFolder = createAsyncThunk(
  'links/goBackToParentFolder',
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState

      let linksParentsIds = state.link.linksParentsIds
      let linkTree: Link[] = []
      if (linksParentsIds[linksParentsIds.length - 2] === 'root') {
        linkTree = await loadLinksTree()
      } else {
        linkTree =
          (await loadLinksTreeById(linksParentsIds[linksParentsIds.length - 2]))
            ?.children || []
      }

      return linkTree
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//create link
export const addLink = createAsyncThunk(
  'link/addLink',
  async (link: CreateLink, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState
      let linksParentsIds: string[] = state.link.linksParentsIds
      return linksParentsIds.length === 1
        ? await saveLink(link)
        : (await saveLink(link)).children
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//update link
export const updateLink = createAsyncThunk(
  'link/updateLink',
  async (link: Link, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState
      let linksParentsIds: string[] = state.link.linksParentsIds
      return linksParentsIds.length === 1
        ? await modifyLink(link)
        : (await modifyLink(link)).children
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//delete link
export const deleteLink = createAsyncThunk(
  'link/deleteLink',
  async (id: string, thunkApi) => {
    try {
      return await removeLink(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface authSliceState {
  linksTree: Link[]
  linksParentsIds: string[]
  loading: boolean
  error?: SerializedError
}

const initialState: authSliceState = {
  linksTree: [],
  linksParentsIds: ['root'],
  loading: true,
  error: undefined,
}

const linkSlice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    // setSelectedVocabulary: (state: authSliceState, action: PayloadAction<Vocabulary | undefined>) => {
    //   if(action.payload){
    //     state.selectedVocabulary = {
    //       ...action.payload,
    //       words:[...action.payload.words]
    //     }
    //   }else{
    //     state.selectedVocabulary = action.payload
    //   }
    // }
  },
  extraReducers: {
    // get linksTree
    [getLinksTree.fulfilled.type]: (state, action: PayloadAction<Link[]>) => {
      state.linksTree.push(...sortLinks(action.payload))
      state.loading = false
      state.error = undefined
    },

    [getLinksTree.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [getLinksTree.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // get linksTreeById
    [getLinksTreeById.fulfilled.type]: (
      state,
      action: PayloadAction<GetLinkById>,
    ) => {
      state.linksTree = sortLinks(action.payload.linkTree)
      state.linksParentsIds.push(action.payload.parentId)
      state.loading = false
      state.error = undefined
    },

    [getLinksTreeById.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [getLinksTreeById.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    //goBackToParentFolder
    [goBackToParentFolder.fulfilled.type]: (
      state,
      action: PayloadAction<Link[]>,
    ) => {
      state.linksTree = sortLinks(action.payload)
      state.linksParentsIds.pop()
      state.loading = false
      state.error = undefined
    },

    [goBackToParentFolder.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [goBackToParentFolder.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // add link
    [addLink.fulfilled.type]: (state, action: PayloadAction<Link[]>) => {
      state.linksTree = sortLinks(action?.payload)
      state.loading = false
      state.error = undefined
    },

    [addLink.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [addLink.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // update link
    [updateLink.fulfilled.type]: (state, action: PayloadAction<Link[]>) => {
      state.linksTree = sortLinks(action.payload)
      state.loading = false
      state.error = undefined
    },

    [updateLink.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [updateLink.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // delete vocabulary
    [deleteLink.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.linksTree = state.linksTree.filter(
        (link) => link.id !== action.payload,
      )
      state.loading = false
      state.error = undefined
    },

    [deleteLink.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [deleteLink.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {} = linkSlice.actions
export default linkSlice.reducer
