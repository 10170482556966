import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../redux/store'

interface SettingsProps {}

const Settings = ({}: SettingsProps) => {
  return <div>Settings</div>
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {})(Settings)
