import React, { useState } from 'react'
import styles from './FormAddWhiteboard.module.scss'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { CreateWhiteboard, Whiteboard } from '../../types/whiteboard'
import { addWhiteboard, updateWhiteboard } from '../../store/whiteboard.slice'
import { refreshObjective } from '../../../Objectives/store/objective.slice'

interface FormAddWhiteboardProps {
  whiteboard?: Whiteboard
  objectiveId?: number
  onClickCancel: () => void
  addWhiteboard?: (whiteboard: CreateWhiteboard) => void
  updateWhiteboard?: (whiteboard: Whiteboard) => void
  refreshObjective: (whiteboardId: number) => void
}

const FormAddWhiteboard = (props: FormAddWhiteboardProps) => {
  const {
    whiteboard,
    objectiveId,
    onClickCancel,
    addWhiteboard,
    updateWhiteboard,
    refreshObjective,
  } = props
  const [name, setName] = useState<string>(whiteboard?.name || '')

  const onAddWhiteboard = async () => {
    if (name && addWhiteboard) {
      const createdWhiteboard: CreateWhiteboard = {
        name,
        objectiveId,
      }

      await addWhiteboard(createdWhiteboard)
      objectiveId && (await refreshObjective(objectiveId))

      setName('')
      onClickCancel()
    }
  }

  const onUpdateWhiteboard = async () => {
    if (whiteboard && name && updateWhiteboard) {
      const updatedWhiteboard: Whiteboard = {
        ...whiteboard,
        name,
      }

      await updateWhiteboard(updatedWhiteboard)
      objectiveId && (await refreshObjective(objectiveId))
      setName('')
      onClickCancel()
    }
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Whiteboard name..."
        />
      </Form.Group>

      <div className={styles.btnContainer}>
        <div className={styles.btnSeparator}></div>
        {whiteboard ? (
          <Button
            onClick={onUpdateWhiteboard}
            className={styles.createBtn}
            variant="success"
            disabled={!name}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddWhiteboard}
            className={styles.createBtn}
            variant="success"
            disabled={!name}
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addWhiteboard,
  updateWhiteboard,
  refreshObjective,
})(FormAddWhiteboard)
