import React, { ReactNode, SyntheticEvent } from 'react'
import styles from './IconButton.module.scss'

interface IconButtonProps {
  children: ReactNode
  disabled?: boolean
  title?: string
  onClick?: () => void
}

const IconButton = (props: IconButtonProps) => {
  const { children, disabled = false, title, onClick } = props

  const onClickIconButton = (e: SyntheticEvent) => {
    e.stopPropagation()
    !disabled && onClick && onClick()
  }

  return (
    <div onClick={onClickIconButton} className={disabled ? '' : styles.root}>
      <div title={title} className={disabled ? styles.disabled : ''}>
        {children}
      </div>
    </div>
  )
}

export default IconButton
