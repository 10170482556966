import React, { MouseEvent } from 'react'
import styles from './ObjectiveListItem.module.scss'
import MainSelect from '../../../../components/MainSelect/MainSelect'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { format } from 'date-fns'
import { Objective } from '../../types/objective'
import {
  objectivePriorityOptions,
  objectiveStatusOptions,
} from '../../config/objectiveInputSelectOptions'

interface ObjectiveListItemProps {
  objective: Objective
  updateObjective: (objective: Objective) => void
  openUpdateModal: () => void
  setObjectiveId: (objective: number) => void
  onDetails: () => void
}

export const ObjectiveListItem = (props: ObjectiveListItemProps) => {
  const {
    objective,
    updateObjective,
    openUpdateModal,
    setObjectiveId,
    onDetails,
  } = props

  const onChangeStatus = (id: number, status: string) => {
    const updatedObjective: Objective = {
      ...objective,
      status,
    }

    updateObjective(updatedObjective)
  }

  const onChangePriority = (id: number, priority: string) => {
    const updatedObjective: Objective = {
      ...objective,
      priority,
    }

    updateObjective(updatedObjective)
  }

  const onConfirmDeleteObjective = (e: MouseEvent, id: number) => {
    e.stopPropagation()
    setObjectiveId(id)
  }

  return (
    <div className={styles.objective} onClick={onDetails}>
      <div className={styles.top}>
        <div className={styles.name}>{objective.name}</div>
        <div className={styles.buttonContainer}>
          <IconButton>
            <MdModeEdit
              onClick={openUpdateModal}
              className={styles.icon}
              size={25}
            />
          </IconButton>
          <IconButton>
            <MdDelete
              onClick={(e) => onConfirmDeleteObjective(e, objective.id)}
              className={styles.icon}
              size={25}
            />
          </IconButton>
        </div>
      </div>

      <div className={styles.controls}>
        <div onClick={(e) => e.stopPropagation()}>
          <MainSelect
            label={''}
            select={{
              label: objective.status,
              value: objective.status,
            }}
            setSelect={(status) =>
              status && onChangeStatus(objective.id, status.value as string)
            }
            selects={objectiveStatusOptions}
            classNameDropdown={styles.dropdown}
          />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <MainSelect
            label={''}
            select={{
              label: objective.priority,
              value: objective.priority,
            }}
            setSelect={(priority) =>
              priority &&
              onChangePriority(objective.id, priority.value as string)
            }
            selects={objectivePriorityOptions}
            classNameDropdown={styles.dropdown}
          />
        </div>
      </div>

      <div className={styles.deadLineContainer}>
        {objective.deadline ? (
          <span className={styles.deadlineDate}>
            {format(new Date(objective.deadline), 'dd MMMM yyyy')}
          </span>
        ) : (
          <span className={styles.noDate}>No deadline</span>
        )}
      </div>
    </div>
  )
}
