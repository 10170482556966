import React, { useState } from 'react'
import styles from './EventDetails.module.scss'
import { Event } from '../../types/event'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Confirm from '../../../../components/Confirm/Confirm'
import { format } from 'date-fns'

interface EventDetailsProps {
  event: Event
  onUpdate: () => void
  onDelete: (id: string) => void
}

const EventDetails = (props: EventDetailsProps) => {
  const { event, onUpdate, onDelete } = props
  const [showConfirmDeleteEventModal, setShowConfirmDeleteEventModal] =
    useState(false)

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <IconButton onClick={onUpdate}>
          <MdModeEdit size={22} />
        </IconButton>
        <IconButton onClick={() => setShowConfirmDeleteEventModal(true)}>
          <MdDelete size={22} />
        </IconButton>
      </div>
      <div className={styles.section}>
        <span>Priority: </span>
        <span className={styles.priority}>{event.priority}</span>
      </div>
      <div className={styles.section}>
        <span>Periodicity: </span>
        <span className={styles.periodicity}>{event.periodicity}</span>
      </div>
      <div className={styles.section}>
        <span>Type: </span>
        <span className={styles.type}>{event.type}</span>
      </div>
      <div className={styles.section}>
        <span>Dates: </span>
        <span>
          {event.periodicity === 'Once' ? (
            <span className={styles.date}>
              {format(new Date(event.dates[0]), 'yyyy-MM-dd')}
            </span>
          ) : event.periodicity === 'Every Year' ? (
            <span className={styles.date}>
              {format(new Date(event.dates[0]), 'yyyy-MM-dd')}
            </span>
          ) : event.periodicity === 'By Dates' ? (
            <span className={styles.dates}>
              {event.dates.map((date, index) => (
                <span className={styles.date} key={`${index}-${date}`}>
                  {format(new Date(date), 'yyyy-MM-dd')}
                </span>
              ))}
            </span>
          ) : event.periodicity === 'Period' ? (
            <span>
              from{' '}
              <span className={styles.date}>
                {format(new Date(event.dates[0]), 'yyyy-MM-dd')}
              </span>{' '}
              to{' '}
              <span className={styles.date}>
                {format(new Date(event.dates[1]), 'yyyy-MM-dd')}
              </span>
            </span>
          ) : (
            <span></span>
          )}
        </span>
      </div>
      <div className={styles.section}>
        <span>Tags: </span>
        <span className={styles.tags}>
          {event.tags.map((tag, index) => (
            <span className={styles.tag} key={`${index}-${tag}`}>
              {tag}
            </span>
          ))}
        </span>
      </div>
      <div className={styles.section}>
        <span>Description:</span>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: event.description,
            }}
          />
        </div>
      </div>
      <div className={styles.section}>
        <span>Notes: </span>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: event.note,
            }}
          />
        </div>
      </div>
      {showConfirmDeleteEventModal && (
        <Confirm
          confirm="Are you really want to delete this event?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={() => {
            onDelete(event.id)
            setShowConfirmDeleteEventModal(false)
          }}
          onCancel={() => setShowConfirmDeleteEventModal(false)}
        />
      )}
    </div>
  )
}

export default EventDetails
