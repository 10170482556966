import React, { useEffect, useState } from 'react'
import styles from './TagSelect.module.scss'
import { Form } from 'react-bootstrap'

interface TagSelectProps {
  tags: string[]
  placeholder?: string
  disabled?: boolean
  setTags?: (value: string[]) => void
}

const TagSelect = (props: TagSelectProps) => {
  const {
    tags,
    placeholder = 'type tag',
    disabled = false,
    setTags = () => {},
  } = props

  const onKeyDown = (e: any) => {
    if (e.key !== 'Enter') return
    const value = e.target.value
    if (!value.trim()) return
    setTags([...tags, value])
    e.target.value = ''
  }

  const onRemoveTag = (i: number) => {
    setTags(tags.filter((tag: string, index: number) => index !== i))
  }

  return (
    <div className={styles.root}>
      {tags &&
        tags.length > 0 &&
        tags.map((tag: string, index: number) => (
          <div key={`${index}-${tag}`} className={styles.tag}>
            <span className={styles.text}>{tag}</span>
            <span onClick={() => onRemoveTag(index)} className={styles.close}>
              &times;
            </span>
          </div>
        ))}

      <input
        disabled={disabled}
        onKeyDown={onKeyDown}
        type="text"
        className={styles.input}
        placeholder={placeholder}
      />
    </div>
  )
}

export default TagSelect
