import instance from '../../../../api/axios'
import { CreateToDo, ToDo } from '../types/toDo'

export const loadToDoes = async (): Promise<ToDo[]> => {
  const response = await instance.get('/notes')
  return response.data
}

export const loadToDo = async (id: number): Promise<ToDo> => {
  const response = await instance.get(`/notes/${id}`)
  return response.data
}

export const saveToDo = async (toDo: CreateToDo): Promise<ToDo> => {
  const response = await instance.post('/notes', toDo)
  return response.data
}

export const modifyToDo = async (toDo: ToDo): Promise<ToDo> => {
  const response = await instance.put('/notes', toDo)
  return response.data
}

export const removeToDo = async (id: number): Promise<number> => {
  const response = await instance.delete(`/notes/${id}`)
  return response.data
}
