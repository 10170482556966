import instance from '../../../../api/axios'
import { CreateMemory, Memory } from '../types/memory'

export const loadMemories = async (): Promise<Memory[]> => {
  const response = await instance.get('/memories')
  return response.data
}

export const loadMemory = async (id: number): Promise<Memory> => {
  const response = await instance.get(`/memories/${id}`)
  return response.data
}

export const saveMemory = async (memory: CreateMemory): Promise<Memory> => {
  const response = await instance.post('/memories', memory)
  return response.data
}

export const modifyMemory = async (memory: Memory): Promise<Memory> => {
  const response = await instance.put('/memories', memory)
  return response.data
}

export const removeMemory = async (id: number): Promise<number> => {
  const response = await instance.delete(`/memories/${id}`)
  return response.data
}
