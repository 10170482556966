import React from 'react'
import styles from './IdeaDetails.module.scss'
import { Idea } from '../../types/idea'

interface IdeaDetailsProps {
  idea: Idea
}

const IdeaDetails = (props: IdeaDetailsProps) => {
  const { idea } = props

  return (
    <div className={styles.root}>
      <div className={styles.tagsContainer}>
        <span>Tags: </span>
        <span className={styles.tags}>
          {idea?.tags?.length > 0 &&
            idea.tags.map((tag, i) => (
              <span key={`${i}-${tag}-idea-details`} className={styles.tag}>
                {tag}
              </span>
            ))}
        </span>
      </div>

      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: idea.description,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default IdeaDetails
