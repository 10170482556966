import React from 'react'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import IconButton from '../../../../../components/IconButton/IconButton'
import styles from './Recipe.module.scss'
import { format } from 'date-fns'
import { Recipe as RecipeType } from '../../types/recipe'

interface RecipeProps {
  recipe: RecipeType
  onEditRecipe: (recipe: RecipeType) => void
  onDetails: () => void
  onDeleteRecipe: (id?: string) => void
}

const Recipe = (props: RecipeProps) => {
  const { recipe, onEditRecipe, onDetails, onDeleteRecipe } = props

  return (
    <div className={styles.root} onClick={onDetails}>
      <div className={styles.topContainer}>
        <div className={styles.name}>{recipe.name}</div>
        <div className={styles.btnContainer}>
          <IconButton onClick={() => onEditRecipe(recipe)}>
            <MdModeEdit size={22} />
          </IconButton>
          <IconButton onClick={() => onDeleteRecipe(recipe.id)}>
            <MdDelete size={22} />
          </IconButton>
        </div>
      </div>

      <div className={styles.tags}>
        {recipe?.ingredients &&
          recipe.ingredients.length > 0 &&
          recipe.ingredients.map((tag: string, index: number) => (
            <div key={`${index}-${tag}`} className={styles.tag}>
              {tag}
            </div>
          ))}
      </div>
      <div className={styles.createdAt}>
        Created at: {format(new Date(recipe.createdAt), 'yyyy-MM-dd')}
      </div>
    </div>
  )
}

export default Recipe
