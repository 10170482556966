import React, { useEffect, useState } from 'react'
import styles from './LinksPage.module.scss'
import { connect } from 'react-redux'
import { Link } from '../types/link'
import { RootState } from '../../../../redux/store'
import {
  deleteLink,
  getLinksTree,
  getLinksTreeById,
  goBackToParentFolder,
} from '../store/link.slice'
import { FcFolder, FcLink } from 'react-icons/fc'
import MyLoader from '../../../../components/MyLoader/MyLoader'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import FormAddLink from '../components/FormAddLink/FormAddLink'
import Confirm from '../../../../components/Confirm/Confirm'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'

interface LinksPageProps {
  linksTree?: Link[]
  linksParentsIds?: string[]
  loading?: boolean
  getLinksTree?: () => void
  getLinksTreeById?: ({ id }: { id: string }) => void
  goBackToParentFolder?: () => void
  deleteLink?: (id: string) => void
}

const LinksPage = (props: LinksPageProps) => {
  const [isActiveAddLinkTable, setIsActiveAddLinkTable] =
    useState<boolean>(false)
  const [isActiveEditLinkTable, setIsActiveEditLinkTable] =
    useState<boolean>(false)
  const [type, setType] = useState<string>('link')
  const [link, setLink] = useState<Link>()
  const [selectedLinkId, setSelectedLinkId] = useState<string | undefined>()

  const {
    linksTree,
    linksParentsIds,
    loading,
    getLinksTree = () => {},
    getLinksTreeById = () => {},
    goBackToParentFolder = () => {},
    deleteLink = () => {},
  } = props

  useEffect(() => {
    linksTree?.length === 0 && getLinksTree()
  }, [])

  const onClickFolder = (id: string) => {
    getLinksTreeById({ id })
  }

  const onOpenAddLinkTable = (t: string) => {
    setType(t)
    setIsActiveAddLinkTable(true)
  }

  const onOpenEditLinkTable = (link: Link) => {
    setLink(link)
    setType(link.type)
    setIsActiveEditLinkTable(true)
  }

  const onDeleteLink = () => {
    if (selectedLinkId) {
      deleteLink(selectedLinkId)
      setSelectedLinkId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.linkTop}>
        <div title={'Create New Link'} className={styles.addLink}>
          <IconButton onClick={() => onOpenAddLinkTable('link')}>
            <FcLink className={styles.icon} size={30} />
          </IconButton>
          <IconButton onClick={() => onOpenAddLinkTable('folder')}>
            <FcFolder className={styles.icon} size={30} />
          </IconButton>
        </div>
      </div>
      <div className={styles.content}>
        {linksParentsIds && linksParentsIds?.length > 1 && (
          <div className={styles.back} onClick={() => goBackToParentFolder()}>
            <FcFolder size={24} />
            <span className={styles.folderName}>..</span>
          </div>
        )}
        {linksTree &&
          linksTree?.length > 0 &&
          linksTree.map((link, index) => (
            <div
              className={styles.linkContainer}
              key={`link-${index}-${link.id}`}
            >
              {link.type === 'folder' ? (
                <div
                  className={styles.folder}
                  onClick={() => onClickFolder(link.id)}
                >
                  <FcFolder size={24} />
                  <span className={styles.folderName}>{link.name}</span>
                </div>
              ) : (
                <a
                  href={link.url}
                  className={styles.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FcLink size={24} />
                  <span className={styles.folderName}>{link.name}</span>
                </a>
              )}
              <div className={styles.btnContainer}>
                <IconButton onClick={() => onOpenEditLinkTable(link)}>
                  <MdModeEdit size={22} />
                </IconButton>
                <IconButton
                  disabled={link?.children && link?.children?.length > 0}
                  onClick={() => setSelectedLinkId(link.id)}
                >
                  <MdDelete size={22} />
                </IconButton>
              </div>
            </div>
          ))}
      </div>
      <RightDrawer
        title={`Add ${type}`}
        show={isActiveAddLinkTable}
        onHide={() => setIsActiveAddLinkTable(false)}
      >
        <FormAddLink
          type={type}
          onClickCancel={() => setIsActiveAddLinkTable(false)}
        />
      </RightDrawer>

      <RightDrawer
        title={`Edit ${type}`}
        show={isActiveEditLinkTable}
        onHide={() => setIsActiveEditLinkTable(false)}
      >
        <FormAddLink
          type={type}
          link={link}
          onClickCancel={() => setIsActiveEditLinkTable(false)}
        />
      </RightDrawer>

      {selectedLinkId && (
        <Confirm
          confirm="Are you really want to delete this Link?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteLink}
          onCancel={() => setSelectedLinkId(undefined)}
        />
      )}
      {loading && (
        <div className={styles.loaderContainer}>
          <MyLoader />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  linksTree: state.link.linksTree,
  linksParentsIds: state.link.linksParentsIds,
  loading: state.link.loading,
})

export default connect(mapStateToProps, {
  getLinksTree,
  getLinksTreeById,
  goBackToParentFolder,
  deleteLink,
})(LinksPage)
