import { CreateEvent, Event } from '../types/event'
import instance from '../../../api/axios'

export const loadEvents = async (): Promise<Event[]> => {
  const response = await instance.get('/events')
  return response.data
}

export const saveEvent = async (event: CreateEvent): Promise<Event> => {
  const response = await instance.post('/events', event)
  return response.data
}

export const modifyEvent = async (event: Event): Promise<Event> => {
  const response = await instance.put('/events', event)
  return response.data
}

export const removeEvent = async (id: string): Promise<string> => {
  const response = await instance.delete(`/events/${id}`)
  return response.data
}
