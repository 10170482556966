import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Idea as IdeaType } from '../types/idea'
import styles from './IdeasPage.module.scss'
import { deleteIdea, getIdeas } from '../store/idea.slice'
import Idea from '../components/Idea/Idea'
import IconButton from '../../../../components/IconButton/IconButton'
import { FcIdea } from 'react-icons/fc'
import FormAddIdea from '../components/FormAddIdea/FormAddIdea'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import Confirm from '../../../../components/Confirm/Confirm'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import IdeaDetails from '../components/IdeaDetails/IdeaDetails'
import { IoFilter } from 'react-icons/io5'
import TopDrawer from '../../../../components/TopDrawer/TopDrawer'
import IdeasFilters from '../components/IdeasFilters/IdeasFilters'

export interface IdeasPageProps {
  ideas?: IdeaType[]
  screenWidth: number
  getIdeas: () => void
  deleteIdea: (id: number) => void
}

const IdeasPage = (props: IdeasPageProps) => {
  const { ideas, screenWidth, getIdeas, deleteIdea } = props
  const [isActiveAddIdeaTable, setIsActiveAddIdeaTable] =
    useState<boolean>(false)
  const [isActiveEditIdeaTable, setIsActiveEditIdeaTable] =
    useState<boolean>(false)
  const [isActiveFiltersModal, setIsActiveFiltersModal] =
    useState<boolean>(false)
  const [selectedIdea, setSelectedIdea] = useState<IdeaType | undefined>()
  const [selectedIdeaId, setSelectedIdeaId] = useState<number | undefined>()
  const [ideaDetails, setIdeaDetails] = useState<IdeaType | undefined>()

  useEffect(() => {
    getIdeas()
  }, [])

  const onOpenAdIdeaTable = () => {
    setIsActiveAddIdeaTable(true)
  }

  const onClickCancel = () => {
    setIsActiveAddIdeaTable(false)
  }

  const onEditIdea = (idea: IdeaType) => {
    setSelectedIdea(idea)
    setIsActiveEditIdeaTable(true)
  }

  const onDeleteIdea = () => {
    if (selectedIdeaId) {
      deleteIdea(selectedIdeaId)
      setSelectedIdeaId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.ideaTop}>
        <div className={styles.buttons}>
          <div title={'Filters'} className={styles.filtersButton}>
            <IconButton onClick={() => setIsActiveFiltersModal(true)}>
              <IoFilter className={styles.icon} size={30} />
            </IconButton>
          </div>
          <div title={'Create New Idea'} className={styles.addIdea}>
            <IconButton onClick={onOpenAdIdeaTable}>
              <FcIdea className={styles.icon} size={30} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={styles.ideaContainer}>
        <div className={styles.ideas}>
          {ideas &&
            ideas.map((idea, index) => (
              <div className={styles.idea} key={idea.id}>
                <Idea
                  idea={idea}
                  onEditIdea={onEditIdea}
                  onDetails={() => setIdeaDetails(idea)}
                  onDeleteIdea={setSelectedIdeaId}
                  key={`${index}-${idea.id}-idea`}
                />
              </div>
            ))}
        </div>
        <RightDrawer
          title={'Add Idea'}
          size="l"
          show={isActiveAddIdeaTable}
          onHide={onClickCancel}
        >
          <FormAddIdea onClickCancel={onClickCancel} />
        </RightDrawer>
        <RightDrawer
          title={'Edit ToDo'}
          size="l"
          show={isActiveEditIdeaTable}
          onHide={() => setIsActiveEditIdeaTable(false)}
        >
          {isActiveEditIdeaTable && selectedIdea && (
            <FormAddIdea
              idea={{
                ...selectedIdea,
                description: convertHtmlToNode(
                  selectedIdea!.description as string,
                ),
              }}
              onClickCancel={() => setIsActiveEditIdeaTable(false)}
            />
          )}
        </RightDrawer>

        <RightDrawer
          title={`${ideaDetails?.name}`}
          size="l"
          show={!!ideaDetails}
          onHide={() => setIdeaDetails(undefined)}
        >
          {ideaDetails && <IdeaDetails idea={ideaDetails} />}
        </RightDrawer>
        <TopDrawer
          title={`Ideas filters`}
          size={screenWidth < 502 ? 'vs' : 'es'}
          show={isActiveFiltersModal}
          onHide={() => setIsActiveFiltersModal(false)}
        >
          <IdeasFilters />
        </TopDrawer>

        {selectedIdeaId && (
          <Confirm
            confirm="Are you really want to delete this Idea?"
            confirmBtnLabel="Delete"
            confirmBtnVariant="danger"
            onConfirm={onDeleteIdea}
            onCancel={() => setSelectedIdeaId(undefined)}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  ideas: state.idea.ideas,
  screenWidth: state.main.screenWidth,
})

export default connect(mapStateToProps, {
  getIdeas,
  deleteIdea,
})(IdeasPage)
