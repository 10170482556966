import instance from '../../../api/axios'
import { Whiteboard, WhiteboardRaw } from '../../Whiteboard/types/whiteboard'
import { CreateObjective, Objective, ObjectiveRaw } from '../types/objective'

export const loadObjectives = async (): Promise<Objective[]> => {
  const response = await instance.get('/objectives')
  return response.data
    ? response.data.map((objective: ObjectiveRaw) =>
        objective.whiteboards?.length > 0
          ? {
              ...objective,
              whiteboards: objective.whiteboards
                ?.map((whiteboard: WhiteboardRaw) => ({
                  ...whiteboard,
                  excalidrawElement: whiteboard.excalidrawElement
                    ? JSON.parse(whiteboard.excalidrawElement)
                    : [],
                }))
                .sort((a: Whiteboard, b: Whiteboard) =>
                  b.createdAt.localeCompare(a.createdAt),
                ),
            }
          : objective,
      )
    : []
}

export const loadObjective = async (id: number): Promise<Objective> => {
  const response = await instance.get(`/objectives/${id}`)
  return (
    response.data?.whiteboards?.length > 0 && {
      ...response.data,
      whiteboards: response.data?.whiteboards
        ?.map((whiteboard: WhiteboardRaw) => ({
          ...whiteboard,
          excalidrawElement: whiteboard.excalidrawElement
            ? JSON.parse(whiteboard.excalidrawElement)
            : [],
        }))
        .sort((a: Whiteboard, b: Whiteboard) =>
          b.createdAt.localeCompare(a.createdAt),
        ),
    }
  )
}

export const saveObjective = async (
  objective: CreateObjective,
): Promise<Objective> => {
  const response = await instance.post('/objectives', objective)
  return response.data
}

export const modifyObjective = async (
  objective: Objective,
): Promise<Objective> => {
  const response = await instance.put('/objectives', objective)
  return response.data
}

export const removeObjective = async (id: number): Promise<number> => {
  const response = await instance.delete(`/objectives/${id}`)
  return response.data
}
