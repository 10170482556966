import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw'
import styles from './WhiteboardPage.module.scss'
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types'
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types'
import { IoMdMenu } from 'react-icons/io'
import { getWhiteboard, updateWhiteboard } from '../../store/whiteboard.slice'
import { Whiteboard } from '../../types/whiteboard'
import { useNavigate, useParams } from 'react-router-dom'
import MyLoader from '../../../../components/MyLoader/MyLoader'
import { IoArrowBack, IoImageOutline, IoSaveOutline } from 'react-icons/io5'
import { MdSaveAlt } from 'react-icons/md'

interface WhiteboardPageProps {
  whiteboard?: Whiteboard
  getWhiteboard: (whiteboardId: string) => void
  updateWhiteboard: (whiteboard: Whiteboard) => void
}

const WhiteboardPage = (props: WhiteboardPageProps) => {
  const { whiteboard, getWhiteboard, updateWhiteboard } = props
  const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI>()
  const [excalidrawElement, setExcalidrawElement] =
    useState<ExcalidrawElement[]>()
  const [isOpenedMenuDropdown, setIsOpenedMenuDropdown] =
    useState<boolean>(false)

  const params = useParams()
  const navigator = useNavigate()

  useEffect(() => {
    if (!whiteboard) {
      getWhiteboard(params.id!)
    } else {
      setExcalidrawElement(whiteboard.excalidrawElement)
    }
  }, [])

  useEffect(() => {
    if (whiteboard) {
      setExcalidrawElement(whiteboard.excalidrawElement)
    }
  }, [whiteboard])

  // useEffect(() => {
  //   console.log('excalidrawAPI', excalidrawAPI)
  // }, [excalidrawAPI])

  const onBack = () => {
    setIsOpenedMenuDropdown(false)
    navigator(-1)
  }

  const onSave = () => {
    updateWhiteboard({
      ...whiteboard,
      // @ts-ignore
      excalidrawElement: excalidrawAPI?.getSceneElements() || [],
    })
    setIsOpenedMenuDropdown(false)
  }

  return (
    <div className={styles.root}>
      {excalidrawElement ? (
        <Excalidraw
          initialData={{ elements: excalidrawElement }}
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
        >
          <MainMenu>
            <MainMenu.Item onSelect={onBack}>Back</MainMenu.Item>
            <MainMenu.Item onSelect={onSave}>Save</MainMenu.Item>
          </MainMenu>
        </Excalidraw>
      ) : (
        <MyLoader />
      )}

      {/* <div
        className={styles.menuButton}
        onClick={() => setIsOpenedMenuDropdown(!isOpenedMenuDropdown)}
      >
        <IoMdMenu size={18} />
      </div>
      {isOpenedMenuDropdown && (
        <div className={styles.menuDropdown}>
          <div className={styles.dropdownItem} onClick={onBack}>
            <IoArrowBack size={14} />
            Back
          </div>
          <div className={styles.dropdownItem} onClick={onSave}>
            <IoSaveOutline size={14} />
            Save
          </div>
          <div className={styles.dropdownItem} onClick={() => {}}>
            <MdSaveAlt size={14} />
            Save To
          </div>
          <div className={styles.dropdownItem} onClick={() => {}}>
            <IoImageOutline size={14} />
            Export image
          </div>
        </div>
      )} */}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  whiteboard: state.whiteboard.whiteboard,
})

export default connect(mapStateToProps, { getWhiteboard, updateWhiteboard })(
  WhiteboardPage,
)
