import React, { useEffect, useState } from 'react'
import styles from './FormAddEvent.module.scss'
import { Button, Form } from 'react-bootstrap'
import Textarea from '../../../../components/Textarea/Textarea'
import MainSelect from '../../../../components/MainSelect/MainSelect'
import { CreateEvent, Event } from '../../types/event'
import DatePicker from 'react-date-picker'
import TagSelect from '../../../../components/TagSelect/TagSelect'
import DatePickerMulti from '../../../../components/DatePickerMulti/DatePickerMulti'
import { convertNodeToHtml } from '../../../../utils/convertNodeToHtml'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import {
  eventPeriodicityOptions,
  eventPriorityOptions,
  eventTypesOptions,
} from '../../config/eventInputSelectOptions'

interface FormAddEventProps {
  event?: Event
  preDefinedEventDate?: Date
  onCancel: () => void
  addEvent?: (event: CreateEvent) => void
  updateEvent?: (event: Event) => void
}

const FormAddEvent = (props: FormAddEventProps) => {
  const { event, preDefinedEventDate, onCancel, addEvent, updateEvent } = props

  const [title, setTitle] = useState<any>(event?.title ? event.title : '')
  const [description, setDescription] = useState<any>(
    event ? convertHtmlToNode(event.description) : '',
  )
  const [note, setNote] = useState<any>(
    event ? convertHtmlToNode(event.note) : '',
  )
  const [type, setType] = useState<any>(event ? event.type : '')
  const [priority, setPriority] = useState<string | null>(
    event ? event.priority : '',
  )
  const [periodicity, setPeriodicity] = useState<string | null>(
    event ? event.periodicity : 'Once',
  )
  const [dates, setDates] = useState<Date[]>(
    event
      ? event.dates.map((date) => new Date(date))
      : preDefinedEventDate
      ? [preDefinedEventDate]
      : [],
  )
  const [tags, setTags] = useState<string[]>(event ? event.tags : [])
  const [notification, setNotification] = useState<boolean>(
    event ? event.notification : false,
  )

  const onAddEvent = () => {
    if (title && type && type && priority && periodicity && dates.length > 0) {
      const newEvent: CreateEvent = {
        title,
        description: description ? convertNodeToHtml(description) : '',
        note: note ? convertNodeToHtml(note) : '',
        type,
        priority,
        periodicity,
        dates,
        tags,
        notification,
      }
      addEvent && addEvent(newEvent)
      onCancel()
    }
  }

  const onUpdateEvent = () => {
    if (
      event &&
      title &&
      type &&
      type &&
      priority &&
      periodicity &&
      dates.length > 0
    ) {
      const updatedEvent: Event = {
        ...event,
        title,
        description: description ? convertNodeToHtml(description) : '',
        note: note ? convertNodeToHtml(note) : '',
        type,
        priority,
        periodicity,
        dates,
        tags,
        notification,
      }
      updateEvent && updateEvent(updatedEvent)
      onCancel()
    }
  }

  const onResetDates = (p: string) => {
    setDates([])
  }

  return (
    <div>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Title*</Form.Label>
        <Form.Control
          value={title}
          onChange={(e: any) => setTitle(e.target.value)}
          placeholder="event name..."
        />
      </Form.Group>
      <div className={styles.lineTextarea}>
        <Form.Label className={styles.label}>Description</Form.Label>
        <Textarea
          value={description}
          placeholder="event description..."
          setValue={setDescription}
        />
      </div>

      <div className={styles.lineTextarea}>
        <Form.Label className={styles.label}>Note</Form.Label>
        <Textarea value={note} placeholder="event note..." setValue={setNote} />
      </div>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <MainSelect
          label={'Type'}
          select={
            type ? { label: type, value: type } : { label: '', value: '' }
          }
          setSelect={(p) => setType(p.value as string)}
          selects={eventTypesOptions}
          required
        />
      </Form.Group>
      <div className={styles.line}>
        <MainSelect
          label={'Priority'}
          select={
            priority
              ? { label: priority, value: priority }
              : { label: '', value: '' }
          }
          setSelect={(p) => setPriority(p.value as string)}
          selects={eventPriorityOptions}
          required
        />
      </div>
      <div className={styles.line}>
        <MainSelect
          label={'Periodicity'}
          select={
            periodicity
              ? { label: periodicity, value: periodicity }
              : { label: '', value: '' }
          }
          setSelect={(p) => {
            setPeriodicity(p.value as string)
          }}
          selects={eventPeriodicityOptions}
          required
        />
      </div>
      {periodicity === 'By Dates' || periodicity === 'Period' ? (
        <div className={styles.line}>
          <Form.Group>
            <Form.Label className={styles.label}>Dates*</Form.Label>
            <DatePickerMulti
              dates={dates}
              setDates={setDates}
              limit={periodicity === 'Period' ? 2 : 0}
            />
          </Form.Group>
        </div>
      ) : (
        <div className={styles.line}>
          <Form.Group>
            <Form.Label className={styles.label}>Date*</Form.Label>
            <DatePicker
              onChange={(date: Date) => setDates([date])}
              value={dates[0]}
              className={styles.picker}
              format="y-MM-dd"
            />
          </Form.Group>
        </div>
      )}
      <div className={styles.line}>
        <Form.Label className={styles.label}>Tags</Form.Label>
        <TagSelect tags={tags} setTags={setTags} />
      </div>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Notification</Form.Label>
        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          label="Check this switch"
          checked={notification}
          onChange={(e) => setNotification(e.target.checked)}
        />
      </div>

      <div className={styles.btnContainer}>
        <Button onClick={onCancel} variant={'light'}>
          Cancel
        </Button>
        {event ? (
          <Button
            onClick={onUpdateEvent}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddEvent}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

export default FormAddEvent
