import React from 'react'
import styles from './MemoryDetails.module.scss'
import { Memory } from '../../types/memory'

interface MemoryDetailsProps {
  memory: Memory
}

const MemoryDetails = (props: MemoryDetailsProps) => {
  const { memory } = props

  return (
    <div className={styles.root}>
      <div className={styles.tagsContainer}>
        <span>Tags: </span>
        <span className={styles.tags}>
          {memory?.tags?.length > 0 &&
            memory.tags.map((tag, i) => (
              <span key={`${i}-${tag}-memory-details`} className={styles.tag}>
                {tag}
              </span>
            ))}
        </span>
      </div>

      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: memory.description,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MemoryDetails
