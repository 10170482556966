import React, { useEffect, useState } from 'react'
import { RootState } from '../../../../redux/store'
import { connect } from 'react-redux'
import { Recipe as RecipeType } from '../types/recipe'
import { deleteRecipe, getRecipes } from '../store/recipe.slice'
import { PiBowlFood } from 'react-icons/pi'
import IconButton from '../../../../components/IconButton/IconButton'
import { IoFilter } from 'react-icons/io5'
import styles from './Recipes.module.scss'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import FormAddRecipe from '../components/FormAddRecipe/FormAddRecipe'
import Recipe from '../components/Recipe/Recipe'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import RecipeDetails from '../components/RecipeDetails/RecipeDetails'
import Confirm from '../../../../components/Confirm/Confirm'

interface RecipesPageProps {
  recipes: RecipeType[]
  getRecipes: () => void
  deleteRecipe: (id: string) => void
}

const RecipesPage = (props: RecipesPageProps) => {
  const { recipes, getRecipes, deleteRecipe } = props
  const [isActiveFiltersModal, setIsActiveFiltersModal] =
    useState<boolean>(false)
  const [isActiveAddRecipeTable, setIsActiveAddRecipeTable] =
    useState<boolean>(false)
  const [isActiveEditRecipeTable, setIsActiveEditRecipeTable] =
    useState<boolean>(false)
  const [selectedRecipe, setSelectedRecipe] = useState<RecipeType | undefined>()
  const [selectedRecipeId, setSelectedRecipeId] = useState<string | undefined>()
  const [recipeDetails, setRecipeDetails] = useState<RecipeType | undefined>()

  const onClickCancel = () => {
    setIsActiveAddRecipeTable(false)
  }

  useEffect(() => {
    getRecipes()
  }, [])

  const onOpenAddRecipeTable = () => {
    setIsActiveAddRecipeTable(true)
  }

  const onEditRecipe = (recipe: RecipeType) => {
    setSelectedRecipe(recipe)
    setIsActiveEditRecipeTable(true)
  }

  const onDeleteRecipe = () => {
    if (selectedRecipeId) {
      deleteRecipe(selectedRecipeId)
      setSelectedRecipeId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.recipeTop}>
        <div className={styles.buttons}>
          {/* <div title={'Filters'} className={styles.filtersButton}>
            <IconButton onClick={() => setIsActiveFiltersModal(true)}>
              <IoFilter className={styles.icon} size={30} />
            </IconButton>
          </div> */}
          <div title={'Create New Recipe'} className={styles.addRecipeButton}>
            <IconButton onClick={onOpenAddRecipeTable}>
              <PiBowlFood className={styles.icon} size={30} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={styles.recipeContainer}>
        <div className={styles.memories}>
          {recipes &&
            recipes.map((recipe, index) => (
              <div className={styles.recipe} key={recipe.id}>
                <Recipe
                  recipe={recipe}
                  onEditRecipe={onEditRecipe}
                  onDetails={() => setRecipeDetails(recipe)}
                  onDeleteRecipe={setSelectedRecipeId}
                  key={`${index}-${recipe.id}-recipe`}
                />
              </div>
            ))}
        </div>
        <RightDrawer
          title={'Add Recipe'}
          size="l"
          show={isActiveAddRecipeTable}
          onHide={onClickCancel}
        >
          <FormAddRecipe onClickCancel={onClickCancel} />
        </RightDrawer>

        <RightDrawer
          title={'Edit Recipe'}
          size="l"
          show={isActiveEditRecipeTable}
          onHide={() => setIsActiveEditRecipeTable(false)}
        >
          {isActiveEditRecipeTable && selectedRecipe && (
            <FormAddRecipe
              recipe={{
                ...selectedRecipe,
                description: convertHtmlToNode(
                  selectedRecipe!.description as string,
                ),
              }}
              onClickCancel={() => setIsActiveEditRecipeTable(false)}
            />
          )}
        </RightDrawer>

        <RightDrawer
          title={`${recipeDetails?.name}`}
          size="l"
          show={!!recipeDetails}
          onHide={() => setRecipeDetails(undefined)}
        >
          {recipeDetails && <RecipeDetails recipe={recipeDetails} />}
        </RightDrawer>

        {/* <TopDrawer
          title={`Memories filters`}
          size={screenWidth < 502 ? 'vs' : 'es'}
          show={isActiveFiltersModal}
          onHide={() => setIsActiveFiltersModal(false)}
        >
          <MemoriesFilters />
        </TopDrawer> */}

        {selectedRecipeId && (
          <Confirm
            confirm="Are you really want to delete this Recipe?"
            confirmBtnLabel="Delete"
            confirmBtnVariant="danger"
            onConfirm={onDeleteRecipe}
            onCancel={() => setSelectedRecipeId(undefined)}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  recipes: state.recipe.recipes,
})

export default connect(mapStateToProps, { getRecipes, deleteRecipe })(
  RecipesPage,
)
