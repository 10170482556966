import instance from '../../../api/axios'
import { CreateStep, Step } from '../types/objective'

export const saveStep = async (step: CreateStep): Promise<Step> => {
  const response = await instance.post('/steps', step)
  return response.data
}

export const modifyStep = async (step: Step): Promise<Step> => {
  const response = await instance.put('/steps', step)
  return response.data
}

export const removeStep = async (id: number): Promise<number> => {
  const response = await instance.delete(`/steps/${id}`)
  return response.data
}
