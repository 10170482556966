import instance from '../../../../api/axios'
import { CreateLocation, Location } from '../types/location'

export const loadLocations = async (): Promise<Location[]> => {
  const response = await instance.get('/locations')
  return response.data
}

export const saveLocation = async (
  location: CreateLocation,
): Promise<Location> => {
  const response = await instance.post('/locations', location)
  return response.data
}

export const modifyLocation = async (location: Location): Promise<Location> => {
  const response = await instance.put('/locations', location)
  return response.data
}

export const removeLocation = async (id: string): Promise<string> => {
  const response = await instance.delete(`/locations/${id}`)
  return response.data
}
