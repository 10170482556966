import React from 'react'
import styles from './Textarea.module.scss'
import { Editor, EditorState } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

interface TextareaProps {
  value: EditorState
  placeholder?: string
  setValue: (value: EditorState) => void
}

const Textarea = (props: TextareaProps) => {
  const { value, placeholder = '', setValue } = props

  return (
    <div className={styles.root}>
      <Editor
        editorState={value}
        placeholder={placeholder}
        toolbarClassName={styles.toolbarClassName}
        wrapperClassName={styles.wrapperClassName}
        editorClassName={styles.editorClassName}
        editorStyle={{ height: '160px', minHeight: '180px' }}
        onEditorStateChange={(editorState: any) => {
          setValue(editorState)
        }}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'image',
            'remove',
            'history',
          ],
          inline: {
            inDropdown: true,
          },
          list: {
            inDropdown: true,
          },
          textAlign: {
            inDropdown: true,
          },
          link: {
            inDropdown: true,
          },
          history: {
            inDropdown: true,
          },
        }}
      />
    </div>
  )
}

export default Textarea
