import { Option } from '../../../types'

export const getWebSpeechApiLanguages = (): Option[] => {
  return [
    {
      label: 'English - United States',
      value: 'en-US',
    },
    {
      label: 'Spanish - Spain',
      value: 'es-ES',
    },
    {
      label: 'Armenian - Armenia',
      value: 'hy-AM',
    },
    {
      label: 'Russian - Russian Federation',
      value: 'ru-RU',
    },
  ]

  //  ['af-ZA']],
  //  [         ['am-ET']],
  //  [    ['az-AZ']],
  //  [          ['bn-BD', 'বাংলাদেশ'],
  //                      ['bn-IN', 'ভারত']],
  //  [['id-ID']],
  //  [   ['ms-MY']],
  //  [          ['ca-ES']],
  //  [        ['cs-CZ']],
  //  [         ['da-DK']],
  //  [        ['de-DE']],
  //  [        ['en-AU', 'Australia'],
  //                      ['en-CA', 'Canada'],
  //                      ['en-IN', 'India'],
  //                      ['en-KE', 'Kenya'],
  //                      ['en-TZ', 'Tanzania'],
  //                      ['en-GH', 'Ghana'],
  //                      ['en-NZ', 'New Zealand'],
  //                      ['en-NG', 'Nigeria'],
  //                      ['en-ZA', 'South Africa'],
  //                      ['en-PH', 'Philippines'],
  //                      ['en-GB', 'United Kingdom'],
  //                      ['en-US', 'United States']],
  //  [        ['es-AR', 'Argentina'],
  //                      ['es-BO', 'Bolivia'],
  //                      ['es-CL', 'Chile'],
  //                      ['es-CO', 'Colombia'],
  //                      ['es-CR', 'Costa Rica'],
  //                      ['es-EC', 'Ecuador'],
  //                      ['es-SV', 'El Salvador'],
  //                      ['es-ES', 'España'],
  //                      ['es-US', 'Estados Unidos'],
  //                      ['es-GT', 'Guatemala'],
  //                      ['es-HN', 'Honduras'],
  //                      ['es-MX', 'México'],
  //                      ['es-NI', 'Nicaragua'],
  //                      ['es-PA', 'Panamá'],
  //                      ['es-PY', 'Paraguay'],
  //                      ['es-PE', 'Perú'],
  //                      ['es-PR', 'Puerto Rico'],
  //                      ['es-DO', 'República Dominicana'],
  //                      ['es-UY', 'Uruguay'],
  //                      ['es-VE', 'Venezuela']],
  //  [        ['eu-ES']],
  //  [        ['fil-PH']],
  //  [       ['fr-FR']],
  //  [       ['jv-ID']],
  //  [          ['gl-ES']],
  //  [          ['gu-IN']],
  //  [        ['hr-HR']],
  //  [        ['zu-ZA']],
  //  [        ['is-IS']],
  //  [      ['it-IT', 'Italia'],
  //                      ['it-CH', 'Svizzera']],
  //  [            ['kn-IN']],
  //  [         ['km-KH']],
  //  [        ['lv-LV']],
  //  [        ['lt-LT']],
  //  [         ['ml-IN']],
  //  [           ['mr-IN']],
  //  [         ['hu-HU']],
  //  [             ['lo-LA']],
  //  [     ['nl-NL']],
  //  ['नेपाली भाषा',        ['ne-NP']],
  //  ['Norsk bokmål',    ['nb-NO']],
  //  ['Polski',          ['pl-PL']],
  //  ['Português',       ['pt-BR', 'Brasil'],
  //                      ['pt-PT', 'Portugal']],
  //  ['Română',          ['ro-RO']],
  //  ['සිංහල',          ['si-LK']],
  //  ['Slovenščina',     ['sl-SI']],
  //  ['Basa Sunda',      ['su-ID']],
  //  ['Slovenčina',      ['sk-SK']],
  //  ['Suomi',           ['fi-FI']],
  //  ['Svenska',         ['sv-SE']],
  //  ['Kiswahili',       ['sw-TZ', 'Tanzania'],
  //                      ['sw-KE', 'Kenya']],
  //  ['ქართული',       ['ka-GE']],
  //  ['Հայերեն',          ['hy-AM']],
  //  ['தமிழ்',            ['ta-IN', 'இந்தியா'],
  //                      ['ta-SG', 'சிங்கப்பூர்'],
  //                      ['ta-LK', 'இலங்கை'],
  //                      ['ta-MY', 'மலேசியா']],
  //  ['తెలుగు',           ['te-IN']],
  //  ['Tiếng Việt',      ['vi-VN']],
  //  ['Türkçe',          ['tr-TR']],
  //  ['اُردُو',            ['ur-PK', 'پاکستان'],
  //                      ['ur-IN', 'بھارت']],
  //  ['Ελληνικά',         ['el-GR']],
  //  ['български',         ['bg-BG']],
  //  ['Pусский',          ['ru-RU']],
  //  ['Српски',           ['sr-RS']],
  //  ['Українська',        ['uk-UA']],
  //  ['한국어',            ['ko-KR']],
  //  ['中文',             ['cmn-Hans-CN', '普通话 (中国大陆)'],
  //                      ['cmn-Hans-HK', '普通话 (香港)'],
  //                      ['cmn-Hant-TW', '中文 (台灣)'],
  //                      ['yue-Hant-HK', '粵語 (香港)']],
  //  ['日本語',           ['ja-JP']],
  //  ['हिन्दी',             ['hi-IN']],
  //  ['ภาษาไทย',         ['th-TH']]];
}
