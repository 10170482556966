import React from 'react'
import { Button } from 'react-bootstrap'
import styles from './Confirm.module.scss'

const Confirm = ({
  confirm = 'You are confirm?',
  confirmBtnVariant = 'success',
  confirmBtnLabel = 'Confirm',
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <span className={styles.confirm}>{confirm}</span>
          <div className={styles.btnContainer}>
            <Button onClick={onCancel} className={styles.createBtn} variant="light">Cancel</Button>
            <Button onClick={onConfirm} className={styles.confirmBtn} variant={confirmBtnVariant}>{confirmBtnLabel}</Button>
          </div>
      </div>
    </div>
  )
}

export default Confirm
