import React, { useEffect, useState } from 'react'
import {
  MdAddCircleOutline,
  MdDelete,
  MdGTranslate,
  MdModeEdit,
  MdPlaylistAdd,
} from 'react-icons/md'
import { connect } from 'react-redux'
import Confirm from '../../../components/Confirm/Confirm'
import FormAddVocabulary from '../components/FormAddVocabulary/FormAddVocabulary'
import FormAddWord from '../components/FormAddWord/FormAddWord'
import IconButton from '../../../components/IconButton/IconButton'
import MainSelect from '../../../components/MainSelect/MainSelect'
import MyLoader from '../../../components/MyLoader/MyLoader'
import Word from '../components/Word/Word'
import { RootState } from '../../../redux/store'
import {
  addVocabulary,
  addWord,
  deleteVocabulary,
  deleteWord,
  getVocabularies,
  moveWord,
  setSelectedVocabulary,
  updateVocabulary,
  updateWord,
} from '../store/vocabulary.slice'
// import {
//   CreateVocabulary,
//   CreateWord,
//   Vocabulary,
//   Word as WordType,
// } from '../../../types'
import styles from './TranslationMain.module.scss'
import { FaSyncAlt } from 'react-icons/fa'
import {
  CreateVocabulary,
  CreateWord,
  Vocabulary,
  Word as WordType,
} from '../types/vocabulary'
import { IoFilter } from 'react-icons/io5'

interface TranslationMainProps {
  vocabularies: Vocabulary[]
  selectedVocabulary?: Vocabulary
  vocabulariesLoading: boolean
  getVocabularies: () => void
  addVocabulary: (vocabulary: CreateVocabulary) => void
  updateVocabulary: (vocabulary: Vocabulary) => void
  deleteVocabulary: (Id: number) => void
  addWord: (word: CreateWord) => void
  deleteWord: (Id: number) => void
  setSelectedVocabulary: (vocabulary?: Vocabulary) => void
  updateWord: (word: WordType) => void
  moveWord: (word: WordType) => void
}

const TranslationMain = (props: TranslationMainProps) => {
  const {
    vocabularies,
    selectedVocabulary,
    vocabulariesLoading,
    getVocabularies,
    addVocabulary,
    updateVocabulary,
    deleteVocabulary,
    addWord,
    deleteWord,
    setSelectedVocabulary,
    updateWord,
    moveWord,
  } = props

  const [vocabulary, setVocabulary] = useState<Vocabulary>()
  const [isActiveAddVocabularyForm, setIsActiveAddVocabularyForm] =
    useState<boolean>(false)
  const [isActiveUpdateVocabularyForm, setIsActiveUpdateVocabularyForm] =
    useState<boolean>(false)
  const [isActiveAddWordForm, setIsActiveAddWordForm] = useState<boolean>(false)
  const [isWords, setIsWords] = useState<boolean>(true)
  const [deletionVocabularyId, setDeletionVocabularyId] = useState<number>()
  const [deletionWordId, setDeletionWordId] = useState<number>()
  const [wordToUpdate, setWordToUpdate] = useState<WordType>()
  const [wordOptionId, setWordOptionId] = useState<number>()
  const [activatedVoiceWordId, setActivatedVoiceWordId] = useState<number>()
  const [isActiveFiltersModal, setIsActiveFiltersModal] =
    useState<boolean>(false)

  const onCloseOptionTable = () => {
    setWordOptionId(undefined)
  }

  useEffect(() => {
    document.addEventListener('click', onCloseOptionTable)
    getVocabularies()

    return () => {
      setSelectedVocabulary(undefined)
      document.removeEventListener('click', onCloseOptionTable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (vocabularies.length > 0 && vocabulary) {
      setVocabulary(vocabularies.find((v) => v.id === vocabulary.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vocabularies])

  useEffect(() => {
    vocabulary && setSelectedVocabulary(vocabulary)
  }, [vocabulary])

  const onDeleteVocabulary = () => {
    deletionVocabularyId && deleteVocabulary(deletionVocabularyId)
    setDeletionVocabularyId(undefined)
  }

  const onDeleteWord = () => {
    deletionWordId && deleteWord(deletionWordId)
    setDeletionWordId(undefined)
  }

  const onClickMoveWord = (word: WordType, newVocabularyId: number) => {
    moveWord({ ...word, vocabularyId: newVocabularyId })
  }

  return (
    <>
      {vocabulariesLoading ? (
        <MyLoader />
      ) : (
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.vocabularies}>
              <div className={styles.vocabulariesDropdown}>
                <MainSelect
                  select={
                    vocabulary
                      ? { label: vocabulary.name, value: vocabulary.id }
                      : { label: '', value: '' }
                  }
                  setSelect={(option) => {
                    setVocabulary(
                      vocabularies?.find(
                        (voc: Vocabulary) =>
                          voc.id === Number.parseInt(option.value as string),
                      ),
                    )
                  }}
                  selects={vocabularies?.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                />
              </div>
              <div className={styles.buttonContainer}>
                <div>
                  <IconButton
                    onClick={() => setIsActiveAddWordForm(true)}
                    title={
                      vocabulary
                        ? 'Add word to selected vocabulary'
                        : 'Please select vocabulary'
                    }
                    disabled={!vocabulary}
                  >
                    <MdAddCircleOutline
                      className={vocabulary ? styles.addIcon : ''}
                      size={26}
                    />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    onClick={() => setIsActiveUpdateVocabularyForm(true)}
                    title={
                      vocabulary
                        ? 'Update selected vocabulary'
                        : 'Please select vocabulary'
                    }
                    disabled={!vocabulary}
                  >
                    <MdModeEdit
                      className={vocabulary ? styles.updateIcon : ''}
                      size={26}
                    />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    onClick={() => setDeletionVocabularyId(vocabulary?.id)}
                    title={
                      vocabulary
                        ? 'Delete selected vocabulary'
                        : 'Please select vocabulary'
                    }
                    disabled={!vocabulary}
                  >
                    <MdDelete
                      className={vocabulary ? styles.deleteIcon : ''}
                      size={26}
                    />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    onClick={() => setIsWords(!isWords)}
                    title={
                      vocabulary
                        ? 'Delete selected vocabulary'
                        : 'Please select vocabulary'
                    }
                    disabled={!vocabulary}
                  >
                    <FaSyncAlt
                      className={vocabulary ? styles.changeWordsIcon : ''}
                      size={22}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className={styles.addVocabularyButton}>
              {/* <IconButton
                onClick={() => setIsActiveFiltersModal(true)}
                title={'Filters'}
              >
                <IoFilter className={styles.icon} size={30} />
              </IconButton> */}
              <IconButton
                onClick={() => setIsActiveAddVocabularyForm(true)}
                title={'Add new vocabulary'}
              >
                <MdGTranslate className={styles.icon} size={25} />
              </IconButton>
            </div>
          </div>
          <div className={styles.words}>
            {selectedVocabulary ? (
              selectedVocabulary?.words.map((word, index) => (
                <div key={word.id} className={styles.word}>
                  <Word
                    wordOptionId={wordOptionId}
                    activatedVoiceWordId={activatedVoiceWordId}
                    isWords={isWords}
                    vocabularies={vocabularies.filter(
                      (v) => vocabulary && v.id !== vocabulary.id,
                    )}
                    word={word}
                    index={index}
                    wordsVoiceLanguage={vocabulary?.wordsVoiceLanguage}
                    translationsVoiceLanguage={
                      vocabulary?.translationsVoiceLanguage
                    }
                    setWordOptionId={setWordOptionId}
                    onClickDelete={() => setDeletionWordId(word.id)}
                    onClickUpdate={() => setWordToUpdate(word)}
                    onClickMoveWord={onClickMoveWord}
                    onSetActivatedVoiceWordId={setActivatedVoiceWordId}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {isActiveAddVocabularyForm && (
            <FormAddVocabulary
              title={'Add new vocabulary'}
              onAddVocabulary={addVocabulary}
              onClickCancel={() => setIsActiveAddVocabularyForm(false)}
            />
          )}
          {isActiveUpdateVocabularyForm && (
            <FormAddVocabulary
              title={'Update new vocabulary'}
              vocabulary={vocabulary}
              onAddVocabulary={addVocabulary}
              onClickCancel={() => setIsActiveUpdateVocabularyForm(false)}
              onUpdateVocabulary={updateVocabulary}
            />
          )}
          {deletionVocabularyId && (
            <Confirm
              confirm="Are you really want to delete this vocabulary?"
              confirmBtnLabel="Delete"
              confirmBtnVariant="danger"
              onConfirm={onDeleteVocabulary}
              onCancel={() => setDeletionVocabularyId(undefined)}
            />
          )}
          {isActiveAddWordForm && (
            <FormAddWord
              title={`Add new word to ${vocabulary?.name}`}
              vocabularyId={vocabulary?.id}
              onClickCancel={() => setIsActiveAddWordForm(false)}
              onAddWord={addWord}
            />
          )}
          {wordToUpdate && (
            <FormAddWord
              title={`Update word in ${vocabulary?.name}`}
              vocabularyId={vocabulary?.id}
              word={wordToUpdate}
              onClickCancel={() => setWordToUpdate(undefined)}
              onUpdateWord={updateWord}
            />
          )}
          {deletionWordId && (
            <Confirm
              confirm="Are you really want to delete this word?"
              confirmBtnLabel="Delete"
              confirmBtnVariant="danger"
              onConfirm={onDeleteWord}
              onCancel={() => setDeletionWordId(undefined)}
            />
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  vocabularies: state.vocabulary.vocabularies,
  vocabulariesLoading: state.vocabulary.vocabulariesLoading,
  selectedVocabulary: state.vocabulary.selectedVocabulary,
})

export default connect(mapStateToProps, {
  getVocabularies,
  addVocabulary,
  updateVocabulary,
  deleteVocabulary,
  addWord,
  deleteWord,
  setSelectedVocabulary,
  updateWord,
  moveWord,
})(TranslationMain)
