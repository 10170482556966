import React, { useEffect, useState } from 'react'
import styles from './StatusTags.module.scss'
import Tag from '../Tag/Tag'
import { TagColors } from '../../types'
import { MdDoubleArrow } from 'react-icons/md'
import { FaFlagCheckered, FaPause } from 'react-icons/fa'

interface StatusTagProps {
  status: string
}

const StatusTag = (props: StatusTagProps) => {
  const { status } = props
  const [color, setColor] = useState<TagColors>('Grey')

  useEffect(() => {
    if (status) {
      if (status === 'Active') {
        setColor('Green')
      } else if (status === 'Paused') {
        setColor('Yellow')
      } else if (status === 'Finished') {
        setColor('Red')
      }
    }
  }, [status])

  return (
    <Tag name={status} color={color}>
      {status === 'Active' ? (
        <MdDoubleArrow />
      ) : status === 'Paused' ? (
        <FaPause />
      ) : (
        status === 'Finished' && <FaFlagCheckered />
      )}
    </Tag>
  )
}

export default StatusTag
