import React from 'react';
import { Grid } from 'react-loader-spinner';
import styles from './MyLoader.module.scss'

interface MyLoaderProps {
  type?: string,
  color?: string,
  size?: number,
}

const MyLoader = ({
  type='Grid',
  color='#00BFFF',
  size=70,
}: MyLoaderProps) => {
  return(
    <div className={styles.root}>
      {type === 'Grid' &&
       <Grid
        color={color}
        height={size}
        width={size}
        />
      }
    </div>
  )
};

export default MyLoader
