import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Option } from '../../../../types'
import styles from './FormAddVocabulary.module.scss'
import MainSelect from '../../../../components/MainSelect/MainSelect'
import { getWebSpeechApiLanguages } from '../../utils/getWebSpeechApiLanguages'
import { CreateVocabulary, Vocabulary } from '../../types/vocabulary'

interface FormAddVocabularyProps {
  title: string
  vocabulary?: Vocabulary
  onClickCancel: () => void
  onAddVocabulary?: (note: CreateVocabulary) => void
  onUpdateVocabulary?: (note: Vocabulary) => void
}

const FormAddVocabulary = (props: FormAddVocabularyProps) => {
  const {
    title,
    vocabulary,
    onClickCancel,
    onAddVocabulary,
    onUpdateVocabulary,
  } = props
  const [name, setName] = useState<string>(vocabulary ? vocabulary.name : '')
  const [languages] = useState<Option[]>(getWebSpeechApiLanguages())
  const [wordsVoiceLanguage, setWordsVoiceLanguage] = useState<string>(
    vocabulary ? vocabulary.wordsVoiceLanguage : '',
  )
  const [translationsVoiceLanguage, setTranslationsVoiceLanguage] =
    useState<string>(vocabulary ? vocabulary.translationsVoiceLanguage : '')

  const addVocabularyHandler = () => {
    if (name) {
      const vocabulary: CreateVocabulary = {
        name,
        wordsVoiceLanguage,
        translationsVoiceLanguage,
        createdAt: new Date().toISOString(),
        words: [],
      }
      onAddVocabulary!(vocabulary)
      onClickCancel()
    }
  }

  const updateVocabularyHandler = () => {
    if (name && vocabulary) {
      const updatedVocabulary: Vocabulary = {
        id: vocabulary.id,
        name,
        wordsVoiceLanguage,
        translationsVoiceLanguage,
        createdAt: vocabulary.createdAt,
        words: vocabulary.words,
      }
      onUpdateVocabulary!(updatedVocabulary)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <h2 className={styles.title}>{title}</h2>
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Vocabulary name..."
          />
        </Form.Group>
        <div className="mb-3">
          <MainSelect
            placeholder="word voice language"
            select={
              wordsVoiceLanguage
                ? languages.find(
                    (lang) => lang.value === wordsVoiceLanguage,
                  ) || { label: '', value: '' }
                : { label: '', value: '' }
            }
            setSelect={(option) => {
              setWordsVoiceLanguage(option.value as string)
            }}
            selects={languages}
          />
        </div>
        <div>
          <MainSelect
            placeholder="translation voice language"
            select={
              translationsVoiceLanguage
                ? languages.find(
                    (lang) => lang.value === translationsVoiceLanguage,
                  ) || { label: '', value: '' }
                : { label: '', value: '' }
            }
            setSelect={(option) => {
              setTranslationsVoiceLanguage(option.value as string)
            }}
            selects={languages}
          />
        </div>
        <div className={styles.btnContainer}>
          <Button onClick={onClickCancel} variant={'light'}>
            Cancel
          </Button>
          <div className={styles.btnSeparator}></div>
          {vocabulary ? (
            <Button
              onClick={updateVocabularyHandler}
              className={styles.createBtn}
              variant="success"
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={addVocabularyHandler}
              className={styles.createBtn}
              variant="success"
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {})(FormAddVocabulary)
