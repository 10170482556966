import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { CreateRecipe, Recipe } from '../types/recipe'
import {
  loadRecipes,
  modifyRecipe,
  removeRecipe,
  saveRecipe,
} from '../api/recipe'

// get all recipes
export const getRecipes = createAsyncThunk(
  'recipe/getRecipes',
  async (_, thunkApi) => {
    try {
      return await loadRecipes()
    } catch (e) {
      return thunkApi.rejectWithValue('getRecipeError')
    }
  },
)

// add recipe
export const addRecipe = createAsyncThunk(
  'recipe/addRecipe',
  async (recipe: CreateRecipe, thunkApi) => {
    try {
      return await saveRecipe(recipe)
    } catch (e) {
      return thunkApi.rejectWithValue('addRecipeError')
    }
  },
)

// update recipe
export const updateRecipe = createAsyncThunk(
  'recipe/updateRecipe',
  async (recipe: Recipe, thunkApi) => {
    try {
      return await modifyRecipe(recipe)
    } catch (e) {
      return thunkApi.rejectWithValue('updateRecipeError')
    }
  },
)

// delete objective
export const deleteRecipe = createAsyncThunk(
  'recipe/deleteRecipe',
  async (id: string, thunkApi) => {
    try {
      return await removeRecipe(id)
    } catch (e) {
      return thunkApi.rejectWithValue('deleteRecipeError')
    }
  },
)

interface RecipeSliceState {
  recipes: Recipe[]
  loading: boolean
  error?: SerializedError
}

const initialState: RecipeSliceState = {
  recipes: [],
  loading: false,
  error: undefined,
}

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {},
  extraReducers: {
    // get recipes
    [getRecipes.fulfilled.type]: (state, action: PayloadAction<Recipe[]>) => {
      state.recipes = action.payload
      state.loading = false
      state.error = undefined
    },

    [getRecipes.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [getRecipes.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // add objective
    [addRecipe.fulfilled.type]: (state, action: PayloadAction<Recipe>) => {
      const recipes = state.recipes
      recipes.push(action.payload)
      state.recipes = recipes
      state.loading = false
      state.error = undefined
    },

    [addRecipe.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [addRecipe.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // delete objective
    [deleteRecipe.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.recipes = state.recipes.filter(
        (recipe) => recipe.id !== action.payload,
      )
      state.loading = false
      state.error = undefined
    },

    [deleteRecipe.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [deleteRecipe.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // update recipestatus
    [updateRecipe.fulfilled.type]: (state, action: PayloadAction<Recipe>) => {
      if (state.recipes.length > 0) {
        const updatedObjectives = state.recipes.map((recipe) =>
          recipe.id === action.payload.id ? action.payload : recipe,
        )
        state.recipes = updatedObjectives
      }
      state.loading = false
      state.error = undefined
    },

    [updateRecipe.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [updateRecipe.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },

  // (builder) => {
  //     builder.addCase(getDevelopers.fulfilled, (state, action) => {
  //         state.developers = action.payload as IDeveloper[];
  //     })

  //     builder.addCase(addDeveloper.fulfilled, (state, action) => {
  //         state.developers.push(action.payload as IDeveloper);
  //     })

  //     builder.addCase(deleteDeveloper.fulfilled, (state, action) => {
  //         state.developers = state.developers.filter(developer => developer.id != action.payload)
  //     })
  // }
})

export const {} = recipeSlice.actions
export default recipeSlice.reducer
