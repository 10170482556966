import React from 'react'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Memory as MemoryType } from '../../types/memory'
import IconButton from '../../../../../components/IconButton/IconButton'
import styles from './Memory.module.scss'
import { format } from 'date-fns'

interface MemoryProps {
  memory: MemoryType
  onEditMemory: (memory: MemoryType) => void
  onDetails: () => void
  onDeleteMemory: (id?: number) => void
}

const Memory = (props: MemoryProps) => {
  const { memory, onEditMemory, onDetails, onDeleteMemory } = props

  return (
    <div className={styles.root} onClick={onDetails}>
      <div className={styles.topContainer}>
        <div className={styles.name}>{memory.name}</div>
        <div className={styles.btnContainer}>
          <IconButton onClick={() => onEditMemory(memory)}>
            <MdModeEdit size={22} />
          </IconButton>
          <IconButton onClick={() => onDeleteMemory(memory.id)}>
            <MdDelete size={22} />
          </IconButton>
        </div>
      </div>

      <div className={styles.tags}>
        {memory?.tags &&
          memory.tags.length > 0 &&
          memory.tags.map((tag: string, index: number) => (
            <div key={`${index}-${tag}`} className={styles.tag}>
              {tag}
            </div>
          ))}
      </div>
      <div className={styles.createdAt}>
        Created at: {format(new Date(memory.createdAt), 'yyyy-MM-dd')}
      </div>
    </div>
  )
}

export default Memory
