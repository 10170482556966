import React from 'react'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { Idea as IdeaType } from '../../types/idea'
import IconButton from '../../../../../components/IconButton/IconButton'
import styles from './Idea.module.scss'
import { format } from 'date-fns'

interface IdeaProps {
  idea: IdeaType
  onEditIdea: (idea: IdeaType) => void
  onDetails: () => void
  onDeleteIdea: (id?: number) => void
}

const Idea = (props: IdeaProps) => {
  const { idea, onEditIdea, onDetails, onDeleteIdea } = props

  return (
    <div className={styles.root} onClick={onDetails}>
      <div className={styles.topContainer}>
        <div className={styles.name}>{idea.name}</div>
        <div className={styles.btnContainer}>
          <IconButton onClick={() => onEditIdea(idea)}>
            <MdModeEdit size={22} />
          </IconButton>
          <IconButton onClick={() => onDeleteIdea(idea.id)}>
            <MdDelete size={22} />
          </IconButton>
        </div>
      </div>
      <div className={styles.tags}>
        {idea?.tags &&
          idea.tags.length > 0 &&
          idea.tags.map((tag: string, index: number) => (
            <div key={`${index}-${tag}`} className={styles.tag}>
              {tag}
            </div>
          ))}
      </div>
      <div className={styles.createdAt}>
        Created at: {format(new Date(idea.createdAt), 'yyyy-MM-dd')}
      </div>
    </div>
  )
}

export default Idea
