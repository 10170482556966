import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { Memory as MemoryType } from '../types/memory'
import styles from './MemoriesPage.module.scss'
import { deleteMemory, getMemories } from '../store/memory.slice'
import Memory from '../components/Memory/Memory'
import IconButton from '../../../../components/IconButton/IconButton'
import { BiMemoryCard } from 'react-icons/bi'
import FormAddMemory from '../components/FormAddMemory/FormAddMemory'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import Confirm from '../../../../components/Confirm/Confirm'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import MemoryDetails from '../components/MemoryDetails/MemoryDetails'
import { IoFilter } from 'react-icons/io5'
import MemoriesFilters from '../components/MemoriesFilters/MemoriesFilters'
import TopDrawer from '../../../../components/TopDrawer/TopDrawer'

export interface MemoriesPageProps {
  memories?: MemoryType[]
  screenWidth: number
  getMemories: () => void
  deleteMemory: (id: number) => void
}

const MemoriesPage = (props: MemoriesPageProps) => {
  const { memories, screenWidth, getMemories, deleteMemory } = props
  const [isActiveAddMemoryTable, setIsActiveAddMemoryTable] =
    useState<boolean>(false)
  const [isActiveEditMemoryTable, setIsActiveEditMemoryTable] =
    useState<boolean>(false)
  const [isActiveFiltersModal, setIsActiveFiltersModal] =
    useState<boolean>(false)
  const [selectedMemory, setSelectedMemory] = useState<MemoryType | undefined>()
  const [selectedMemoryId, setSelectedMemoryId] = useState<number | undefined>()
  const [memoryDetails, setMemoryDetails] = useState<MemoryType | undefined>()

  useEffect(() => {
    getMemories()
  }, [])

  const onOpenAddMemoryTable = () => {
    setIsActiveAddMemoryTable(true)
  }

  const onClickCancel = () => {
    setIsActiveAddMemoryTable(false)
  }

  const onEditMemory = (memory: MemoryType) => {
    setSelectedMemory(memory)
    setIsActiveEditMemoryTable(true)
  }

  const onDeleteMemory = () => {
    if (selectedMemoryId) {
      deleteMemory(selectedMemoryId)
      setSelectedMemoryId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.memoryTop}>
        <div className={styles.buttons}>
          <div title={'Filters'} className={styles.filtersButton}>
            <IconButton onClick={() => setIsActiveFiltersModal(true)}>
              <IoFilter className={styles.icon} size={30} />
            </IconButton>
          </div>
          <div title={'Create New Memory'} className={styles.addMemoryButton}>
            <IconButton onClick={onOpenAddMemoryTable}>
              <BiMemoryCard className={styles.icon} size={30} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={styles.memoryContainer}>
        <div className={styles.memories}>
          {memories &&
            memories.map((memory, index) => (
              <div className={styles.memory} key={memory.id}>
                <Memory
                  memory={memory}
                  onEditMemory={onEditMemory}
                  onDetails={() => setMemoryDetails(memory)}
                  onDeleteMemory={setSelectedMemoryId}
                  key={`${index}-${memory.id}-memory`}
                />
              </div>
            ))}
        </div>
        <RightDrawer
          title={'Add Memory'}
          size="l"
          show={isActiveAddMemoryTable}
          onHide={onClickCancel}
        >
          <FormAddMemory onClickCancel={onClickCancel} />
        </RightDrawer>

        <RightDrawer
          title={'Edit Memory'}
          size="l"
          show={isActiveEditMemoryTable}
          onHide={() => setIsActiveEditMemoryTable(false)}
        >
          {isActiveEditMemoryTable && selectedMemory && (
            <FormAddMemory
              memory={{
                ...selectedMemory,
                description: convertHtmlToNode(
                  selectedMemory!.description as string,
                ),
              }}
              onClickCancel={() => setIsActiveEditMemoryTable(false)}
            />
          )}
        </RightDrawer>

        <RightDrawer
          title={`${memoryDetails?.name}`}
          size="l"
          show={!!memoryDetails}
          onHide={() => setMemoryDetails(undefined)}
        >
          {memoryDetails && <MemoryDetails memory={memoryDetails} />}
        </RightDrawer>

        <TopDrawer
          title={`Memories filters`}
          size={screenWidth < 502 ? 'vs' : 'es'}
          show={isActiveFiltersModal}
          onHide={() => setIsActiveFiltersModal(false)}
        >
          <MemoriesFilters />
        </TopDrawer>

        {selectedMemoryId && (
          <Confirm
            confirm="Are you really want to delete this Memory?"
            confirmBtnLabel="Delete"
            confirmBtnVariant="danger"
            onConfirm={onDeleteMemory}
            onCancel={() => setSelectedMemoryId(undefined)}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  memories: state.memory.memories,
  screenWidth: state.main.screenWidth,
})

export default connect(mapStateToProps, {
  getMemories,
  deleteMemory,
})(MemoriesPage)
