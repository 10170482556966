import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  CalendarEvent,
  CreateEvent,
  Event,
  UpcomingEvents,
} from '../types/event'
import { loadEvents, modifyEvent, removeEvent, saveEvent } from '../api/event'
import { findCurrentTimePeriodEvents } from '../utils/findCurrentTimePeriodEvents'
import { isWithinInterval } from 'date-fns'
import { findEventsByDate } from '../utils/findEventsByDate'

// get all events
export const getEvents = createAsyncThunk(
  'event/getEvents',
  async (_, thunkApi) => {
    try {
      return await loadEvents()
    } catch (e) {
      return thunkApi.rejectWithValue('getEventsError')
    }
  },
)

// add event
export const addEvent = createAsyncThunk(
  'event/addEvent',
  async (event: CreateEvent, thunkApi) => {
    try {
      return await saveEvent(event)
    } catch (e) {
      return thunkApi.rejectWithValue('addEventError')
    }
  },
)

// update event
export const updateEvent = createAsyncThunk(
  'event/updateEvent',
  async (event: Event, thunkApi) => {
    try {
      return await modifyEvent(event)
    } catch (e) {
      return thunkApi.rejectWithValue('updateEventError')
    }
  },
)

// delete objective
export const deleteEvent = createAsyncThunk(
  'event/deleteEvent',
  async (id: string, thunkApi) => {
    try {
      return await removeEvent(id)
    } catch (e) {
      return thunkApi.rejectWithValue('deleteEventError')
    }
  },
)

interface EventSliceState {
  events: Event[]
  calendarEvents: CalendarEvent[]
  upcomingEvents: UpcomingEvents
  event?: Event
  eventLoading: boolean
  evensLoaded: boolean
  eventError?: SerializedError
}

const initialState: EventSliceState = {
  events: [],
  calendarEvents: [],
  upcomingEvents: {
    firstDay: [],
    secondDay: [],
    thirdDay: [],
    forthDay: [],
    fifthDay: [],
    sixthDay: [],
    seventhDay: [],
  },
  event: undefined,
  eventLoading: false,
  evensLoaded: false,
  eventError: undefined,
}

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setCalendarEvents: (
      state: EventSliceState,
      action: PayloadAction<CalendarEvent[]>,
    ) => {
      state.calendarEvents = action.payload
    },
    setEvent: (
      state: EventSliceState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.event = state.events.find((e) => e.id === action.payload)
    },
    getUpcomingEvents: (state: EventSliceState) => {
      const getDate = (daysAfterCount: number) => {
        let date = new Date()
        date.setDate(date.getDate() + daysAfterCount)

        return date
      }
      const firstDay = new Date()
      const secondDay = getDate(1)
      const thirdDay = getDate(2)
      const forthDay = getDate(3)
      const fifthDay = getDate(4)
      const sixthDay = getDate(5)
      const seventhDay = getDate(6)

      state.upcomingEvents.firstDay = findEventsByDate(state.events, firstDay)
      state.upcomingEvents.secondDay = findEventsByDate(state.events, secondDay)
      state.upcomingEvents.thirdDay = findEventsByDate(state.events, thirdDay)
      state.upcomingEvents.forthDay = findEventsByDate(state.events, forthDay)
      state.upcomingEvents.fifthDay = findEventsByDate(state.events, fifthDay)
      state.upcomingEvents.sixthDay = findEventsByDate(state.events, sixthDay)
      state.upcomingEvents.seventhDay = findEventsByDate(
        state.events,
        seventhDay,
      )
    },
  },
  extraReducers: {
    // get events
    [getEvents.fulfilled.type]: (state, action: PayloadAction<Event[]>) => {
      state.events = action.payload
      state.eventLoading = false
      state.eventError = undefined
      state.evensLoaded = true
    },

    [getEvents.pending.type]: (state) => {
      state.eventLoading = true
      state.eventError = undefined
    },

    [getEvents.rejected.type]: (state, action) => {
      state.eventLoading = false
      state.eventError = action.payload
      state.evensLoaded = true
    },

    // add objective
    [addEvent.fulfilled.type]: (state, action: PayloadAction<Event>) => {
      const events = state.events
      events.push(action.payload)
      state.events = events
      state.eventLoading = false
      state.eventError = undefined
    },

    [addEvent.pending.type]: (state) => {
      state.eventLoading = true
      state.eventError = undefined
    },

    [addEvent.rejected.type]: (state, action) => {
      state.eventLoading = false
      state.eventError = action.payload
    },

    // delete objective
    [deleteEvent.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.events = state.events.filter((event) => event.id !== action.payload)
      state.calendarEvents = state.calendarEvents.filter(
        (event) => event.id !== action.payload,
      )
      state.eventLoading = false
      state.eventError = undefined
    },

    [deleteEvent.pending.type]: (state) => {
      state.eventLoading = true
      state.eventError = undefined
    },

    [deleteEvent.rejected.type]: (state, action) => {
      state.eventLoading = false
      state.eventError = action.payload
    },

    // update eventstatus
    [updateEvent.fulfilled.type]: (state, action: PayloadAction<Event>) => {
      state.events = state.events.map((event) =>
        event.id === action.payload.id ? action.payload : event,
      )
      state.calendarEvents = state.calendarEvents.map((event) =>
        event.id === action.payload.id
          ? {
              ...event,
              title: event.title,
            }
          : event,
      )
      state.eventLoading = false
      state.eventError = undefined
    },

    [updateEvent.pending.type]: (state) => {
      state.eventLoading = true
      state.eventError = undefined
    },

    [updateEvent.rejected.type]: (state, action) => {
      state.eventLoading = false
      state.eventError = action.payload
    },
  },

  // (builder) => {
  //     builder.addCase(getDevelopers.fulfilled, (state, action) => {
  //         state.developers = action.payload as IDeveloper[];
  //     })

  //     builder.addCase(addDeveloper.fulfilled, (state, action) => {
  //         state.developers.push(action.payload as IDeveloper);
  //     })

  //     builder.addCase(deleteDeveloper.fulfilled, (state, action) => {
  //         state.developers = state.developers.filter(developer => developer.id != action.payload)
  //     })
  // }
})

export const { setCalendarEvents, setEvent, getUpcomingEvents } =
  eventSlice.actions
export default eventSlice.reducer
