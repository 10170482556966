import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addMemory, updateMemory } from '../../store/memory.slice'
import { RootState } from '../../../../../redux/store'
import { convertNodeToHtml } from '../../../../../utils/convertNodeToHtml'
import TagSelect from '../../../../../components/TagSelect/TagSelect'
import Textarea from '../../../../../components/Textarea/Textarea'
import styles from './FormAddMemory.module.scss'
import { CreateMemory, Memory } from '../../types/memory'

interface FormAddMemoryProps {
  memory?: Memory
  onClickCancel: () => void
  addMemory?: (memory: CreateMemory) => void
  updateMemory?: (memory: Memory) => void
}

const FormAddMemory = (props: FormAddMemoryProps) => {
  const { memory, onClickCancel, addMemory, updateMemory } = props

  const [name, setName] = useState<string>(memory ? memory.name : '')
  const [description, setDescription] = useState<any>(
    memory ? memory.description : '',
  )
  const [tags, setTags] = useState<string[]>(memory ? memory.tags : [])

  const onAddMemory = () => {
    if (name && convertNodeToHtml(description)) {
      const memory: CreateMemory = {
        name,
        description: convertNodeToHtml(description),
        tags,
      }
      addMemory!(memory)
      onClickCancel()
    }
  }

  const onUpdateNote = () => {
    if (name && convertNodeToHtml(description)) {
      const updatedMemory: Memory = {
        ...memory!,
        name,
        description: convertNodeToHtml(description),
        tags,
      }
      updateMemory!(updatedMemory)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="Memory name..."
          className={styles.textInput}
        />
      </Form.Group>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Tags</Form.Label>
        <TagSelect tags={tags} setTags={setTags} />
      </div>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Description*</Form.Label>
        <Textarea value={description} setValue={setDescription} />
      </div>
      <div className={styles.btnContainer}>
        <div className={styles.btnSeparator}></div>
        {memory ? (
          <Button
            onClick={onUpdateNote}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddMemory}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addMemory,
  updateMemory,
})(FormAddMemory)
