import React, { useEffect, useState } from 'react'
import { BiTask } from 'react-icons/bi'
import { GoGear } from 'react-icons/go'
import { CgNotes } from 'react-icons/cg'
import { FaHome } from 'react-icons/fa'
import { LuBookMinus, LuClipboardEdit, LuWallet } from 'react-icons/lu'
import { IoCalendarOutline } from 'react-icons/io5'
import { MdGTranslate } from 'react-icons/md'
import { RiFolderInfoLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import styles from './MenuDrawer.module.scss'
import SubNavMenu from '../SubNavMenu/SubNavMenu'
import notesMenu from '../../config/notesMenu'
import { connect } from 'react-redux'
import { RootState } from '../../redux/store'
import { AuthUser } from '../../domains/Login/types/users'

interface MenuDrawerProps {
  isActiveMenu: boolean
  user?: AuthUser
  onClose: () => void
}

const MenuDrawer = (props: MenuDrawerProps) => {
  const [iconColor, setIconColor] = useState<string>('transparent')
  const [isMenuItemsHidden, setIsMenuItemsHidden] = useState<boolean>(false)
  const { isActiveMenu, user, onClose } = props

  useEffect(() => {
    if (isActiveMenu) {
      setIsMenuItemsHidden(true)
    } else {
      setTimeout(() => {
        setIsMenuItemsHidden(false)
      }, 500)
    }
  }, [isActiveMenu])

  const setColorTransparent = () => {
    setTimeout(() => {
      setIconColor('transparent')
    }, 400)
  }

  const setColorWhite = () => {
    setTimeout(() => {
      setIconColor('white')
    }, 150)
  }

  useEffect(() => {
    isActiveMenu ? setColorWhite() : setColorTransparent()
  }, [isActiveMenu])

  return (
    <div
      className={isActiveMenu ? styles.rootOpen : styles.rootClose}
      onClick={onClose}
    >
      {isMenuItemsHidden ? (
        <>
          <div
            onClick={(e) => e.stopPropagation()}
            className={isActiveMenu ? styles.drawerOpen : styles.drawerClose}
          >
            {user?.role === 'admin' && (
              <div className={styles.firstLink}>
                <NavLink
                  onClick={onClose}
                  title={'Home'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/'}
                >
                  <FaHome color={iconColor} size={22} />
                </NavLink>
              </div>
            )}
            {user?.role === 'admin' && (
              <div className={styles.firstLink}>
                <SubNavMenu menuItems={notesMenu}>
                  <NavLink
                    onClick={onClose}
                    title={'Notes'}
                    className={(naveData) =>
                      naveData.isActive ? styles.activeNavLink : styles.navLink
                    }
                    to={'/notes'}
                  >
                    <CgNotes color={iconColor} size={22} />
                  </NavLink>
                </SubNavMenu>
              </div>
            )}
            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Objectives'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/objectives'}
                >
                  <BiTask size={22} color={iconColor} />
                </NavLink>
              </div>
            )}
            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Events'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/events'}
                >
                  <IoCalendarOutline size={21} color={iconColor} />
                </NavLink>
              </div>
            )}

            <div>
              <NavLink
                onClick={onClose}
                title={'Translation'}
                className={(naveData) =>
                  naveData.isActive ? styles.activeNavLink : styles.navLink
                }
                to={'/translation'}
              >
                <MdGTranslate size={20} color={iconColor} />
              </NavLink>
            </div>

            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Whiteboard'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/whiteboards'}
                >
                  <LuClipboardEdit size={22} color={iconColor} />
                </NavLink>
              </div>
            )}

            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Books'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/books'}
                >
                  <LuBookMinus size={22} color={iconColor} />
                </NavLink>
              </div>
            )}
            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Purchases'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/purchases'}
                >
                  <LuWallet size={23} color={iconColor} />
                </NavLink>
              </div>
            )}
            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Information'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/information'}
                >
                  <RiFolderInfoLine size={22} color={iconColor} />
                </NavLink>
              </div>
            )}
            {user?.role === 'admin' && (
              <div>
                <NavLink
                  onClick={onClose}
                  title={'Settings'}
                  className={(naveData) =>
                    naveData.isActive ? styles.activeNavLink : styles.navLink
                  }
                  to={'/settings'}
                >
                  <GoGear size={22} color={iconColor} />
                </NavLink>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps, {})(MenuDrawer)
