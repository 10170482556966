import instance from '../../../../api/axios'
import { CreateIdea, Idea } from '../types/idea'

export const loadIdeas = async (): Promise<Idea[]> => {
  const response = await instance.get('/ideas')
  return response.data
}

export const loadIdea = async (id: number): Promise<Idea> => {
  const response = await instance.get(`/ideas/${id}`)
  return response.data
}

export const saveIdea = async (idea: CreateIdea): Promise<Idea> => {
  const response = await instance.post('/ideas', idea)
  return response.data
}

export const modifyIdea = async (idea: Idea): Promise<Idea> => {
  const response = await instance.put('/ideas', idea)
  return response.data
}

export const removeIdea = async (id: number): Promise<number> => {
  const response = await instance.delete(`/ideas/${id}`)
  return response.data
}
