import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { convertNodeToHtml } from '../../../../../utils/convertNodeToHtml'
import TagSelect from '../../../../../components/TagSelect/TagSelect'
import Textarea from '../../../../../components/Textarea/Textarea'
import styles from './FormAddRecipe.module.scss'
import { CreateRecipe, Recipe } from '../../types/recipe'
import { addRecipe, updateRecipe } from '../../store/recipe.slice'

interface FormAddRecipeProps {
  recipe?: Recipe
  onClickCancel: () => void
  addRecipe?: (recipe: CreateRecipe) => void
  updateRecipe?: (recipe: Recipe) => void
}

const FormAddRecipe = (props: FormAddRecipeProps) => {
  const { recipe, onClickCancel, addRecipe, updateRecipe } = props

  const [name, setName] = useState<string>(recipe ? recipe.name : '')
  const [description, setDescription] = useState<any>(
    recipe ? recipe.description : '',
  )
  const [ingredients, setiIngredients] = useState<string[]>(
    recipe ? recipe.ingredients : [],
  )

  const onAddRecipe = () => {
    if (name && convertNodeToHtml(description)) {
      const recipe: CreateRecipe = {
        name,
        description: convertNodeToHtml(description),
        ingredients,
      }
      addRecipe!(recipe)
      onClickCancel()
    }
  }

  const onUpdateNote = () => {
    if (name && convertNodeToHtml(description)) {
      const updatedRecipe: Recipe = {
        ...recipe!,
        name,
        description: convertNodeToHtml(description),
        ingredients,
      }
      updateRecipe!(updatedRecipe)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="Recipe name..."
          className={styles.textInput}
        />
      </Form.Group>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Tags</Form.Label>
        <TagSelect tags={ingredients} setTags={setiIngredients} />
      </div>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Description*</Form.Label>
        <Textarea value={description} setValue={setDescription} />
      </div>
      <div className={styles.btnContainer}>
        <div className={styles.btnSeparator}></div>
        {recipe ? (
          <Button
            onClick={onUpdateNote}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddRecipe}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addRecipe,
  updateRecipe,
})(FormAddRecipe)
