import React, { useEffect } from 'react'
import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import HomePage from './domains/Home/pages/HomePage'
import { RootState } from './redux/store'
import { connect } from 'react-redux'
import { authUser } from './domains/Login/store/auth.slice'
import MyLoader from './components/MyLoader/MyLoader'
import LoginPage from './domains/Login/pages/LoginPage'
import ObjectivesMain from './domains/Objectives/pages/ObjectivesMain/ObjectivesMain'
import CreateNewObjective from './domains/Objectives/pages/CreateNewObjective/CreateNewObjective'
import Objective from './domains/Objectives/pages/Objective'
import TranslationMain from './domains/Translations/pages/TranslationMain'
import Settings from './domains/Settings/pages/Settings'
import NotesPage from './domains/Notes/pages/NotesPage'
import EventsPage from './domains/Events/pages/EventsPage'
import BooksPage from './domains/Books/pages/BooksPage'
import { setScreenHeight, setScreenWidth } from './redux/main.slice'
import { AuthUser } from './domains/Login/types/users'
import WhiteboardPage from './domains/Whiteboard/pages/WhiteboardPage/WhiteboardPage'
import WhiteboardListPage from './domains/Whiteboard/pages/WhiteboardListPage/WhiteboardListPage'

interface AppProps {
  user?: AuthUser
  isAuthLoading: boolean
  authUser: () => void
  setScreenHeight(height: number): void
  setScreenWidth(width: number): void
}

function App({
  user,
  isAuthLoading,
  authUser,
  setScreenHeight,
  setScreenWidth,
}: AppProps) {
  useEffect(() => {
    authUser()
    setScreenHeight(window.innerHeight)
    setScreenWidth(window.innerWidth)
  }, [])

  return (
    <>
      {isAuthLoading ? (
        <MyLoader />
      ) : (
        <>
          {user?.role === 'admin' ? (
            <div className="App">
              <MainLayout>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route
                    path="/notes"
                    element={<Navigate to="/notes/to-dos" replace />}
                  />
                  <Route path="/notes/*" element={<NotesPage />} />
                  <Route path="/objectives" element={<ObjectivesMain />} />
                  <Route
                    path="/objectives/create-new-objective"
                    element={<CreateNewObjective />}
                  />
                  <Route path="/objective/:name/:id" element={<Objective />} />
                  <Route path="/events" element={<EventsPage />} />
                  <Route path="/translation" element={<TranslationMain />} />
                  <Route path="/whiteboards" element={<WhiteboardListPage />} />
                  <Route path="/whiteboards/:id" element={<WhiteboardPage />} />
                  <Route path="/books" element={<BooksPage />} />
                  <Route path="/settings" element={<Settings />} />
                </Routes>
              </MainLayout>
            </div>
          ) : user?.role === 'user' ? (
            <div className="App">
              <MainLayout>
                <Routes>
                  <Route path="/translation" element={<TranslationMain />} />
                </Routes>
              </MainLayout>
            </div>
          ) : (
            <LoginPage />
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  isAuthLoading: state.auth.isAuthLoading,
})

export default connect(mapStateToProps, {
  authUser,
  setScreenHeight,
  setScreenWidth,
})(App)
