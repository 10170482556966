import React from 'react'
import styles from './DatePickerMulti.module.scss'
import { format } from 'date-fns'
import DatePicker from 'react-date-picker'
import { IoMdClose } from 'react-icons/io'

interface DatePickerMultiProps {
  dates: Date[]
  limit?: number
  setDates: (date: Date[]) => void
}

const DatePickerMulti = (props: DatePickerMultiProps) => {
  const { dates, limit = 0, setDates } = props

  const setUniqueDate = (date: Date) => {
    const foundDate = dates.find(
      (d) =>
        format(new Date(d), 'dd/MM/yyyy') ===
        format(new Date(date), 'dd/MM/yyyy'),
    )

    if (date === null) {
      setDates([])
    } else {
      if (!foundDate) {
        if (limit === 0) {
          setDates([...dates, date])
        } else if (limit && dates.length < limit) {
          setDates([...dates, date])
        }
      }
    }
  }

  const onRemoveTag = (i: number) => {
    setDates(dates.filter((date: Date, index: number) => index !== i))
  }

  return (
    <div>
      <DatePicker
        onChange={(date: Date) => setUniqueDate(date)}
        value={dates[dates.length - 1]}
        className={styles.picker}
        format="dd-MM-yyyy"
        minDate={limit === 2 && dates.length > 0 ? dates[0] : undefined}
      />
      <div className={`${dates?.length > 0 && styles.dates}`}>
        {dates &&
          dates.map((date: Date, index: number) => (
            <div
              key={`${index}-${format(new Date(date), 'dd-MM-yyyy')}`}
              className={styles.date}
            >
              <span className={styles.text}>
                {format(new Date(date), 'dd/MM/yyyy')}
              </span>
              <span onClick={() => onRemoveTag(index)} className={styles.close}>
                <IoMdClose />
              </span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default DatePickerMulti
