import React from 'react'
import styles from './RecipeDetails.module.scss'
import { Recipe } from '../../types/recipe'

interface RecipeDetailsProps {
  recipe: Recipe
}

const RecipeDetails = (props: RecipeDetailsProps) => {
  const { recipe } = props

  return (
    <div className={styles.root}>
      <div className={styles.tagsContainer}>
        <span>Tags: </span>
        <span className={styles.tags}>
          {recipe?.ingredients?.length > 0 &&
            recipe.ingredients.map((ingredient, i) => (
              <span
                key={`${i}-${ingredient}-recipe-details`}
                className={styles.tag}
              >
                {ingredient}
              </span>
            ))}
        </span>
      </div>

      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: recipe.description,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default RecipeDetails
