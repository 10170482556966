import { differenceInDays, format, isWithinInterval } from 'date-fns'
import { CalendarEvent, Event } from '../types/event'

export const findCurrentTimePeriodEvents = (
  events: Event[],
  start: Date,
  end: Date,
): CalendarEvent[] => {
  let currentTimePeriodCalendarEvents: CalendarEvent[] = []
  events.forEach((event) => {
    if (
      event.periodicity === 'Once' &&
      isWithinInterval(new Date(event.dates[0]), {
        start: new Date(start),
        end: new Date(end),
      })
    ) {
      currentTimePeriodCalendarEvents.push({
        id: event.id,
        title: event.title,
        priority: event.priority,
        type: event.type,
        date: format(new Date(event.dates[0]), 'yyyy-MM-dd'),
      })
    } else if (
      event.periodicity === 'Every Year' &&
      (isWithinInterval(
        new Date(
          new Date(start).getFullYear(),
          new Date(event.dates[0]).getMonth(),
          new Date(event.dates[0]).getDate(),
        ),
        {
          start: new Date(start),
          end: new Date(end),
        },
      ) ||
        isWithinInterval(
          new Date(
            new Date(end).getFullYear(),
            new Date(event.dates[0]).getMonth(),
            new Date(event.dates[0]).getDate(),
          ),
          {
            start: new Date(start),
            end: new Date(end),
          },
        ))
    ) {
      if (new Date(start).getFullYear() !== new Date(end).getFullYear()) {
        currentTimePeriodCalendarEvents.push({
          id: event.id,
          title: event.title,
          priority: event.priority,
          type: event.type,
          date: format(
            new Date(
              new Date(start).getFullYear(),
              new Date(event.dates[0]).getMonth(),
              new Date(event.dates[0]).getDate(),
            ),
            'yyyy-MM-dd',
          ),
        })
        currentTimePeriodCalendarEvents.push({
          id: event.id,
          title: event.title,
          priority: event.priority,
          type: event.type,
          date: format(
            new Date(
              new Date(end).getFullYear(),
              new Date(event.dates[0]).getMonth(),
              new Date(event.dates[0]).getDate(),
            ),
            'yyyy-MM-dd',
          ),
        })
      } else {
        currentTimePeriodCalendarEvents.push({
          id: event.id,
          title: event.title,
          priority: event.priority,
          type: event.type,
          date: format(
            new Date(
              new Date(start).getFullYear(),
              new Date(event.dates[0]).getMonth(),
              new Date(event.dates[0]).getDate(),
            ),
            'yyyy-MM-dd',
          ),
        })
      }
    } else if (event.periodicity === 'By Dates') {
      event.dates.forEach((date) => {
        if (
          isWithinInterval(new Date(date), {
            start: new Date(start),
            end: new Date(end),
          })
        ) {
          currentTimePeriodCalendarEvents.push({
            id: event.id,
            title: event.title,
            priority: event.priority,
            type: event.type,
            date: format(new Date(date), 'yyyy-MM-dd'),
          })
        }
      })
    } else if (event.periodicity === 'Period') {
      const days = differenceInDays(
        new Date(event.dates[1]),
        new Date(event.dates[0]),
      )

      let date: Date | string = event.dates[0]
      currentTimePeriodCalendarEvents.push({
        id: event.id,
        title: event.title,
        priority: event.priority,
        type: event.type,
        date: format(new Date(date), 'yyyy-MM-dd'),
      })

      for (let index = 0; index < days; index++) {
        let nextDate = new Date(date)
        nextDate.setDate(new Date(date).getDate() + 1)
        date = format(nextDate, 'yyyy-MM-dd')
        currentTimePeriodCalendarEvents.push({
          id: event.id,
          title: event.title,
          priority: event.priority,
          type: event.type,
          date: date,
        })
      }
    }
  })

  return currentTimePeriodCalendarEvents
}
