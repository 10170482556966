import React, { SyntheticEvent, useEffect, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
  MdDelete,
  MdModeEdit,
  MdContentCopy,
  MdKeyboardVoice,
} from 'react-icons/md'
import { CgMoveTask } from 'react-icons/cg'
import IconButton from '../../../../components/IconButton/IconButton'
import styles from './Word.module.scss'
import { Vocabulary, Word as WordType } from '../../types/vocabulary'

interface WordProps {
  vocabularies: Vocabulary[]
  wordOptionId?: number
  word: WordType
  index: number
  isWords: boolean
  activatedVoiceWordId?: number
  wordsVoiceLanguage?: string
  translationsVoiceLanguage?: string
  setWordOptionId: (id?: number) => void
  onClickDelete: () => void
  onClickUpdate: () => void
  onClickMoveWord: (word: WordType, newVocabularyId: number) => void
  onSetActivatedVoiceWordId: (wordId?: number) => void
}

const Word = (props: WordProps) => {
  const [isWord, setIsWord] = useState<boolean>(true)
  const [isVocabulariesList, setIsVocabulariesList] = useState<boolean>(false)
  const [isPlayAgain, setIsPlayAgain] = useState<boolean>(false)
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(
    null,
  )

  const {
    vocabularies,
    wordOptionId,
    word,
    index,
    isWords,
    activatedVoiceWordId,
    wordsVoiceLanguage,
    translationsVoiceLanguage,
    setWordOptionId,
    onClickDelete,
    onClickUpdate,
    onClickMoveWord,
    onSetActivatedVoiceWordId,
  } = props

  const onWordClick = (value: boolean) => {
    setIsWord(value)
  }

  const onCloseVocabulariesList = () => {
    const synth = window.speechSynthesis
    synth.cancel()
    setIsVocabulariesList(false)
    onSetActivatedVoiceWordId(undefined)
  }

  useEffect(() => {
    setIsWord(isWords)
  }, [isWords])

  useEffect(() => {
    if (word.word) {
      const synth = window.speechSynthesis
      const u = new SpeechSynthesisUtterance(
        isWord ? word.word : word.translation,
      )
      u.lang = isWord
        ? wordsVoiceLanguage || 'en-US'
        : translationsVoiceLanguage || 'en-US'

      setUtterance(u)

      return () => {
        synth.cancel()
      }
    }
  }, [word.word, isWord])

  const onVoiceActivate = () => {
    const synth = window.speechSynthesis

    if (!synth.speaking && utterance) {
      onSetActivatedVoiceWordId(word.id)
      synth.speak(utterance)
      utterance.addEventListener('end', (event) => {
        onSetActivatedVoiceWordId(undefined)
      })
    } else {
      onSetActivatedVoiceWordId(undefined)
      synth.cancel()
      if (activatedVoiceWordId !== word.id && utterance) {
        onSetActivatedVoiceWordId(word.id)
        setIsPlayAgain(true)
      }
    }
  }

  useEffect(() => {
    const synth = window.speechSynthesis
    if (isPlayAgain && utterance) {
      setIsPlayAgain(false)
      synth.speak(utterance)
      utterance.addEventListener('end', (event) => {
        onSetActivatedVoiceWordId(undefined)
      })
    }
  }, [isPlayAgain])

  useEffect(() => {
    document.addEventListener('click', onCloseVocabulariesList)

    return () => {
      document.removeEventListener('click', onCloseVocabulariesList)
    }
  }, [])

  useEffect(() => {
    if (wordOptionId && isVocabulariesList && wordOptionId !== word.id) {
      setIsVocabulariesList(false)
    }
  }, [wordOptionId])

  const onCopyWord = (word: string) => {
    navigator.clipboard.writeText(word)
  }

  return (
    <div className={styles.root}>
      <span className={styles.index}>{index + 1}.</span>
      {isWord ? (
        <div className={styles.topContainer}>
          <div onClick={() => onWordClick(false)} className={styles.cart}>
            {word.word}
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainer}>
              <IconButton
                onClick={() => {
                  onVoiceActivate()
                }}
                title={'Listen word'}
              >
                <MdKeyboardVoice
                  className={`${
                    activatedVoiceWordId === word.id
                      ? styles.activatedVoiceIcon
                      : styles.deactivatedVoiceIcon
                  }`}
                  size={22}
                />
              </IconButton>
            </div>
            <div className={styles.buttonContainer}>
              <IconButton
                onClick={() => {
                  onCopyWord(isWord ? word.word : word.translation)
                }}
                title={'Copy word'}
              >
                <MdContentCopy className={styles.copyIcon} size={22} />
              </IconButton>
            </div>
            <div className={styles.buttonContainer}>
              <IconButton
                onClick={() => {
                  setIsVocabulariesList(false)
                  setWordOptionId(word.id)
                }}
                title={'Open options'}
              >
                <BsThreeDotsVertical className={styles.updateIcon} size={22} />
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.translationRoot}
          onClick={() => onWordClick(true)}
        >
          <div className={styles.topContainer}>
            <div className={styles.translation}>{word.translation}</div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonContainer}>
                <IconButton
                  onClick={() => {
                    onVoiceActivate()
                  }}
                  title={'Listen word'}
                >
                  <MdKeyboardVoice
                    className={`${
                      activatedVoiceWordId === word.id
                        ? styles.activatedVoiceIcon
                        : styles.deactivatedVoiceIcon
                    }`}
                    size={22}
                  />
                </IconButton>
              </div>
              <div className={styles.buttonContainer}>
                <IconButton
                  onClick={() => {
                    onCopyWord(isWord ? word.word : word.translation)
                  }}
                  title={'Copy word'}
                >
                  <MdContentCopy className={styles.copyIcon} size={22} />
                </IconButton>
              </div>
              <div className={styles.buttonContainer}>
                <IconButton
                  onClick={() => {
                    setIsVocabulariesList(false)
                    setWordOptionId(word.id)
                  }}
                  title={'Open options'}
                >
                  <BsThreeDotsVertical
                    className={styles.updateIcon}
                    size={22}
                  />
                </IconButton>
              </div>
            </div>
          </div>

          {word?.dictionary && (
            <div className={styles.dictionary}>
              <div
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  __html: word.dictionary,
                }}
              />
            </div>
          )}
        </div>
      )}

      {wordOptionId === word.id && (
        <div
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          className={styles.options}
        >
          <IconButton
            onClick={() => {
              setWordOptionId(undefined)
              onClickUpdate()
            }}
            title={'Update word'}
          >
            <MdModeEdit className={styles.updateIcon} size={22} />
          </IconButton>
          <IconButton
            onClick={() => {
              setWordOptionId(undefined)
              setIsVocabulariesList(true)
            }}
            title={'Move word'}
          >
            <CgMoveTask className={styles.deleteIcon} size={24} />
          </IconButton>
          <IconButton
            onClick={() => {
              setWordOptionId(undefined)
              onClickDelete()
            }}
            title={'Delete word'}
          >
            <MdDelete className={styles.deleteIcon} size={22} />
          </IconButton>
        </div>
      )}
      {isVocabulariesList && (
        <div
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          className={styles.vocabulariesList}
        >
          {vocabularies &&
            vocabularies?.length > 0 &&
            vocabularies.map((vocabulary) => (
              <span
                onClick={() => {
                  setIsVocabulariesList(false)
                  onClickMoveWord(word, vocabulary.id)
                }}
                className={styles.vocabulariesListItem}
                key={`${vocabulary.name}-${vocabulary.id}`}
              >
                {vocabulary.name}
              </span>
            ))}
        </div>
      )}
    </div>
  )
}

export default Word
