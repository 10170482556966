import { differenceInDays, format, isWithinInterval } from 'date-fns'
import { CalendarEvent, Event } from '../types/event'

export const findEventsByDate = (
  events: Event[],
  date: Date,
): CalendarEvent[] => {
  let foundedEvents: CalendarEvent[] = []
  events.forEach((event) => {
    if (
      event.periodicity === 'Once' &&
      format(new Date(event.dates[0]), 'yyyy-MM-dd') ===
        format(date, 'yyyy-MM-dd')
    ) {
      foundedEvents.push({
        id: event.id,
        title: event.title,
        priority: event.priority,
        type: event.type,
        date: format(new Date(event.dates[0]), 'yyyy-MM-dd'),
      })
    } else if (
      event.periodicity === 'Every Year' &&
      format(
        new Date(
          new Date(date).getFullYear(),
          new Date(event.dates[0]).getMonth(),
          new Date(event.dates[0]).getDate(),
        ),
        'yyyy-MM-dd',
      ) === format(date, 'yyyy-MM-dd')
    ) {
      foundedEvents.push({
        id: event.id,
        title: event.title,
        priority: event.priority,
        type: event.type,
        date: format(new Date(event.dates[0]), 'yyyy-MM-dd'),
      })
    } else if (event.periodicity === 'By Dates') {
      event.dates.forEach((d) => {
        if (format(date, 'yyyy-MM-dd') === format(new Date(d), 'yyyy-MM-dd')) {
          foundedEvents.push({
            id: event.id,
            title: event.title,
            priority: event.priority,
            type: event.type,
            date: format(new Date(d), 'yyyy-MM-dd'),
          })
        }
      })
    } else if (event.periodicity === 'Period') {
      const days = differenceInDays(
        new Date(event.dates[1]),
        new Date(event.dates[0]),
      )

      let date: Date | string = event.dates[0]
      foundedEvents.push({
        id: event.id,
        title: event.title,
        priority: event.priority,
        type: event.type,
        date: format(new Date(date), 'yyyy-MM-dd'),
      })

      for (let index = 0; index < days; index++) {
        let nextDate = new Date(date)
        nextDate.setDate(new Date(date).getDate() + 1)
        date = format(nextDate, 'yyyy-MM-dd')
        foundedEvents.push({
          id: event.id,
          title: event.title,
          priority: event.priority,
          type: event.type,
          date: date,
        })
      }
    }
  })

  return foundedEvents
}
