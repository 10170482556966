import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../redux/store'
import styles from './EventsPage.module.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import RightDrawer from '../../../components/RightDrawer/RightDrawer'
import FormAddEvent from '../components/FormAddEvent/FormAddEvent'
import { FcCalendar } from 'react-icons/fc'
import IconButton from '../../../components/IconButton/IconButton'
import {
  addEvent,
  deleteEvent,
  getEvents,
  setCalendarEvents,
  setEvent,
  updateEvent,
} from '../store/event.slice'
import { CalendarEvent, CreateEvent, Event } from '../types/event'
import { findCurrentTimePeriodEvents } from '../utils/findCurrentTimePeriodEvents'
import MyLoader from '../../../components/MyLoader/MyLoader'
import EventDetails from '../components/EventDetails/EventDetails'
import EventCalendarItem from '../components/EventCalendarItem/EventCalendarItem'

interface EventsPageProps {
  events: Event[]
  event?: Event
  loading?: boolean
  loaded?: boolean
  calendarEvents: CalendarEvent[]
  getEvents: () => void
  addEvent: (event: CreateEvent) => void
  updateEvent: (event: Event) => void
  deleteEvent: (eventId: string) => void
  setCalendarEvents: (event: CalendarEvent[]) => void
  setEvent: (eventId?: string) => void
}

const EventsPage = (props: EventsPageProps) => {
  const {
    events,
    event,
    calendarEvents,
    loading,
    loaded,
    getEvents,
    addEvent,
    updateEvent,
    deleteEvent,
    setCalendarEvents,
    setEvent,
  } = props
  const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false)
  const [showUpdateEventModal, setShowUpdateEventModal] =
    useState<boolean>(false)
  const [currentStartDate, setCurrentStartDate] = useState<Date>(new Date())
  const [currentEndDate, setCurrentEndDate] = useState<Date>(new Date())
  const [preDefinedEventDate, setPreDefinedEventDate] = useState<Date>()

  useEffect(() => {
    getEvents()
  }, [])

  useEffect(() => {
    if ((events && events.length > 0 && currentStartDate, currentEndDate)) {
      setCalendarEvents(
        findCurrentTimePeriodEvents(events, currentStartDate, currentEndDate),
      )
    }
  }, [currentEndDate, currentStartDate, events, setCalendarEvents])

  const onDateClick = (e: any) => {
    setPreDefinedEventDate(e.date)
    setShowAddEventModal(true)
  }

  const onEventClick = (info: any) => {
    setEvent(info.event._def.extendedProps.uuid)
  }

  const onDateChange = (e: any) => {
    setCalendarEvents(findCurrentTimePeriodEvents(events, e.start, e.end))
    setCurrentStartDate(e.start)
    setCurrentEndDate(e.end)
  }

  const onAddEvent = (event: CreateEvent) => {
    addEvent(event)
  }

  const onDeleteEvent = (eventId: string) => {
    deleteEvent(eventId)
    setEvent(undefined)
  }

  const onCloseAddEvent = () => {
    setPreDefinedEventDate(undefined)
    setShowAddEventModal(false)
  }

  const getEventColor = (type: string): string => {
    let color: string = ''

    if (type === 'To Do') {
      color = 'coral'
    } else if (type === 'To Visit') {
      color = 'royalblue'
    } else if (type === 'Birthday') {
      color = 'limegreen'
    } else if (type === 'Death') {
      color = 'grey'
    } else if (type === 'Wedding') {
      color = 'darkorange'
    } else if (type === 'Vacation') {
      color = 'indianred'
    } else if (type === 'Other') {
      color = 'cadetblue'
    }

    return color
  }

  const setPropertyToTitle = (title: string, priority: string): string => {
    let newTitle: string = ''

    if (priority === 'High') {
      newTitle = `🔼 ${title}`
    } else if (priority === 'Medium') {
      newTitle = `⏹ ${title}`
    } else if (priority === 'Low') {
      newTitle = `🔽 ${title}`
    } else {
      newTitle = title
    }

    return newTitle
  }

  return (
    <>
      {loaded ? (
        <div className={styles.root}>
          <div className={styles.addEventButtonContainer}>
            <IconButton onClick={() => setShowAddEventModal(true)}>
              <FcCalendar className={styles.icon} size={30} />
            </IconButton>
          </div>
          <div className={styles.calendarContainer}>
            <FullCalendar
              viewClassNames={styles.calendar}
              // eventClassNames={styles.event} //event styles
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              dateClick={onDateClick}
              eventClick={onEventClick}
              eventContent={EventCalendarItem} // render view content
              datesSet={onDateChange}
              events={calendarEvents.map((e) => ({
                title: setPropertyToTitle(e.title, e.priority),
                date: e.date,
                uuid: e.id,
                color: getEventColor(e.type),
              }))}
            />
          </div>
          <RightDrawer
            show={showAddEventModal}
            onHide={onCloseAddEvent}
            title="Add Event"
          >
            <FormAddEvent
              onCancel={onCloseAddEvent}
              addEvent={onAddEvent}
              preDefinedEventDate={preDefinedEventDate}
            />
          </RightDrawer>
          <RightDrawer
            show={!!event && showUpdateEventModal}
            onHide={() => setShowUpdateEventModal(false)}
            title="Update Event"
          >
            <FormAddEvent
              onCancel={() => setShowUpdateEventModal(false)}
              updateEvent={updateEvent}
              event={event}
            />
          </RightDrawer>
          <RightDrawer
            show={!!event && !showUpdateEventModal}
            onHide={() => setEvent(undefined)}
            title={event?.title || ''}
          >
            {event && (
              <EventDetails
                event={event}
                onDelete={onDeleteEvent}
                onUpdate={() => setShowUpdateEventModal(true)}
              />
            )}
          </RightDrawer>
        </div>
      ) : (
        <MyLoader />
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  event: state.event.event,
  calendarEvents: state.event.calendarEvents,
  loading: state.event.eventLoading,
  loaded: state.event.evensLoaded,
})

export default connect(mapStateToProps, {
  getEvents,
  addEvent,
  updateEvent,
  deleteEvent,
  setCalendarEvents,
  setEvent,
})(EventsPage)
