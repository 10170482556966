import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { CreateLocation, Location } from '../types/location'
import {
  loadLocations,
  modifyLocation,
  removeLocation,
  saveLocation,
} from '../api/location'

// get all locations
export const getLocations = createAsyncThunk(
  'location/getLocations',
  async (_, thunkApi) => {
    try {
      return await loadLocations()
    } catch (e) {
      return thunkApi.rejectWithValue('getLocationError')
    }
  },
)

// add location
export const addLocation = createAsyncThunk(
  'location/addLocation',
  async (location: CreateLocation, thunkApi) => {
    try {
      return await saveLocation(location)
    } catch (e) {
      return thunkApi.rejectWithValue('addLocationError')
    }
  },
)

// update location
export const updateLocation = createAsyncThunk(
  'location/updateLocation',
  async (location: Location, thunkApi) => {
    try {
      return await modifyLocation(location)
    } catch (e) {
      return thunkApi.rejectWithValue('updateLocationError')
    }
  },
)

// delete objective
export const deleteLocation = createAsyncThunk(
  'location/deleteLocation',
  async (id: string, thunkApi) => {
    try {
      return await removeLocation(id)
    } catch (e) {
      return thunkApi.rejectWithValue('deleteLocationError')
    }
  },
)

interface LocationSliceState {
  locations: Location[]
  locationLoading: boolean
  locationError?: SerializedError
}

const initialState: LocationSliceState = {
  locations: [],
  locationLoading: false,
  locationError: undefined,
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {},
  extraReducers: {
    // get locations
    [getLocations.fulfilled.type]: (
      state,
      action: PayloadAction<Location[]>,
    ) => {
      state.locations = action.payload
      state.locationLoading = false
      state.locationError = undefined
    },

    [getLocations.pending.type]: (state) => {
      state.locationLoading = true
      state.locationError = undefined
    },

    [getLocations.rejected.type]: (state, action) => {
      state.locationLoading = false
      state.locationError = action.payload
    },

    // add objective
    [addLocation.fulfilled.type]: (state, action: PayloadAction<Location>) => {
      const locations = state.locations
      locations.push(action.payload)
      state.locations = locations
      state.locationLoading = false
      state.locationError = undefined
    },

    [addLocation.pending.type]: (state) => {
      state.locationLoading = true
      state.locationError = undefined
    },

    [addLocation.rejected.type]: (state, action) => {
      state.locationLoading = false
      state.locationError = action.payload
    },

    // delete objective
    [deleteLocation.fulfilled.type]: (state, action: PayloadAction<string>) => {
      state.locations = state.locations.filter(
        (location) => location.id !== action.payload,
      )
      state.locationLoading = false
      state.locationError = undefined
    },

    [deleteLocation.pending.type]: (state) => {
      state.locationLoading = true
      state.locationError = undefined
    },

    [deleteLocation.rejected.type]: (state, action) => {
      state.locationLoading = false
      state.locationError = action.payload
    },

    // update locationstatus
    [updateLocation.fulfilled.type]: (
      state,
      action: PayloadAction<Location>,
    ) => {
      if (state.locations.length > 0) {
        const updatedObjectives = state.locations.map((location) =>
          location.id === action.payload.id ? action.payload : location,
        )
        state.locations = updatedObjectives
      }
      state.locationLoading = false
      state.locationError = undefined
    },

    [updateLocation.pending.type]: (state) => {
      state.locationLoading = true
      state.locationError = undefined
    },

    [updateLocation.rejected.type]: (state, action) => {
      state.locationLoading = false
      state.locationError = action.payload
    },
  },

  // (builder) => {
  //     builder.addCase(getDevelopers.fulfilled, (state, action) => {
  //         state.developers = action.payload as IDeveloper[];
  //     })

  //     builder.addCase(addDeveloper.fulfilled, (state, action) => {
  //         state.developers.push(action.payload as IDeveloper);
  //     })

  //     builder.addCase(deleteDeveloper.fulfilled, (state, action) => {
  //         state.developers = state.developers.filter(developer => developer.id != action.payload)
  //     })
  // }
})

export const {} = locationSlice.actions
export default locationSlice.reducer
