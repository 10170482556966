import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  loadToDoes,
  loadToDo,
  saveToDo,
  modifyToDo,
  removeToDo,
} from '../api/toDo'
import { CreateToDo, ToDo } from '../types/toDo'

// get toDoes
export const getToDoes = createAsyncThunk(
  'note/getToDoes',
  async (_, thunkApi) => {
    try {
      return await loadToDoes()
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// get note
export const getToDo = createAsyncThunk(
  'note/getToDo',
  async (id: number, thunkApi) => {
    try {
      return await loadToDo(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// create note
export const addToDo = createAsyncThunk(
  'note/addToDo',
  async (note: CreateToDo, thunkApi) => {
    try {
      return await saveToDo(note)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// update note
export const updateToDo = createAsyncThunk(
  'note/updateToDo',
  async (note: ToDo, thunkApi) => {
    try {
      return await modifyToDo(note)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// delete note
export const deleteToDo = createAsyncThunk(
  'note/deleteToDo',
  async (id: number, thunkApi) => {
    try {
      return await removeToDo(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface ToDoSliceState {
  allToDoes: ToDo[]
  toDoes: ToDo[]
  toDoesLoading: boolean
  toDoesError?: SerializedError
}
//FIXME: move each to separate slice // todo, memory, idea
const initialState: ToDoSliceState = {
  allToDoes: [],
  toDoes: [],
  toDoesLoading: false,
  toDoesError: undefined,
}

const toDoSlice = createSlice({
  name: 'toDoes',
  initialState,
  reducers: {
    sortToDoes: (state: ToDoSliceState, action: PayloadAction<string>) => {
      const High = state.toDoes.filter((note: ToDo) => note.priority === 'High')
      const Medium = state.toDoes.filter(
        (note: ToDo) => note.priority === 'Medium',
      )
      const Low = state.toDoes.filter((note: ToDo) => note.priority === 'Low')
      if (action.payload === 'High') {
        state.toDoes = [...High, ...Medium, ...Low]
      } else if (action.payload === 'Medium') {
        state.toDoes = [...Medium, ...High, ...Low]
      } else if (action.payload === 'Low') {
        state.toDoes = [...Low, ...High, ...Medium]
      } else {
        state.toDoes = state.allToDoes
      }
    },
    filterToDoes: (state: ToDoSliceState, action: PayloadAction<string>) => {
      const sortedToDoes =
        action.payload === 'Default'
          ? state.allToDoes
          : state.allToDoes.filter((note: ToDo) => note.type === action.payload)
      state.toDoes = sortedToDoes
    },
  },
  extraReducers: {
    // get toDoes
    [getToDoes.fulfilled.type]: (state, action: PayloadAction<ToDo[]>) => {
      state.toDoes = action.payload
      state.allToDoes = action.payload
      state.toDoesLoading = false
      state.toDoesError = undefined
    },

    [getToDoes.pending.type]: (state) => {
      state.toDoesLoading = true
      state.toDoesError = undefined
    },

    [getToDoes.rejected.type]: (state, action) => {
      state.toDoesLoading = false
      state.toDoesError = action.payload
    },

    // add note
    [addToDo.fulfilled.type]: (state, action: PayloadAction<ToDo>) => {
      state.toDoes.unshift(action.payload)
      state.allToDoes.unshift(action.payload)
      state.toDoesLoading = false
      state.toDoesError = undefined
    },

    [addToDo.pending.type]: (state) => {
      state.toDoesLoading = true
      state.toDoesError = undefined
    },

    [addToDo.rejected.type]: (state, action) => {
      state.toDoesLoading = false
      state.toDoesError = action.payload
    },

    // update note
    [updateToDo.fulfilled.type]: (state, action: PayloadAction<ToDo>) => {
      state.toDoes = state.toDoes.map((note) =>
        note.id === action.payload.id ? action.payload : note,
      )
      state.allToDoes = state.allToDoes.map((note) =>
        note.id === action.payload.id ? action.payload : note,
      )
      state.toDoesLoading = false
      state.toDoesError = undefined
    },

    [updateToDo.pending.type]: (state) => {
      state.toDoesLoading = true
      state.toDoesError = undefined
    },

    [updateToDo.rejected.type]: (state, action) => {
      state.toDoesLoading = false
      state.toDoesError = action.payload
    },

    // delete note
    [deleteToDo.fulfilled.type]: (state, action: PayloadAction<number>) => {
      state.toDoes = state.toDoes.filter((note) => note.id !== action.payload)
      state.allToDoes = state.toDoes.filter(
        (note) => note.id !== action.payload,
      )
      state.toDoesLoading = false
      state.toDoesError = undefined
    },

    [deleteToDo.pending.type]: (state) => {
      state.toDoesLoading = true
      state.toDoesError = undefined
    },

    [deleteToDo.rejected.type]: (state, action) => {
      state.toDoesLoading = false
      state.toDoesError = action.payload
    },
  },
})

export const { sortToDoes, filterToDoes } = toDoSlice.actions
export default toDoSlice.reducer
