import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addIdea, updateIdea } from '../../store/idea.slice'
import { RootState } from '../../../../../redux/store'
import { convertNodeToHtml } from '../../../../../utils/convertNodeToHtml'
import TagSelect from '../../../../../components/TagSelect/TagSelect'
import Textarea from '../../../../../components/Textarea/Textarea'
import styles from './FormAddIdea.module.scss'
import { CreateIdea, Idea } from '../../types/idea'

interface FormAddIdeaProps {
  idea?: Idea
  onClickCancel: () => void
  addIdea?: (idea: CreateIdea) => void
  updateIdea?: (idea: Idea) => void
}

const FormAddIdea = (props: FormAddIdeaProps) => {
  const { idea, onClickCancel, addIdea, updateIdea } = props

  const [name, setName] = useState<string>(idea ? idea.name : '')
  const [description, setDescription] = useState<any>(
    idea ? idea.description : '',
  )
  const [tags, setTags] = useState<string[]>(idea ? idea.tags : [])

  const onAddIdea = () => {
    if (name && convertNodeToHtml(description)) {
      const idea: CreateIdea = {
        name,
        description: convertNodeToHtml(description),
        tags,
      }
      addIdea!(idea)
      onClickCancel()
    }
  }

  const onUpdateIdea = () => {
    if (name && convertNodeToHtml(description)) {
      const updatedIdea: Idea = {
        ...idea!,
        name,
        description: convertNodeToHtml(description),
        tags,
      }
      updateIdea!(updatedIdea)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="Idea name..."
          className={styles.textInput}
        />
      </Form.Group>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Tags</Form.Label>
        <TagSelect tags={tags} setTags={setTags} />
      </div>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Description*</Form.Label>
        <Textarea value={description} setValue={setDescription} />
      </div>
      <div className={styles.btnContainer}>
        <div className={styles.btnSeparator}></div>
        {idea ? (
          <Button
            onClick={onUpdateIdea}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddIdea}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addIdea,
  updateIdea,
})(FormAddIdea)
