import React, { useEffect, useState } from 'react'
import styles from './PriorityTag.module.scss'
import Tag from '../Tag/Tag'
import { FcHighPriority, FcLowPriority, FcMediumPriority } from 'react-icons/fc'
import { TagColors } from '../../types'

interface PriorityTagProps {
  priority: string
}

const PriorityTag = (props: PriorityTagProps) => {
  const { priority } = props
  const [color, setColor] = useState<TagColors>('Grey')

  useEffect(() => {
    if (priority) {
      if (priority === 'Low') {
        setColor('Green')
      } else if (priority === 'Medium') {
        setColor('Yellow')
      } else if (priority === 'High') {
        setColor('Red')
      }
    }
  }, [priority])

  return (
    <Tag name={priority} color={color}>
      {priority === 'High' ? (
        <FcHighPriority />
      ) : priority === 'Medium' ? (
        <FcMediumPriority />
      ) : (
        priority === 'Low' && <FcLowPriority />
      )}
    </Tag>
  )
}

export default PriorityTag
