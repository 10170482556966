import { ToDo } from '../types/toDo'

//sort by priority
export const sortToDoesByPriority = (
  objectives: ToDo[],
  priority: string,
): ToDo[] => {
  const HighPriorityObjectives = objectives.filter(
    (objective: ToDo) => objective.priority === 'High',
  )
  const MediumPriorityObjectives = objectives.filter(
    (objective: ToDo) => objective.priority === 'Medium',
  )
  const LowPriorityObjectives = objectives.filter(
    (objective: ToDo) => objective.priority === 'Low',
  )
  const sortedObjectives: ToDo[] = []

  if (priority === 'High') {
    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }

    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }
  }

  if (priority === 'Medium') {
    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }

    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }
  }

  if (priority === 'Low') {
    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }

    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }
  }
  return sortedObjectives
}
