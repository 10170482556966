import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import styles from './WhiteboardListPage.module.scss'
import IconButton from '../../../../components/IconButton/IconButton'
import { LuClipboardEdit } from 'react-icons/lu'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import FormAddWhiteboard from '../../components/FormAddObjective/FormAddWhiteboard'
import {
  addWhiteboard,
  deleteWhiteboard,
  getAllWhiteboards,
  setWhiteboard,
} from '../../store/whiteboard.slice'
import { CreateWhiteboard, Whiteboard } from '../../types/whiteboard'
import { useNavigate } from 'react-router-dom'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Confirm from '../../../../components/Confirm/Confirm'

interface WhiteboardListPageProps {
  whiteboards: Whiteboard[]
  whiteboard?: Whiteboard
  getAllWhiteboards: () => void
  setWhiteboard: (whiteboard?: Whiteboard) => void
  deleteWhiteboard: (id: string) => void
}

const WhiteboardListPage = (props: WhiteboardListPageProps) => {
  const {
    whiteboards,
    whiteboard,
    getAllWhiteboards,
    setWhiteboard,
    deleteWhiteboard,
  } = props
  const [isActiveAddWhiteboardDrawer, setIsActiveAddWhiteboardDrawer] =
    useState<boolean>(false)
  const [isActiveEditWhiteboardDrawer, setIsActiveEditWhiteboardDrawer] =
    useState<boolean>(false)
  const [selectedWhiteboardIdForDelete, setSelectedWhiteboardIdForDelete] =
    useState<string | undefined>()

  const navigator = useNavigate()

  useEffect(() => {
    getAllWhiteboards()
  }, [])

  const onOpenAddWhiteboardDrawer = () => {
    setIsActiveAddWhiteboardDrawer(true)
  }

  const onCloseAddWhiteboardDrawer = () => {
    setIsActiveAddWhiteboardDrawer(false)
  }

  const onOpenEditWhiteboardDrawer = (whiteboard: Whiteboard) => {
    setWhiteboard(whiteboard)
    setIsActiveEditWhiteboardDrawer(true)
  }

  const onCloseEditWhiteboardDrawer = () => {
    setWhiteboard(undefined)
    setIsActiveEditWhiteboardDrawer(false)
  }

  const onClickWhiteBoard = (whiteboard: Whiteboard) => {
    setWhiteboard(whiteboard)
    navigator(`/whiteboards/${whiteboard.id}`)
  }

  const onDeleteWhiteBoard = () => {
    deleteWhiteboard(selectedWhiteboardIdForDelete!)
    setSelectedWhiteboardIdForDelete(undefined)
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <div title={'Create New WhiteBoard'} className={styles.addWhiteboard}>
          <IconButton onClick={onOpenAddWhiteboardDrawer}>
            <LuClipboardEdit className={styles.icon} size={30} />
          </IconButton>
        </div>
      </div>
      <div className={styles.main}>
        {whiteboards?.length > 0 &&
          whiteboards.map((whiteboard: Whiteboard) => (
            <div
              key={whiteboard.id}
              onClick={() => onClickWhiteBoard(whiteboard)}
              className={styles.whiteboard}
            >
              <div className={styles.name}>{whiteboard.name}</div>
              <div className={styles.buttonContainer}>
                <IconButton>
                  <MdModeEdit
                    onClick={() => onOpenEditWhiteboardDrawer(whiteboard)}
                    className={styles.icon}
                    size={25}
                  />
                </IconButton>
                <IconButton>
                  <MdDelete
                    onClick={() =>
                      setSelectedWhiteboardIdForDelete(whiteboard.id)
                    }
                    className={styles.icon}
                    size={25}
                  />
                </IconButton>
              </div>
            </div>
          ))}
      </div>
      <RightDrawer
        title={'Add Whiteboard'}
        size="m"
        show={isActiveAddWhiteboardDrawer}
        onHide={onCloseAddWhiteboardDrawer}
      >
        <FormAddWhiteboard onClickCancel={onCloseAddWhiteboardDrawer} />
      </RightDrawer>
      <RightDrawer
        title={'Add Whiteboard'}
        size="m"
        show={isActiveEditWhiteboardDrawer}
        onHide={onCloseEditWhiteboardDrawer}
      >
        <FormAddWhiteboard
          onClickCancel={onCloseEditWhiteboardDrawer}
          whiteboard={whiteboard}
        />
      </RightDrawer>
      {selectedWhiteboardIdForDelete && (
        <Confirm
          confirm="Are you really want to delete this Whiteboard?"
          confirmBtnLabel="Delete"
          confirmBtnVariant="danger"
          onConfirm={onDeleteWhiteBoard}
          onCancel={() => setSelectedWhiteboardIdForDelete(undefined)}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  whiteboards: state.whiteboard.whiteboards,
  whiteboard: state.whiteboard.whiteboard,
})

export default connect(mapStateToProps, {
  getAllWhiteboards,
  setWhiteboard,
  deleteWhiteboard,
})(WhiteboardListPage)
