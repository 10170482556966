import instance from '../../../api/axios'
import {
  CreateWhiteboard,
  Whiteboard,
  WhiteboardRaw,
} from '../types/whiteboard'

export const loadWhiteboards = async (): Promise<Whiteboard[]> => {
  const response = await instance.get<WhiteboardRaw[]>('/whiteboards')

  return response.data
    ?.map((whiteboard: WhiteboardRaw) => ({
      ...whiteboard,
      excalidrawElement: whiteboard.excalidrawElement
        ? JSON.parse(whiteboard.excalidrawElement)
        : [],
    }))
    .sort((a: Whiteboard, b: Whiteboard) =>
      a.createdAt.localeCompare(b.createdAt),
    )
}

export const loadWhiteboard = async (id: string): Promise<Whiteboard> => {
  const response = await instance.get<WhiteboardRaw>(`/whiteboards/${id}`)

  return (
    response.data && {
      ...response.data,
      excalidrawElement: response.data.excalidrawElement
        ? JSON.parse(response.data.excalidrawElement)
        : undefined,
    }
  )
}

export const saveWhiteboard = async (
  createWhiteboard: CreateWhiteboard,
): Promise<Whiteboard | undefined> => {
  const response = await instance.post<WhiteboardRaw>(
    '/whiteboards',
    createWhiteboard,
  )

  const createdDashboard = response.data
    ? response.data?.excalidrawElement
      ? {
          ...response.data,
          excalidrawElement: JSON.parse(response?.data?.excalidrawElement),
        }
      : response.data
    : undefined

  return createdDashboard
}

export const modifyWhiteboard = async (
  whiteboard: Whiteboard,
): Promise<Whiteboard> => {
  const response = await instance.put<WhiteboardRaw>('/whiteboards', {
    ...whiteboard,
    excalidrawElement: JSON.stringify(whiteboard.excalidrawElement),
  })

  return (
    response.data && {
      ...response.data,
      excalidrawElement: JSON.parse(response.data.excalidrawElement),
    }
  )
}

export const removeWhiteboard = async (id: string): Promise<string> => {
  const response = await instance.delete(`/whiteboards/${id}`)
  return response.data
}
