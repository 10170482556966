import { configureStore } from '@reduxjs/toolkit'
import objectiveSlice from '../domains/Objectives/store/objective.slice'
import authSlice from '../domains/Login/store/auth.slice'
import vocabularySlice from '../domains/Translations/store/vocabulary.slice'
import linkSlice from '../domains/Notes/Links/store/link.slice'
import locationSlice from '../domains/Notes/Locations/store/location.slice'
import eventSlice from '../domains/Events/store/event.slice'
import toDoSlice from '../domains/Notes/ToDoes/store/toDo.slice'
import memorySlice from '../domains/Notes/Memories/store/memory.slice'
import ideaSlice from '../domains/Notes/Ideas/store/idea.slice'
import whiteboardSlice from '../domains/Whiteboard/store/whiteboard.slice'
import recipeSlice from '../domains/Notes/Recipe/store/recipe.slice'
import mainSlice from './main.slice'

const store = configureStore({
  reducer: {
    main: mainSlice,
    objective: objectiveSlice,
    auth: authSlice,
    toDo: toDoSlice,
    memory: memorySlice,
    idea: ideaSlice,
    vocabulary: vocabularySlice,
    link: linkSlice,
    location: locationSlice,
    event: eventSlice,
    whiteboard: whiteboardSlice,
    recipe: recipeSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>

export default store
