import { CreateLink, Link } from '../types/link'
import instance from '../../../../api/axios'

export const loadLinksTree = async (): Promise<Link[]> => {
  const response = await instance.get('/link')
  return response.data
}

export const loadLinksTreeById = async (id: string): Promise<Link> => {
  const response = await instance.get(`/link/${id}`)
  return response.data
}

export const saveLink = async (idea: CreateLink): Promise<any> => {
  const response = await instance.post('/link', idea)
  return response.data
}

export const modifyLink = async (idea: Link): Promise<any> => {
  const response = await instance.put('/link', idea)
  return response.data
}

export const removeLink = async (id: string): Promise<any> => {
  const response = await instance.delete(`/link/${id}`)
  return response.data
}
