import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'

import { RootState } from '../../../redux/store'
import ToDoPage from '../ToDoes/pages/ToDoPage'
import MemoriesPage from '../Memories/pages/MemoriesPage'
import IdeasPage from '../Ideas/pages/IdeasPage'
import LinksPage from '../Links/pages/LinksPage'
import LocationsPage from '../Locations/pages/LocationsPage'
import RecipesPage from '../Recipe/pages/RecipesPage'

// import styles from './NotesPage.module.scss'

interface NotesPageProps {}

const NotesPage = (props: NotesPageProps) => {
  return (
    <Routes>
      <Route path="/to-dos" element={<ToDoPage />} />
      <Route path="/memories" element={<MemoriesPage />} />
      <Route path="/ideas" element={<IdeasPage />} />
      <Route path="/links" element={<LinksPage />} />
      <Route path="/recipes" element={<RecipesPage />} />
      <Route path="/locations" element={<LocationsPage />} />
    </Routes>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {})(NotesPage)
