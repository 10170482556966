import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { CreateIdea, Idea, IdeaFilters } from '../types/idea'
import { loadIdeas, modifyIdea, removeIdea, saveIdea } from '../api/idea'

// get ideas
export const getIdeas = createAsyncThunk(
  'idea/getIdeas',
  async (_, thunkApi) => {
    try {
      return await loadIdeas()
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// create idea
export const addIdea = createAsyncThunk(
  'idea/addIdea',
  async (idea: CreateIdea, thunkApi) => {
    try {
      return await saveIdea(idea)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// update idea
export const updateIdea = createAsyncThunk(
  'idea/updateIdea',
  async (idea: Idea, thunkApi) => {
    try {
      return await modifyIdea(idea)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// delete idea
export const deleteIdea = createAsyncThunk(
  'idea/deleteIdea',
  async (id: number, thunkApi) => {
    try {
      return await removeIdea(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface IdeaSliceState {
  allIdeas: Idea[]
  ideas: Idea[]
  ideasLoading: boolean
  ideasError?: SerializedError
  filters: IdeaFilters
}
const initialState: IdeaSliceState = {
  allIdeas: [],
  ideas: [],
  ideasLoading: false,
  ideasError: undefined,
  filters: {
    name: '',
    tag: '',
  },
}

const ideaSlice = createSlice({
  name: 'ideas',
  initialState,
  reducers: {
    setIdeaFilters: (
      state: IdeaSliceState,
      action: PayloadAction<IdeaFilters>,
    ) => {
      state.filters = action.payload
    },

    filterIdeas: (state: IdeaSliceState) => {
      state.ideas = state.allIdeas.filter(
        (idea) =>
          (state.filters.name
            ? idea.name
                .toLocaleLowerCase()
                .includes(state.filters.name.toLocaleLowerCase())
            : idea) &&
          (state.filters.tag
            ? idea.tags.some((tag) =>
                tag
                  .toLowerCase()
                  .includes(state.filters.tag.toLocaleLowerCase()),
              )
            : idea),
      )
    },
  },
  extraReducers: {
    // get ideas
    [getIdeas.fulfilled.type]: (state, action: PayloadAction<Idea[]>) => {
      state.allIdeas = action.payload
      state.ideas = action.payload
      state.ideasLoading = false
      state.ideasError = undefined
    },

    [getIdeas.pending.type]: (state) => {
      state.ideasLoading = true
      state.ideasError = undefined
    },

    [getIdeas.rejected.type]: (state, action) => {
      state.ideasLoading = false
      state.ideasError = action.payload
    },

    // add idea
    [addIdea.fulfilled.type]: (state, action: PayloadAction<Idea>) => {
      state.ideas.unshift(action.payload)
      state.ideasLoading = false
      state.ideasError = undefined
    },

    [addIdea.pending.type]: (state) => {
      state.ideasLoading = true
      state.ideasError = undefined
    },

    [addIdea.rejected.type]: (state, action) => {
      state.ideasLoading = false
      state.ideasError = action.payload
    },

    // update idea
    [updateIdea.fulfilled.type]: (state, action: PayloadAction<Idea>) => {
      state.ideas = state.ideas.map((idea) =>
        idea.id === action.payload.id ? action.payload : idea,
      )
      state.ideasLoading = false
      state.ideasError = undefined
    },

    [updateIdea.pending.type]: (state) => {
      state.ideasLoading = true
      state.ideasError = undefined
    },

    [updateIdea.rejected.type]: (state, action) => {
      state.ideasLoading = false
      state.ideasError = action.payload
    },

    // delete idea
    [deleteIdea.fulfilled.type]: (state, action: PayloadAction<number>) => {
      state.ideas = state.ideas.filter((idea) => idea.id !== action.payload)
      state.ideasLoading = false
      state.ideasError = undefined
    },

    [deleteIdea.pending.type]: (state) => {
      state.ideasLoading = true
      state.ideasError = undefined
    },

    [deleteIdea.rejected.type]: (state, action) => {
      state.ideasLoading = false
      state.ideasError = action.payload
    },
  },
})

export const { setIdeaFilters, filterIdeas } = ideaSlice.actions
export default ideaSlice.reducer
