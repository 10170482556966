import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MainSliceState {
  screenHeight: number
  screenWidth: number
}

const initialState: MainSliceState = {
  screenHeight: 0,
  screenWidth: 0,
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setScreenHeight: (state: MainSliceState, action: PayloadAction<number>) => {
      state.screenHeight = action.payload
    },

    setScreenWidth: (state: MainSliceState, action: PayloadAction<number>) => {
      state.screenWidth = action.payload
    },
  },
  extraReducers: {},
})

export const { setScreenHeight, setScreenWidth } = mainSlice.actions
export default mainSlice.reducer
