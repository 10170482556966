import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import { CreateWhiteboard, Whiteboard } from '../types/whiteboard'
import {
  loadWhiteboard,
  loadWhiteboards,
  modifyWhiteboard,
  removeWhiteboard,
  saveWhiteboard,
} from '../api/whiteboard'

// get whiteboards
export const getAllWhiteboards = createAsyncThunk(
  'whiteboard/getWhiteboards',
  async (_, thunkApi) => {
    try {
      return await loadWhiteboards()
    } catch (e) {
      return thunkApi.rejectWithValue('getWhiteboards Error')
    }
  },
)
// get whiteboard
export const getWhiteboard = createAsyncThunk(
  'whiteboard/getWhiteboard',
  async (whiteboardId: string, thunkApi) => {
    try {
      return await loadWhiteboard(whiteboardId)
    } catch (e) {
      return thunkApi.rejectWithValue('getWhiteboards Error')
    }
  },
)

// create whiteboard
export const addWhiteboard = createAsyncThunk(
  'whiteboard/addWhiteboard',
  async (whiteboard: CreateWhiteboard, thunkApi) => {
    try {
      return await saveWhiteboard(whiteboard)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// update whiteboard
export const updateWhiteboard = createAsyncThunk(
  'whiteboard/updateWhiteboard',
  async (whiteboard: Whiteboard, thunkApi) => {
    try {
      return await modifyWhiteboard(whiteboard)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// // delete whiteboard
export const deleteWhiteboard = createAsyncThunk(
  'whiteboard/deleteWhiteboard',
  async (id: string, thunkApi) => {
    try {
      return await removeWhiteboard(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface WhiteboardSliceState {
  whiteboards: Whiteboard[]
  whiteboard?: Whiteboard
  loading: boolean
  error?: SerializedError
  // filters: WhiteboardFilters
}
const initialState: WhiteboardSliceState = {
  whiteboards: [],
  whiteboard: undefined,
  loading: false,
  error: undefined,
  // filters: {
  //   name: '',
  //   tag: '',
  // },
}

const whiteboardSlice = createSlice({
  name: 'whiteboards',
  initialState,
  reducers: {
    setWhiteboard: (
      state: WhiteboardSliceState,
      action: PayloadAction<Whiteboard | undefined>,
    ) => {
      state.whiteboard = action.payload
    },
    // setWhiteboardFilters: (
    //   state: WhiteboardSliceState,
    //   action: PayloadAction<WhiteboardFilters>,
    // ) => {
    //   state.filters = action.payload
    // },
    // filterWhiteboards: (state: WhiteboardSliceState) => {
    //   state.whiteboards = state.allWhiteboards.filter(
    //     (whiteboard) =>
    //       (state.filters.name
    //         ? whiteboard.name
    //             .toLocaleLowerCase()
    //             .includes(state.filters.name.toLocaleLowerCase())
    //         : whiteboard) &&
    //       (state.filters.tag
    //         ? whiteboard.tags.some((tag) =>
    //             tag
    //               .toLowerCase()
    //               .includes(state.filters.tag.toLocaleLowerCase()),
    //           )
    //         : whiteboard),
    //   )
    // },
  },
  extraReducers: {
    // get whiteboards
    [getAllWhiteboards.fulfilled.type]: (
      state,
      action: PayloadAction<Whiteboard[]>,
    ) => {
      // @ts-ignore
      state.whiteboards = action.payload?.sort((a, b) =>
        b.createdAt.localeCompare(a.createdAt),
      )
      state.loading = false
      state.error = undefined
    },

    [getAllWhiteboards.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [getAllWhiteboards.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // get whiteboard
    [getWhiteboard.fulfilled.type]: (
      state,
      action: PayloadAction<Whiteboard>,
    ) => {
      // @ts-ignore
      state.whiteboard = action.payload
      state.loading = false
      state.error = undefined
    },

    [getWhiteboard.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [getWhiteboard.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // add whiteboard
    [addWhiteboard.fulfilled.type]: (
      state,
      action: PayloadAction<Whiteboard>,
    ) => {
      // @ts-ignore
      state.whiteboards.unshift(action.payload)
      state.loading = false
      state.error = undefined
    },

    [addWhiteboard.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [addWhiteboard.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // update whiteboard
    [updateWhiteboard.fulfilled.type]: (
      state,
      action: PayloadAction<Whiteboard>,
    ) => {
      // @ts-ignoreç
      state.whiteboards = state.whiteboards.map((whiteboard) =>
        whiteboard.id === action.payload.id ? action.payload : whiteboard,
      )
      state.loading = false
      state.error = undefined
    },

    [updateWhiteboard.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [updateWhiteboard.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    // delete whiteboard
    [deleteWhiteboard.fulfilled.type]: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.whiteboards = state.whiteboards.filter(
        (whiteboard) => whiteboard.id !== action.payload,
      )
      state.loading = false
      state.error = undefined
    },

    [deleteWhiteboard.pending.type]: (state) => {
      state.loading = true
      state.error = undefined
    },

    [deleteWhiteboard.rejected.type]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { setWhiteboard } = whiteboardSlice.actions
export default whiteboardSlice.reducer
