import React, { ReactNode, useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import MenuDrawer from '../../components/MenuDrawer/MenuDrawer'
import Nav from '../../components/Nav/Nav'
import styles from './MainLayout.module.scss'
import { useLocation } from 'react-router-dom'

interface MainLayoutProps {
  children: ReactNode
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const [isWidthScreen] = useState<boolean>(
    window.innerWidth > 1024 ? true : false,
  )
  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false)

  const getTitle = (path: string) => {
    const title = path.replaceAll('/', '') || 'Pyunik'
    document.title = title
  }

  const location = useLocation()

  useEffect(() => {
    if (location) {
      getTitle(location.pathname)
    }
  }, [location])

  const onOpenCloseMenu = (value: boolean) => {
    setIsActiveMenu(value)
  }

  return (
    <div className={styles.root}>
      {isWidthScreen ? (
        <>
          <header>
            <Header />
          </header>
          <div className={styles.bottom}>
            <nav>
              <Nav />
            </nav>
            <main className={styles.main}>{children}</main>
          </div>
        </>
      ) : (
        <>
          <header>
            <Header
              isActiveMenu={isActiveMenu}
              onClickMenuIcon={onOpenCloseMenu}
            />
          </header>
          <main className={styles.main}>{children}</main>
          <MenuDrawer
            isActiveMenu={isActiveMenu}
            onClose={() => onOpenCloseMenu(false)}
          />
        </>
      )}
    </div>
  )
}

export default MainLayout
