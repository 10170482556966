import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  loadVocabularies,
  modifyVocabulary,
  removeVocabulary,
  saveVocabulary,
} from '../api/vocabulary'
import { modifyWord, removeWord, saveWord } from '../api/word'
import {
  CreateVocabulary,
  CreateWord,
  Vocabulary,
  Word,
} from '../types/vocabulary'

//get vocabularies
export const getVocabularies = createAsyncThunk(
  'vocabulary/getVocabularies',
  async (_, thunkApi) => {
    try {
      return await loadVocabularies()
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//create vocabulary
export const addVocabulary = createAsyncThunk(
  'vocabulary/addVocabulary',
  async (vocabulary: CreateVocabulary, thunkApi) => {
    try {
      return await saveVocabulary(vocabulary)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//update vocabulary
export const updateVocabulary = createAsyncThunk(
  'vocabulary/updateVocabulary',
  async (vocabulary: Vocabulary, thunkApi) => {
    try {
      return await modifyVocabulary(vocabulary)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//delete vocabulary
export const deleteVocabulary = createAsyncThunk(
  'vocabulary/deleteVocabulary',
  async (id: number, thunkApi) => {
    try {
      return await removeVocabulary(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//add word to vocabulary
export const addWord = createAsyncThunk(
  'vocabulary/addWord',
  async (word: CreateWord, thunkApi) => {
    try {
      return await saveWord(word)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//update word
export const updateWord = createAsyncThunk(
  'vocabulary/updateWord',
  async (word: Word, thunkApi) => {
    try {
      return await modifyWord(word)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//move word
export const moveWord = createAsyncThunk(
  'vocabulary/moveWord',
  async (word: Word, thunkApi) => {
    try {
      return await modifyWord(word)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

//delete word
export const deleteWord = createAsyncThunk(
  'vocabulary/deleteWord',
  async (wordId: number, thunkApi) => {
    try {
      return await removeWord(wordId)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface authSliceState {
  vocabularies: Vocabulary[]
  selectedVocabulary?: Vocabulary
  vocabulariesLoading: boolean
  vocabulariesError?: SerializedError
  wordError?: SerializedError
}

const initialState: authSliceState = {
  vocabularies: [],
  selectedVocabulary: undefined,
  vocabulariesLoading: false,
  vocabulariesError: undefined,
  wordError: undefined,
}

const vocabularySlice = createSlice({
  name: 'vocabulary',
  initialState,
  reducers: {
    setSelectedVocabulary: (
      state: authSliceState,
      action: PayloadAction<Vocabulary | undefined>,
    ) => {
      if (action.payload) {
        state.selectedVocabulary = {
          ...action.payload,
          words: [...action.payload.words],
        }
      } else {
        state.selectedVocabulary = action.payload
      }
    },
  },
  extraReducers: {
    // get vocabulary
    [getVocabularies.fulfilled.type]: (
      state,
      action: PayloadAction<Vocabulary[]>,
    ) => {
      state.vocabularies = action.payload
      state.vocabulariesLoading = false
      state.vocabulariesError = undefined
    },

    [getVocabularies.pending.type]: (state) => {
      state.vocabulariesLoading = true
      state.vocabulariesError = undefined
    },

    [getVocabularies.rejected.type]: (state, action) => {
      state.vocabulariesLoading = false
      state.vocabulariesError = action.payload
    },

    // add vocabulary
    [addVocabulary.fulfilled.type]: (
      state,
      action: PayloadAction<Vocabulary>,
    ) => {
      state.vocabularies = [...state.vocabularies, action.payload].sort(
        (a: Vocabulary, b: Vocabulary) => a.name.localeCompare(b.name),
      )
      state.vocabulariesLoading = false
      state.vocabulariesError = undefined
    },

    [addVocabulary.pending.type]: (state) => {
      state.vocabulariesLoading = true
      state.vocabulariesError = undefined
    },

    [addVocabulary.rejected.type]: (state, action) => {
      state.vocabulariesLoading = false
      state.vocabulariesError = action.payload
    },

    // update vocabulary
    [updateVocabulary.fulfilled.type]: (
      state,
      action: PayloadAction<Vocabulary>,
    ) => {
      state.vocabularies = state.vocabularies.map((vocabulary) =>
        vocabulary.id === action.payload.id ? action.payload : vocabulary,
      )
      state.vocabulariesLoading = false
      state.vocabulariesError = undefined
    },

    [updateVocabulary.pending.type]: (state) => {
      state.vocabulariesLoading = true
      state.vocabulariesError = undefined
    },

    [updateVocabulary.rejected.type]: (state, action) => {
      state.vocabulariesLoading = false
      state.vocabulariesError = action.payload
    },

    // delete vocabulary
    [deleteVocabulary.fulfilled.type]: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.vocabularies = state.vocabularies.filter(
        (vocabulary) => vocabulary.id !== action.payload,
      )
      // state.vocabulariesLoading = false
      state.vocabulariesError = undefined
    },

    [deleteVocabulary.pending.type]: (state) => {
      // state.vocabulariesLoading = true
      state.vocabulariesError = undefined
    },

    [deleteVocabulary.rejected.type]: (state, action) => {
      // state.vocabulariesLoading = false
      state.vocabulariesError = action.payload
    },

    //add word to vocabulary
    [addWord.fulfilled.type]: (state, action: PayloadAction<Word>) => {
      if (state.selectedVocabulary) {
        state.selectedVocabulary.words.push(action.payload)
      }
      state.wordError = undefined
    },

    [addWord.pending.type]: (state) => {
      state.wordError = undefined
    },

    [addWord.rejected.type]: (state, action) => {
      state.wordError = action.payload
    },

    //update word to vocabulary
    [updateWord.fulfilled.type]: (state, action: PayloadAction<Word>) => {
      if (state.selectedVocabulary) {
        state.selectedVocabulary.words = state.selectedVocabulary.words.map(
          (word) => (word.id === action.payload.id ? action.payload : word),
        )
      }
      state.wordError = undefined
    },

    [updateWord.pending.type]: (state) => {
      state.wordError = undefined
    },

    [updateWord.rejected.type]: (state, action) => {
      state.wordError = action.payload
    },

    //move word
    [moveWord.fulfilled.type]: (state, action: PayloadAction<Word>) => {
      if (state.selectedVocabulary) {
        state.selectedVocabulary.words = state.selectedVocabulary.words.filter(
          (word) => word.id !== action.payload.id,
        )
        state.vocabularies = state.vocabularies.map((vocabulary: Vocabulary) =>
          action.payload.vocabularyId === vocabulary.id
            ? { ...vocabulary, words: [...vocabulary.words, action.payload] }
            : state.selectedVocabulary?.id === vocabulary.id
            ? {
                ...vocabulary,
                words: vocabulary.words
                  .filter((word) => word.id !== action.payload.id)
                  .sort(
                    (a: Word, b: Word) =>
                      Number(a.createdAt) - Number(b.createdAt),
                  ),
              }
            : vocabulary,
        )
      }
      state.wordError = undefined
    },

    [moveWord.pending.type]: (state) => {
      state.wordError = undefined
    },

    [moveWord.rejected.type]: (state, action) => {
      state.wordError = action.payload
    },

    //delete word
    [deleteWord.fulfilled.type]: (state, action: PayloadAction<number>) => {
      if (state.selectedVocabulary) {
        state.selectedVocabulary.words = state.selectedVocabulary.words.filter(
          (word: Word) => word.id !== action.payload,
        )
      }
      state.wordError = undefined
    },

    [deleteWord.pending.type]: (state) => {
      state.wordError = undefined
    },

    [deleteWord.rejected.type]: (state, action) => {
      state.wordError = action.payload
    },
  },
})

export const { setSelectedVocabulary } = vocabularySlice.actions
export default vocabularySlice.reducer
