import instance from '../../../api/axios'
import { CreateVocabulary, Vocabulary } from '../types/vocabulary'

export const loadVocabularies = async (): Promise<Vocabulary[]> => {
  const response = await instance.get('/vocabularies')
  return response.data.sort((a: Vocabulary, b: Vocabulary) =>
    a.name.localeCompare(b.name),
  )
}

export const loadVocabulary = async (id: number): Promise<Vocabulary> => {
  const response = await instance.get(`/vocabularies/${id}`)
  return response.data
}

export const saveVocabulary = async (
  vocabulary: CreateVocabulary,
): Promise<Vocabulary> => {
  const response = await instance.post('/vocabularies', vocabulary)
  return response.data
}

export const modifyVocabulary = async (
  vocabulary: Vocabulary,
): Promise<Vocabulary> => {
  const response = await instance.put('/vocabularies', vocabulary)
  return response.data
}

export const removeVocabulary = async (id: number): Promise<number> => {
  const response = await instance.delete(`/vocabularies/${id}`)
  return response.data
}
