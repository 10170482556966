import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import styles from './DatePikerPopUp.module.scss'

interface DatePikerPopUpProps {
  initialDate?: Date
  onUpdate: (date: Date | undefined) => void,
  onClose: () => void,
}

const DatePikerPopUp = (props: DatePikerPopUpProps) => {
  const {
    initialDate,
    onUpdate,
    onClose,
  } = props
  
 const [date, setDate] = useState<Date | undefined>(initialDate)
  const validateDate = (d:Date) => {
    d > new Date() ? setDate(d) : setDate(undefined)
  }

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <span className={styles.title}>Choose new date</span>
        <DatePicker
            onChange={validateDate}
            value={date}
            className={styles.picker}
        />
        <div className={styles.buttonContainer}>
          <Button onClick={onClose} className={styles.cancelButton} variant="light">Cancel</Button>
          <Button onClick={() => onUpdate(date)} variant="success">Update</Button>
        </div>
      </div>
    </div>
  )
}

export default DatePikerPopUp