import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Nav.module.scss'
import { GoGear } from 'react-icons/go'
import { BiTask } from 'react-icons/bi'
import { RiFolderInfoLine } from 'react-icons/ri'
import { IoCalendarOutline } from 'react-icons/io5'
import { MdGTranslate } from 'react-icons/md'
import { CgNotes } from 'react-icons/cg'
import { LuBookMinus, LuClipboardEdit, LuWallet } from 'react-icons/lu'
import SubNavMenu from '../SubNavMenu/SubNavMenu'
import notesMenu from '../../config/notesMenu'
import { RootState } from '../../redux/store'
import { connect } from 'react-redux'
import { AuthUser } from '../../domains/Login/types/users'

interface NavProps {
  user?: AuthUser
}

const Nav = (props: NavProps) => {
  const { user } = props

  return (
    <div className={styles.root}>
      {user?.role === 'admin' && (
        <div>
          <SubNavMenu menuItems={notesMenu}>
            <NavLink
              title={'Notes'}
              className={(naveData) =>
                naveData.isActive ? styles.activeNavLink : styles.navLink
              }
              to={'/notes'}
            >
              <CgNotes size={22} color={'white'} />
            </NavLink>
          </SubNavMenu>
        </div>
      )}
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Objectives'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/objectives'}
          >
            <BiTask size={22} color={'white'} />
          </NavLink>
        </div>
      )}
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Events'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/events'}
          >
            <IoCalendarOutline size={21} color={'white'} />
          </NavLink>
        </div>
      )}
      <div>
        <NavLink
          title={'Translation'}
          className={(naveData) =>
            naveData.isActive ? styles.activeNavLink : styles.navLink
          }
          to={'/translation'}
        >
          <MdGTranslate size={20} color={'white'} />
        </NavLink>
      </div>
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Whiteboard'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/whiteboards'}
          >
            <LuClipboardEdit size={22} color={'white'} />
          </NavLink>
        </div>
      )}
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Books'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/books'}
          >
            <LuBookMinus size={22} color={'white'} />
          </NavLink>
        </div>
      )}

      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Purchases'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/purchases'}
          >
            <LuWallet size={23} color={'white'} />
          </NavLink>
        </div>
      )}
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Information'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/information'}
          >
            <RiFolderInfoLine size={22} color={'white'} />
          </NavLink>
        </div>
      )}
      {user?.role === 'admin' && (
        <div>
          <NavLink
            title={'Settings'}
            className={(naveData) =>
              naveData.isActive ? styles.activeNavLink : styles.navLink
            }
            to={'/settings'}
          >
            <GoGear size={22} color={'white'} />
          </NavLink>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps, {})(Nav)
