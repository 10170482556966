import instance from '../../../../api/axios'
import { CreateRecipe, Recipe } from '../types/recipe'

export const loadRecipes = async (): Promise<Recipe[]> => {
  const response = await instance.get('/recipes')
  return response.data
}

export const saveRecipe = async (recipe: CreateRecipe): Promise<Recipe> => {
  const response = await instance.post('/recipes', recipe)
  return response.data
}

export const modifyRecipe = async (recipe: Recipe): Promise<Recipe> => {
  const response = await instance.put('/recipes', recipe)
  return response.data
}

export const removeRecipe = async (id: string): Promise<string> => {
  const response = await instance.delete(`/recipes/${id}`)
  return response.data
}
