import React, { ReactNode } from 'react'
import { Button } from 'react-bootstrap'
import styles from './BottomLineLayout.module.scss'

interface BottomLineLayoutProps {
  children: ReactNode | ReactNode[]
  leftButtonName?: string
  leftButtonVariant?: string
  rightFirstButtonName?: string
  rightFirstButtonVariant?: string
  rightSecondButtonName?: string
  rightSecondButtonVariant?: string
  onClickLeftButton?: () => void
  onClickRightFirsButton?: () => void
  onClickRightSecondButton?: () => void
}

const BottomLineLayout = ({
  children,
  leftButtonName = 'Cancel',
  leftButtonVariant = 'light',
  rightFirstButtonName = 'Delete',
  rightFirstButtonVariant = 'danger',
  rightSecondButtonName = 'Save',
  rightSecondButtonVariant = 'success',
  onClickLeftButton,
  onClickRightFirsButton,
  onClickRightSecondButton,
}: BottomLineLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.children}>{children}</div>
      <div className={styles.footer}>
        {onClickLeftButton && (
          <Button
            className={styles.button}
            onClick={onClickLeftButton}
            variant={leftButtonVariant}
          >
            {leftButtonName}
          </Button>
        )}
        <div className={styles.right}>
          {onClickRightFirsButton && (
            <Button
              className={styles.button}
              onClick={onClickRightFirsButton}
              variant={rightFirstButtonVariant}
            >
              {rightFirstButtonName}
            </Button>
          )}
          {onClickRightSecondButton && (
            <Button
              className={styles.button}
              onClick={onClickRightSecondButton}
              variant={rightSecondButtonVariant}
            >
              {rightSecondButtonName}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BottomLineLayout
