import React, { useEffect, useState } from 'react'
import styles from './DateTag.module.scss'
import Tag from '../Tag/Tag'
import { TagColors } from '../../types'
import { LuCalendarCheck2, LuCalendarX2 } from 'react-icons/lu'
import { format } from 'date-fns'

interface DateTagProps {
  date?: string
  dateType: 'deadline' | 'endAt' | 'startedAt'
}

const DateTag = (props: DateTagProps) => {
  const { date, dateType } = props
  const [color, setColor] = useState<TagColors>('Grey')

  useEffect(() => {
    if (dateType && date) {
      if (new Date() > new Date(date)) {
        setColor('Red')
      } else {
        setColor('Green')
      }
    }
  }, [dateType])

  return (
    <Tag
      name={date ? format(new Date(date), 'dd MMMM yyyy') : '--'}
      color={color}
    >
      {dateType === 'deadline' || dateType === 'endAt' ? (
        <LuCalendarX2 />
      ) : (
        dateType === 'startedAt' && <LuCalendarCheck2 />
      )}
    </Tag>
  )
}

export default DateTag
