import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import { FaHome } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
// @ts-ignore
import Burger from 'react-css-burger'

interface HeaderProps{
  isActiveMenu?: boolean,
  onClickMenuIcon?: (value: boolean) => void,
}

const Header = (props: HeaderProps) => {
  const location  = useLocation()
  const [title, setTitle] = useState<string>('')
  const [isWidthScreen] = useState<boolean>(window.innerWidth > 1024 ? true : false)
  const {
    isActiveMenu,
    onClickMenuIcon,
  } = props

  useEffect(() => {
    const path = location.pathname.replaceAll('%20', ' ').split("/").slice(1).filter(el => el !== location.key)
    path[0] === '' ? setTitle('Home') : setTitle(path[0])
  }, [location.pathname])
  
  return (
    <div className={styles.root}>
      {isWidthScreen ? 
        <NavLink to={'/'} className={styles.IconContainer}>
          <FaHome color="white" size={25} />
        </NavLink>
        :
        <div 
          className={styles.menuIconContainer}
        > 
          <div className={styles.burger}>
            <Burger
              onClick={() => onClickMenuIcon && onClickMenuIcon(!isActiveMenu)}
              active={isActiveMenu}
              burger="spin"
              scale={0.6}
              color="#000000"
            />
          </div>
        </div>
       }
      
      <div className={styles.header}>
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  )
}

export default Header
