import { Option } from '../../../../types'

const other: Option = {
  label: 'Other',
  value: 'Other',
}

let locationTypeOptions: Option[] = [
  {
    label: 'Home-Address',
    value: 'Home-Address',
  },
  {
    label: 'Work-Address',
    value: 'Work-Address',
  },
  {
    label: 'Hotel',
    value: 'Hotel',
  },
  {
    label: 'Restaurant',
    value: 'Restaurant',
  },
  {
    label: 'Pizzeria',
    value: 'Pizzeria',
  },
  {
    label: 'Fast-Food',
    value: 'Fast-Food',
  },
  {
    label: 'Bar',
    value: 'Bar',
  },
  {
    label: 'Pub',
    value: 'Pub',
  },
  {
    label: 'Cafe',
    value: 'Cafe',
  },
  {
    label: 'Opera',
    value: 'Opera',
  },
  {
    label: 'Theater',
    value: 'Theater',
  },
  {
    label: 'Cinema',
    value: 'Cinema',
  },
  {
    label: 'Club',
    value: 'Club',
  },
  {
    label: 'Disco',
    value: 'Disco',
  },
  {
    label: 'Tourist-Attraction',
    value: 'Tourist-Attraction',
  },
  {
    label: 'Beach',
    value: 'Beach',
  },
].sort((a, b) => a.label.localeCompare(b.label))

locationTypeOptions = [...locationTypeOptions, other]

export default locationTypeOptions
