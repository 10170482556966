import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { CreateLink, Link } from '../../types/link'
import styles from './FormAddLink.module.scss'
import { addLink, updateLink } from '../../store/link.slice'

interface FormAddLinkProps {
  type: string
  link?: Link
  linksParentsIds: string[]
  onClickCancel: () => void
  addLink?: (link: CreateLink) => void
  updateLink?: (idea: Link) => void
}

const FormAddLink = (props: FormAddLinkProps) => {
  const { link, type, linksParentsIds, onClickCancel, addLink, updateLink } =
    props

  const [name, setName] = useState<string>(link ? link.name : '')
  const [url, setUrl] = useState<string>(link && link.url ? link.url : '')

  const onAddLink = () => {
    if ((type === 'folder' && name) || (type === 'link' && name && url)) {
      const link: CreateLink = {
        name,
        url: url || undefined,
        type,
        parentId:
          linksParentsIds.length > 1
            ? linksParentsIds[linksParentsIds.length - 1]
            : undefined,
      }
      addLink!(link)
      onClickCancel()
    }
  }

  const onUpdateLink = () => {
    if ((type === 'folder' && name) || (type === 'link' && name && url)) {
      const linkToUpdate: Link = {
        name,
        url: url || undefined,
        type,
        parentId:
          linksParentsIds.length > 1
            ? linksParentsIds[linksParentsIds.length - 1]
            : undefined,
        id: link!.id,
        createdAt: link!.createdAt,
      }

      updateLink!(linkToUpdate)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder={`${type} name...`}
          className={styles.textInput}
        />
      </Form.Group>
      {type === 'link' && (
        <div className={styles.line}>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label className={styles.label}>URL*</Form.Label>
            <Form.Control
              value={url}
              onChange={(e: any) => setUrl(e.target.value)}
              placeholder={`${type} url...`}
              className={styles.textInput}
            />
          </Form.Group>
        </div>
      )}

      <div className={styles.btnContainer}>
        <div className={styles.btnSeparator}></div>
        {link ? (
          <Button
            onClick={onUpdateLink}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddLink}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  linksParentsIds: state.link.linksParentsIds,
})

export default connect(mapStateToProps, {
  addLink,
  updateLink,
})(FormAddLink)
