import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'
import {
  loadMemories,
  modifyMemory,
  removeMemory,
  saveMemory,
} from '../api/memory'
import { CreateMemory, Memory, MemoryFilters } from '../types/memory'

// get memories
export const getMemories = createAsyncThunk(
  'memory/getMemory',
  async (_, thunkApi) => {
    try {
      return await loadMemories()
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// create memory
export const addMemory = createAsyncThunk(
  'memory/addMemory',
  async (memory: CreateMemory, thunkApi) => {
    try {
      return await saveMemory(memory)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// update memory
export const updateMemory = createAsyncThunk(
  'memory/updateMemory',
  async (memory: Memory, thunkApi) => {
    try {
      return await modifyMemory(memory)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

// delete memory
export const deleteMemory = createAsyncThunk(
  'memory/deleteMemory',
  async (id: number, thunkApi) => {
    try {
      return await removeMemory(id)
    } catch (e) {
      return thunkApi.rejectWithValue('step Error')
    }
  },
)

interface MemorySliceState {
  allMemories: Memory[]
  memories: Memory[]
  memoriesTags: string[]
  memoriesLoading: boolean
  memoriesError?: SerializedError
  filters: MemoryFilters
}

const initialState: MemorySliceState = {
  allMemories: [],
  memories: [],
  memoriesTags: [],
  memoriesLoading: false,
  memoriesError: undefined,
  filters: {
    name: '',
    tag: '',
  },
}

const memorySlice = createSlice({
  name: 'memories',
  initialState,
  reducers: {
    setMemoryFilters: (
      state: MemorySliceState,
      action: PayloadAction<MemoryFilters>,
    ) => {
      state.filters = action.payload
    },

    filterMemories: (state: MemorySliceState) => {
      state.memories = state.allMemories.filter(
        (memory) =>
          (state.filters.name
            ? memory.name
                .toLocaleLowerCase()
                .includes(state.filters.name.toLocaleLowerCase())
            : memory) &&
          (state.filters.tag
            ? memory.tags.some((tag) =>
                tag
                  .toLowerCase()
                  .includes(state.filters.tag.toLocaleLowerCase()),
              )
            : memory),
      )
    },
  },
  extraReducers: {
    // get memories
    [getMemories.fulfilled.type]: (state, action: PayloadAction<Memory[]>) => {
      state.allMemories = action.payload
      state.memories = action.payload
      let allTags: string[] = []
      action.payload.forEach((memory: Memory) => {
        memory.tags.forEach((tag) => {
          if (!allTags.includes(tag.toLowerCase())) {
            allTags.push(tag)
          }
        })
      })
      state.memoriesTags = allTags
      state.memoriesLoading = false
      state.memoriesError = undefined
    },

    [getMemories.pending.type]: (state) => {
      state.memoriesLoading = true
      state.memoriesError = undefined
    },

    [getMemories.rejected.type]: (state, action) => {
      state.memoriesLoading = false
      state.memoriesError = action.payload
    },

    // add memory
    [addMemory.fulfilled.type]: (state, action: PayloadAction<Memory>) => {
      state.memories.unshift(action.payload)
      state.memoriesLoading = false
      state.memoriesError = undefined
    },

    [addMemory.pending.type]: (state) => {
      state.memoriesLoading = true
      state.memoriesError = undefined
    },

    [addMemory.rejected.type]: (state, action) => {
      state.memoriesLoading = false
      state.memoriesError = action.payload
    },

    // update memory
    [updateMemory.fulfilled.type]: (state, action: PayloadAction<Memory>) => {
      state.memories = state.memories.map((memory) =>
        memory.id === action.payload.id ? action.payload : memory,
      )
      state.memoriesLoading = false
      state.memoriesError = undefined
    },

    [updateMemory.pending.type]: (state) => {
      state.memoriesLoading = true
      state.memoriesError = undefined
    },

    [updateMemory.rejected.type]: (state, action) => {
      state.memoriesLoading = false
      state.memoriesError = action.payload
    },

    // delete memory
    [deleteMemory.fulfilled.type]: (state, action: PayloadAction<number>) => {
      state.memories = state.memories.filter(
        (note) => note.id !== action.payload,
      )
      state.memoriesLoading = false
      state.memoriesError = undefined
    },

    [deleteMemory.pending.type]: (state) => {
      state.memoriesLoading = true
      state.memoriesError = undefined
    },

    [deleteMemory.rejected.type]: (state, action) => {
      state.memoriesLoading = false
      state.memoriesError = action.payload
    },
  },
})

export const { setMemoryFilters, filterMemories } = memorySlice.actions
export default memorySlice.reducer
