import instance from '../../../api/axios'
import { CreateWord, Word } from '../types/vocabulary'

export const saveWord = async (word: CreateWord): Promise<Word> => {
  const response = await instance.post('/words', word)
  return response.data
}

export const modifyWord = async (word: Word): Promise<Word> => {
  const response = await instance.put('/words', word)
  return response.data
}

export const removeWord = async (id: number): Promise<number> => {
  const response = await instance.delete(`/words/${id}`)
  return response.data
}
