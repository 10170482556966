import React from 'react'
import styles from './LocationDetails.module.scss'
import { Location } from '../../types/location'
import Tag from '../../../../../components/Tag/Tag'
import { FaCity } from 'react-icons/fa'
import {
  MdOutlineHotel,
  MdOutlinePlace,
  MdOutlineTheaterComedy,
  MdTheaters,
  MdWorkOutline,
} from 'react-icons/md'
import {
  IoCafeOutline,
  IoFastFoodOutline,
  IoRestaurantOutline,
} from 'react-icons/io5'
import { TbPizza } from 'react-icons/tb'
import { BiDrink } from 'react-icons/bi'
import { BsMusicPlayer } from 'react-icons/bs'
import { HiOutlineBuildingLibrary, HiOutlineHome } from 'react-icons/hi2'

interface LocationDetailsProps {
  location: Location
}

const LocationDetails = (props: LocationDetailsProps) => {
  const { location } = props

  return (
    <div className={styles.root}>
      <div className={styles.inlineContainer}>
        <span>Location: </span>
        {location.map ? (
          <a href={location?.map}>{location.address}</a>
        ) : (
          <span>{location.address}</span>
        )}
      </div>
      <div className={styles.inlineContainer}>
        <span>City: </span>
        <Tag name={location.city} color="Blue">
          <FaCity />
        </Tag>
      </div>
      <div className={styles.inlineContainer}>
        <span>Type: </span>
        <Tag name={location.type} color="Sienna">
          {location.type === 'Home-Address' ? (
            <HiOutlineHome />
          ) : location.type === 'Work-Address' ? (
            <MdWorkOutline />
          ) : location.type === 'Hotel' ? (
            <MdOutlineHotel />
          ) : location.type === 'Restaurant' ? (
            <IoRestaurantOutline />
          ) : location.type === 'Pizzeria' ? (
            <TbPizza />
          ) : location.type === 'Fast-Food' ? (
            <IoFastFoodOutline />
          ) : location.type === 'Bar' || location.type === 'Pub' ? (
            <BiDrink />
          ) : location.type === 'Cafe' ? (
            <IoCafeOutline />
          ) : location.type === 'Opera' || location.type === 'Theater' ? (
            <MdOutlineTheaterComedy />
          ) : location.type === 'Cinema' ? (
            <MdTheaters />
          ) : location.type === 'Club' || location.type === 'Disco' ? (
            <BsMusicPlayer />
          ) : location.type === 'Tourist-Attraction' ? (
            <HiOutlineBuildingLibrary />
          ) : (
            <MdOutlinePlace />
          )}
        </Tag>
      </div>
      <div className={styles.desc}>
        <div>Description: </div>
        <div className={styles.content}>
          <div
            dangerouslySetInnerHTML={{
              //@ts-ignore
              __html: location.note,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default LocationDetails
