import { Option } from '../../../../types'

export const toDoStatusOptions: Option[] = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Paused',
    value: 'Paused',
  },
  {
    label: 'Finished',
    value: 'Finished',
  },
]

export const toDoPriorityOptions: Option[] = [
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Low',
    value: 'Low',
  },
]
