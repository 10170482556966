import { Objective } from '../types/objective'

// sort by date
export const sortObjectivesByDate = (
  objectives: Objective[],
  type: string,
): Objective[] => {
  const newObjectives = [...objectives]
  return type === 'New'
    ? newObjectives.sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1
        }
        return 0
      })
    : newObjectives.sort((a, b) => {
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return -1
        }
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return 1
        }
        return 0
      })
}

//sort by priority
export const sortObjectivesByPriority = (
  objectives: Objective[],
  priority: string,
): Objective[] => {
  const HighPriorityObjectives = objectives.filter(
    (objective: Objective) => objective.priority === 'High',
  )
  const MediumPriorityObjectives = objectives.filter(
    (objective: Objective) => objective.priority === 'Medium',
  )
  const LowPriorityObjectives = objectives.filter(
    (objective: Objective) => objective.priority === 'Low',
  )
  const sortedObjectives: Objective[] = []

  if (priority === 'High') {
    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }

    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }
  }

  if (priority === 'Medium') {
    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }

    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }
  }

  if (priority === 'Low') {
    if (LowPriorityObjectives) {
      sortedObjectives.push(...LowPriorityObjectives)
    }

    if (HighPriorityObjectives) {
      sortedObjectives.push(...HighPriorityObjectives)
    }

    if (MediumPriorityObjectives) {
      sortedObjectives.push(...MediumPriorityObjectives)
    }
  }
  return sortedObjectives
}

// sort by default
export const sortObjectivesByDefault = (
  objectives: Objective[],
): Objective[] => {
  const activeObjectives = objectives.filter(
    (objective: Objective) => objective.status === 'Active',
  )
  const passiveObjectives = objectives.filter(
    (objective: Objective) =>
      objective.status === 'Passive' || objective.status === 'Paused',
  )
  const finishedObjectives = objectives.filter(
    (objective: Objective) => objective.status === 'Finished',
  )
  const sortedObjectives: Objective[] = []

  if (activeObjectives) {
    const sortedByDate = sortObjectivesByDate(activeObjectives, 'new')
    const sortedByPriority = sortObjectivesByPriority(sortedByDate, 'High')
    sortedObjectives.push(...sortedByPriority)
  }

  if (passiveObjectives) {
    const sortedByDate = sortObjectivesByDate(passiveObjectives, 'new')
    const sortedByPriority = sortObjectivesByPriority(sortedByDate, 'High')
    sortedObjectives.push(...sortedByPriority)
  }

  if (finishedObjectives) {
    const sortedByDate = sortObjectivesByDate(finishedObjectives, 'new')
    const sortedByPriority = sortObjectivesByPriority(sortedByDate, 'High')
    sortedObjectives.push(...sortedByPriority)
  }

  return sortedObjectives
}

// sort by status
export const sortObjectivesByStatus = (
  objectives: Objective[],
  status: string,
): Objective[] => {
  const activeObjectives = objectives.filter(
    (objective: Objective) => objective.status === 'Active',
  )
  const passiveObjectives = objectives.filter(
    (objective: Objective) =>
      objective.status === 'Passive' || objective.status === 'Paused',
  )
  const finishedObjectives = objectives.filter(
    (objective: Objective) => objective.status === 'Finished',
  )
  const sortedObjectives: Objective[] = []

  if (status === 'Active') {
    if (activeObjectives) {
      sortedObjectives.push(...activeObjectives)
    }

    if (passiveObjectives) {
      sortedObjectives.push(...passiveObjectives)
    }

    if (finishedObjectives) {
      sortedObjectives.push(...finishedObjectives)
    }
  }

  if (status === 'Passive' || status === 'Paused') {
    if (passiveObjectives) {
      sortedObjectives.push(...passiveObjectives)
    }

    if (finishedObjectives) {
      sortedObjectives.push(...finishedObjectives)
    }

    if (activeObjectives) {
      sortedObjectives.push(...activeObjectives)
    }
  }

  if (status === 'Finished') {
    if (finishedObjectives) {
      sortedObjectives.push(...finishedObjectives)
    }

    if (activeObjectives) {
      sortedObjectives.push(...activeObjectives)
    }

    if (passiveObjectives) {
      sortedObjectives.push(...passiveObjectives)
    }
  }

  return sortedObjectives
}

// sort by name
export const sortObjectivesByName = (
  objectives: Objective[],
  type: string,
): Objective[] => {
  const newObjectives = [...objectives]
  return type === 'A-Z'
    ? newObjectives.sort((a, b) => {
        if (a.name > b.name) {
          return -1
        }
        if (a.name < b.name) {
          return 1
        }
        return 0
      })
    : newObjectives.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
}
