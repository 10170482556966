import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { getEvents, getUpcomingEvents } from '../../store/event.slice'
import { Event, UpcomingEvents as UpcomingEventsType } from '../../types/event'

interface UpcomingEventsProps {
  events: Event[]
  upcomingEvents: UpcomingEventsType
  getEvents: () => void
  getUpcomingEvents: () => void
}

const UpcomingEvents = (props: UpcomingEventsProps) => {
  const { events, upcomingEvents, getEvents, getUpcomingEvents } = props
  useEffect(() => {
    getEvents()
  }, [])

  useEffect(() => {
    if (events?.length > 0) {
      getUpcomingEvents()
    }
  }, [events])

  // useEffect(() => {
  //   console.log('upcomingEvents', upcomingEvents)
  // }, [upcomingEvents])

  return <div></div>
}

const mapStateToProps = (state: RootState) => ({
  events: state.event.events,
  upcomingEvents: state.event.upcomingEvents,
})

export default connect(mapStateToProps, {
  getEvents,
  getUpcomingEvents,
})(UpcomingEvents)
