import React, { ReactNode, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItem } from '../../types'
import styles from './SubNavMenu.module.scss'

interface SideBarLayoutProps {
  children: ReactNode
  menuItems: MenuItem[]
}

const SubNavMenu = ({ children, menuItems }: SideBarLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.children}>{children}</div>
      <div className={styles.sideBar}>
        {menuItems?.length > 0 &&
          menuItems.map((item) => (
            <NavLink
              key={item.path}
              title={item.name}
              className={(naveData) =>
                naveData.isActive ? styles.activeNavLink : styles.navLink
              }
              to={item.path}
            >
              <div className={styles.icon}>
                <item.icon />
              </div>
              <div className={styles.name}>{item.name}</div>
            </NavLink>
          ))}
      </div>
    </div>
  )
}

export default SubNavMenu
