import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../redux/store'
import styles from './HomePage.module.scss'
import UpcomingEvents from '../../Events/components/UpcomingEvents/UpcomingEvents'

interface HomePageProps {
  // notes: NoteType[],
  // getNotes: () => void,
  // deleteNote: (id: number) => void,
  // sortNotes: (priority: string) => void,
  // filterNotes: (type: string) => void,
}

const HomePage = (props: HomePageProps) => {
  return (
    <div className={styles.root}>
      <UpcomingEvents />
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  // getNotes,
  // deleteNote,
  // sortNotes,
  // filterNotes,
})(HomePage)
