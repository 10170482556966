import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import styles from './CreateNewObjective.module.scss'
import DatePicker from 'react-date-picker'
import { useNavigate } from 'react-router-dom'
import { addObjective } from '../../store/objective.slice'
import { RootState } from '../../../../redux/store'
import BottomLineLayout from '../../../../layouts/BottomLineLayout/BottomLineLayout'
import { CreateObjective } from '../../types/objective'

interface CreateNewObjectiveProps {
  addObjective: (objective: CreateObjective) => void
}

const CreateNewObjective = ({ addObjective }: CreateNewObjectiveProps) => {
  const [error, setError] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [deadline, setDeadline] = useState<Date | undefined>(undefined)
  const [priority, setPriority] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const navigator = useNavigate()

  const onCreateClick = async () => {
    if (!name) {
      return setError('Objective name is required')
    }

    if (
      deadline &&
      new Date(deadline).getFullYear() <= new Date().getFullYear() &&
      new Date(deadline).getMonth() <= new Date().getMonth() &&
      new Date(deadline).getDate() < new Date().getDate()
    ) {
      return setError("Objective deadLine can't be earlier then current day")
    }

    if (!priority) {
      return setError('Priority is required')
    }

    setError('')

    const objective: CreateObjective = {
      name,
      createdAt: new Date().toISOString(),
      deadline: deadline ? deadline.toISOString() : undefined,
      steps: [],
      priority,
      status: status ? status : 'Paused',
      description: description ? description : '',
    }

    await addObjective(objective)

    setName('')
    setDeadline(undefined)
    setPriority('')
    setStatus('')
    setDescription('')
  }

  const validateDeadline = (deadline: Date) => {
    deadline < new Date() ? setDeadline(new Date()) : setDeadline(deadline)
  }

  return (
    <BottomLineLayout
      onClickLeftButton={() => navigator('/objectives')}
      rightSecondButtonName={'Create'}
      onClickRightSecondButton={onCreateClick}
    >
      <Form className={styles.form}>
        {error && <Form.Label className={styles.error}>{error}</Form.Label>}
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Objective name..."
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>DeadLine</Form.Label>
          <DatePicker
            onChange={validateDeadline}
            value={deadline}
            className={styles.picker}
          />
        </Form.Group>
        <Form.Group className={styles.selectContainer}>
          <Form.Label>Priority*</Form.Label>
          <Dropdown onSelect={(priority) => priority && setPriority(priority)}>
            <Dropdown.Toggle className={styles.select} id="dropdown-basic">
              {`${priority ? priority : 'Select Priority'}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={'Low'}>Low</Dropdown.Item>
              <Dropdown.Item eventKey={'Medium'}>Medium</Dropdown.Item>
              <Dropdown.Item eventKey={'High'}>High</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group className={styles.selectContainer}>
          <Form.Label>Status</Form.Label>
          <Dropdown onSelect={(status) => status && setStatus(status)}>
            <Dropdown.Toggle className={styles.select} id="dropdown-basic">
              {`${status ? status : 'Select Status'}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey={'Active'}>Active</Dropdown.Item>
              <Dropdown.Item eventKey={'Paused'}>Paused</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        <Form.Group
          className={styles.textareaContainer}
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>Description</Form.Label>
          <Form.Control
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={styles.textarea}
            as="textarea"
            rows={3}
          />
        </Form.Group>
      </Form>
    </BottomLineLayout>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addObjective,
})(CreateNewObjective)
