import React from 'react'
import styles from './Location.module.scss'
import { Location as LocationType } from '../../types/location'
import IconButton from '../../../../../components/IconButton/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import { format } from 'date-fns'

interface LocationProps {
  location: LocationType
  onClick: () => void
  onEditLocation: (idea: LocationType) => void
  onDeleteLocation: (id?: string) => void
}

const Location = (props: LocationProps) => {
  const { location, onClick, onEditLocation, onDeleteLocation } = props

  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.topContainer}>
        <div className={styles.name}>{location.name}</div>
        <div className={styles.btnContainer}>
          <IconButton onClick={() => onEditLocation(location)}>
            <MdModeEdit size={22} />
          </IconButton>
          <IconButton onClick={() => onDeleteLocation(location.id)}>
            <MdDelete size={22} />
          </IconButton>
        </div>
      </div>
      {location?.map ? (
        <div>
          <a href={location.map} target="_blank" rel="noreferrer">
            {location.address}
          </a>
        </div>
      ) : (
        <div>{location.address}</div>
      )}
      <div className={styles.createdAt}>
        Created at: {format(new Date(location.createdAt), 'yyyy-MM-dd')}
      </div>
    </div>
  )
}

export default Location
