import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState } from '../../../../redux/store'
import styles from './FormAddWord.module.scss'
import { CreateWord, Word } from '../../types/vocabulary'
import Textarea from '../../../../components/Textarea/Textarea'
import { convertNodeToHtml } from '../../../../utils/convertNodeToHtml'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'

interface FormAddWordProps {
  title: string
  word?: Word
  vocabularyId?: number
  onClickCancel: () => void
  onAddWord?: (note: CreateWord) => void
  onUpdateWord?: (note: Word) => void
}

const FormAddWord = (props: FormAddWordProps) => {
  const { title, word, vocabularyId, onClickCancel, onAddWord, onUpdateWord } =
    props
  const [newWord, setNewWord] = useState<string>(word?.word || '')
  const [newTranslation, setNewTranslation] = useState<string>(
    word?.translation || '',
  )
  const [newDictionary, setNewDictionary] = useState<any>(
    word?.dictionary ? convertHtmlToNode(word.dictionary) : '',
  )

  const addWordHandler = () => {
    if (newWord && newTranslation && vocabularyId) {
      const word: CreateWord = {
        word: newWord,
        translation: newTranslation,
        createdAt: new Date().toISOString(),
        dictionary: newDictionary ? convertNodeToHtml(newDictionary) : '',
        vocabularyId,
      }
      onAddWord!(word)
      onClickCancel()
    }
  }

  const updateWordHandler = () => {
    if (newWord && newTranslation && word) {
      const updatedWord: Word = {
        id: word.id,
        word: newWord,
        translation: newTranslation,
        dictionary: newDictionary ? convertNodeToHtml(newDictionary) : '',
        createdAt: word.createdAt,
        vocabularyId: word.vocabularyId,
      }
      onUpdateWord!(updatedWord)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <div className={styles.table}>
        <div className={styles.form}>
          <h2 className={styles.title}>{title}</h2>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Control
              value={newWord}
              onChange={(e: any) => setNewWord(e.target.value)}
              placeholder="Word..."
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Control
              value={newTranslation}
              onChange={(e: any) => setNewTranslation(e.target.value)}
              placeholder="Translation..."
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Textarea
              value={newDictionary}
              placeholder="word dictionary..."
              setValue={setNewDictionary}
            />
          </Form.Group>
        </div>

        <div className={styles.btnContainer}>
          <Button onClick={onClickCancel} variant={'light'}>
            Cancel
          </Button>
          <div className={styles.btnSeparator}></div>
          {word ? (
            <Button
              onClick={updateWordHandler}
              className={styles.createBtn}
              variant="success"
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={addWordHandler}
              className={styles.createBtn}
              variant="success"
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {})(FormAddWord)
