import React from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { Option } from '../../types'
import styles from './MainSelect.module.scss'

interface MainSelectProps {
  label?: string
  placeholder?: string
  select: Option
  setSelect: (select: Option) => void
  selects: Option[]
  classNameRoot?: string
  classNameDropdown?: string
  required?: boolean
}

const MainSelect = (props: MainSelectProps) => {
  const {
    label = '',
    placeholder = '',
    select,
    setSelect,
    selects = [],
    classNameRoot = undefined,
    classNameDropdown = undefined,
    required = false,
  } = props

  return (
    <Form.Group className={classNameRoot ? classNameRoot : styles.root}>
      {label && (
        <Form.Label className={styles.label}>{`${label}${
          required && '*'
        }`}</Form.Label>
      )}
      <Dropdown
        className={classNameDropdown ? classNameDropdown : styles.dropdown}
        onSelect={(select) =>
          select && setSelect({ label: select, value: select })
        }
      >
        <Dropdown.Toggle className={styles.select} id="dropdown-basic">
          {`${select?.value ? select?.label : `Select ${placeholder}`}`}
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.menu}>
          {selects?.length > 0 ? (
            selects.map((sel) => (
              <Dropdown.Item
                key={sel.value}
                eventKey={sel.value}
                className={styles.item}
              >
                {sel.label}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item key={'null'} eventKey={'null'}>
              {'No items'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )
}

export default MainSelect
