import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import { connect } from 'react-redux'
import { addToDo, updateToDo } from '../../store/toDo.slice'
import { RootState } from '../../../../../redux/store'
import { convertNodeToHtml } from '../../../../../utils/convertNodeToHtml'
import { validateNotPastDate } from '../../../../../utils/validateDate'
import MainSelect from '../../../../../components/MainSelect/MainSelect'
import Textarea from '../../../../../components/Textarea/Textarea'
import styles from './FormAddNote.module.scss'
import { CreateToDo, ToDo } from '../../types/toDo'
import {
  toDoPriorityOptions,
  toDoStatusOptions,
} from '../../config/toDoInputSelectOptions'

interface FormAddNoteProps {
  note?: ToDo
  onClickCancel: () => void
  addToDo?: (note: CreateToDo) => void
  updateToDo?: (note: ToDo) => void
}

const FormAddNote = (props: FormAddNoteProps) => {
  const { note, onClickCancel, addToDo, updateToDo } = props

  const [name, setName] = useState<string>(note ? note.name : '')
  const [status, setStatus] = useState<string>(note ? note.status : '')
  const [description, setDescription] = useState<any>(
    note ? note.description : '',
  )
  const [priority, setPriority] = useState<string | null>(
    note ? note.priority : '',
  )
  const [startAt, setStartAt] = useState<Date | null>(
    note ? new Date(note.startAt) : null,
  )
  const [endAt, setEndAt] = useState<Date | null>(
    note && note.endAt ? new Date(note.endAt) : null,
  )

  const onSetStartDate = (date: Date) => {
    const validatedDate = validateNotPastDate(date)
    setStartAt(validatedDate)
  }

  const onSetEndDate = (date: Date) => {
    const validatedDate = validateNotPastDate(date)
    setEndAt(validatedDate)
  }

  const onAddNote = () => {
    if (name && convertNodeToHtml(description) && priority && startAt) {
      const note: CreateToDo = {
        name,
        description: convertNodeToHtml(description),
        createdAt: new Date().toISOString(),
        startAt: startAt.toISOString(),
        endAt: endAt ? endAt?.toISOString() : undefined,
        priority,
        status,
      }
      addToDo!(note)
      onClickCancel()
    }
  }

  const onUpdateNote = () => {
    if (name && convertNodeToHtml(description) && priority && startAt) {
      const updatedNote: ToDo = {
        id: note!.id,
        name,
        description: convertNodeToHtml(description),
        createdAt: new Date().toISOString(),
        startAt: startAt.toISOString(),
        endAt: endAt ? endAt?.toISOString() : undefined,
        priority,
        status,
      }
      updateToDo!(updatedNote)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="ToDo name..."
          className={styles.textInput}
        />
      </Form.Group>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Description*</Form.Label>
        <Textarea
          value={description}
          placeholder="ToDo description..."
          setValue={setDescription}
        />
      </div>
      <div className={styles.line}>
        <Form.Group>
          <Form.Label className={styles.label}>Start at*</Form.Label>
          <DatePicker
            onChange={onSetStartDate}
            value={startAt}
            className={styles.picker}
            maxDate={endAt || undefined}
          />
        </Form.Group>
      </div>
      <div className={styles.line}>
        <Form.Group>
          <Form.Label className={styles.label}>End at</Form.Label>
          <DatePicker
            onChange={onSetEndDate}
            value={endAt}
            className={styles.picker}
            minDate={startAt || undefined}
          />
        </Form.Group>
      </div>
      <div className={styles.line}>
        <MainSelect
          label={'Priority'}
          select={
            priority
              ? { label: priority, value: priority }
              : { label: '', value: '' }
          }
          setSelect={(p) => setPriority(p.value as string)}
          selects={toDoPriorityOptions}
          required
        />
      </div>
      <div className={styles.line}>
        <MainSelect
          label={'Status'}
          select={
            status ? { label: status, value: status } : { label: '', value: '' }
          }
          setSelect={(p) => setStatus(p.value as string)}
          selects={toDoStatusOptions}
          required
        />
      </div>
      <div className={styles.btnContainer}>
        {note ? (
          <Button
            onClick={onUpdateNote}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddNote}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addToDo,
  updateToDo,
})(FormAddNote)
