import React, { useState } from 'react'
import styles from './FormAddLocation.module.scss'
import { CreateLocation, Location } from '../../types/location'
import { connect } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { addLocation, updateLocation } from '../../store/location.slice'
import { Button, Form } from 'react-bootstrap'
import MainSelect from '../../../../../components/MainSelect/MainSelect'
import locationTypeOptions from '../../config/locationInputSelectOptions'
import Textarea from '../../../../../components/Textarea/Textarea'
import { convertHtmlToNode } from '../../../../../utils/convertHtmlToNode'
import { convertNodeToHtml } from '../../../../../utils/convertNodeToHtml'

interface FormAddLocationProps {
  location?: Location
  onClickCancel: () => void
  addLocation?: (location: CreateLocation) => void
  updateLocation?: (location: Location) => void
}

const FormAddLocation = (props: FormAddLocationProps) => {
  const { location, onClickCancel, addLocation, updateLocation } = props

  const [name, setName] = useState<string>(location ? location.name : '')
  const [address, setAddress] = useState<string>(
    location ? location.address : '',
  )
  const [map, setMap] = useState<string>(location ? location.map : '')
  const [type, setType] = useState<string>(location ? location.type : '')
  const [city, setCity] = useState<string>(location ? location.city : '')
  const [note, setNote] = useState<any>(
    location ? convertHtmlToNode(location?.note || '') : '',
  )

  const onAddLocation = () => {
    if (name && address) {
      const location: CreateLocation = {
        name,
        address,
        map,
        type,
        city,
        note: note ? convertNodeToHtml(note) : '',
      }
      addLocation!(location)
      onClickCancel()
    }
  }

  const onUpdateLocation = () => {
    if (name && address) {
      const updatedLocation: Location = {
        ...location!,
        name,
        address,
        map,
        type,
        city,
        note: note ? convertNodeToHtml(note) : '',
      }
      updateLocation!(updatedLocation)
      onClickCancel()
    }
    onClickCancel()
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          placeholder="Location name..."
          className={styles.textInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Address*</Form.Label>
        <Form.Control
          value={address}
          onChange={(e: any) => setAddress(e.target.value)}
          placeholder="Location address..."
          className={styles.textInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Map</Form.Label>
        <Form.Control
          value={map}
          onChange={(e: any) => setMap(e.target.value)}
          placeholder="Location map..."
          className={styles.textInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>City</Form.Label>
        <Form.Control
          value={city}
          onChange={(e: any) => setCity(e.target.value)}
          placeholder="Location city..."
          className={styles.textInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <MainSelect
          label={'Type'}
          select={
            type ? { label: type, value: type } : { label: '', value: '' }
          }
          setSelect={(p) => setType(p.value as string)}
          selects={locationTypeOptions}
          required
        />
      </Form.Group>
      <div className={styles.line}>
        <Form.Label className={styles.label}>Description*</Form.Label>
        <Textarea
          value={note}
          placeholder="ToDo description..."
          setValue={setNote}
        />
      </div>
      <div className={styles.btnContainer}>
        {location ? (
          <Button
            onClick={onUpdateLocation}
            className={styles.createBtn}
            variant="success"
            disabled={!name || !address || !type || !city}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddLocation}
            className={styles.createBtn}
            variant="success"
            disabled={!name || !address || !type || !city}
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({})

export default connect(mapStateToProps, {
  addLocation,
  updateLocation,
})(FormAddLocation)
