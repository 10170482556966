import React, { useEffect, useState } from 'react'
import styles from './LocationsPage.module.scss'
import IconButton from '../../../../components/IconButton/IconButton'
import { MdOutlineLocationOn } from 'react-icons/md'
import { RootState } from '../../../../redux/store'
import { Location as LocationType } from '../types/location'
import { connect } from 'react-redux'
import { deleteLocation, getLocations } from '../store/location.slice'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import Confirm from '../../../../components/Confirm/Confirm'
import Location from '../components/Location/Location'
import FormAddLocation from '../components/FormAddLocation/FormAddLocation'
import LocationDetails from '../components/LocationDetails/LocationDetails'

export interface LocationsPageProps {
  locations?: LocationType[]
  getLocations: () => void
  deleteLocation: (id: string) => void
}

const LocationsPage = (props: LocationsPageProps) => {
  const { locations, getLocations, deleteLocation } = props
  const [isActiveAddLocationTable, setIsActiveAddLocationTable] =
    useState<boolean>(false)
  const [isActiveEditLocationTable, setIsActiveEditLocationTable] =
    useState<boolean>(false)
  const [selectedLocation, setSelectedLocation] = useState<
    LocationType | undefined
  >()
  const [selectedLocationId, setSelectedLocationId] = useState<
    string | undefined
  >()
  const [locationDetails, setLocationDetails] = useState<
    LocationType | undefined
  >()

  useEffect(() => {
    getLocations()
  }, [])

  const onOpenAdLocationTable = () => {
    setIsActiveAddLocationTable(true)
  }

  const onClickCancel = () => {
    setIsActiveAddLocationTable(false)
  }

  const onEditLocation = (location: LocationType) => {
    setSelectedLocation(location)
    setIsActiveEditLocationTable(true)
  }

  const onDeleteLocation = () => {
    if (selectedLocationId) {
      deleteLocation(selectedLocationId)
      setSelectedLocationId(undefined)
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.locationTop}>
        <div title={'Add Location'} className={styles.addLocation}>
          <IconButton onClick={onOpenAdLocationTable}>
            <MdOutlineLocationOn className={styles.icon} size={30} />
          </IconButton>
        </div>
      </div>
      <div className={styles.locationContainer}>
        <div className={styles.locations}>
          {locations &&
            locations.map((location, index) => (
              <div className={styles.location} key={location.id}>
                <Location
                  location={location}
                  onClick={() => setLocationDetails(location)}
                  onEditLocation={onEditLocation}
                  onDeleteLocation={setSelectedLocationId}
                  key={`${index}-${location.id}-location`}
                />
              </div>
            ))}
        </div>

        <RightDrawer
          title={'Add Location'}
          size="l"
          show={isActiveAddLocationTable}
          onHide={onClickCancel}
        >
          <FormAddLocation onClickCancel={onClickCancel} />
        </RightDrawer>

        <RightDrawer
          title={'Edit Location'}
          size="l"
          show={isActiveEditLocationTable}
          onHide={() => setIsActiveEditLocationTable(false)}
        >
          {isActiveEditLocationTable && selectedLocation && (
            <FormAddLocation
              location={selectedLocation}
              onClickCancel={() => setIsActiveEditLocationTable(false)}
            />
          )}
        </RightDrawer>
        <RightDrawer
          title={locationDetails ? locationDetails.name : ''}
          size="l"
          show={!!locationDetails}
          onHide={() => setLocationDetails(undefined)}
        >
          {locationDetails && <LocationDetails location={locationDetails} />}
        </RightDrawer>

        {selectedLocationId && (
          <Confirm
            confirm="Are you really want to delete this Location?"
            confirmBtnLabel="Delete"
            confirmBtnVariant="danger"
            onConfirm={onDeleteLocation}
            onCancel={() => setSelectedLocationId(undefined)}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  locations: state.location.locations,
})

export default connect(mapStateToProps, {
  getLocations,
  deleteLocation,
})(LocationsPage)
