import { Option } from '../../../types'

export const eventPriorityOptions: Option[] = [
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'Low',
    value: 'Low',
  },
]

export const eventPeriodicityOptions: Option[] = [
  {
    label: 'Once',
    value: 'Once',
  },
  {
    label: 'Every Year',
    value: 'Every Year',
  },
  {
    label: 'By Dates',
    value: 'By Dates',
  },
  {
    label: 'Period',
    value: 'Period',
  },
]

export const eventTypesOptions: Option[] = [
  {
    label: 'To Do',
    value: 'To Do',
  },
  {
    label: 'To Visit',
    value: 'To Visit',
  },
  {
    label: 'Vacation',
    value: 'Vacation',
  },
  {
    label: 'Birthday',
    value: 'Birthday',
  },
  {
    label: 'Death',
    value: 'Death',
  },
  {
    label: 'Wedding',
    value: 'Wedding',
  },
  {
    label: 'Other',
    value: 'Other',
  },
]
