import { Step } from '../types/objective'

// sort by date
export const sortObjectivesByDate = (step: Step[], type: string): Step[] => {
  const newSteps = [...step]
  return type === 'New'
    ? newSteps.sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1
        }
        return 0
      })
    : newSteps.sort((a, b) => {
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return -1
        }
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return 1
        }
        return 0
      })
}

// sort by status
export const sortStepsByStatus = (steps: Step[], status: string): Step[] => {
  const activeSteps = sortObjectivesByDate(
    steps.filter((objective: Step) => objective.status === 'Active'),
    'New',
  )
  const passiveSteps = sortObjectivesByDate(
    steps.filter((objective: Step) => objective.status === 'Passive'),
    'New',
  )
  const finishedSteps = sortObjectivesByDate(
    steps.filter((objective: Step) => objective.status === 'Finished'),
    'New',
  )
  const sortedSteps: Step[] = []

  if (status === 'Active') {
    if (activeSteps) {
      sortedSteps.push(...activeSteps)
    }

    if (passiveSteps) {
      sortedSteps.push(...passiveSteps)
    }

    if (finishedSteps) {
      sortedSteps.push(...finishedSteps)
    }
  }

  if (status === 'Passive') {
    if (passiveSteps) {
      sortedSteps.push(...passiveSteps)
    }

    if (finishedSteps) {
      sortedSteps.push(...finishedSteps)
    }

    if (activeSteps) {
      sortedSteps.push(...activeSteps)
    }
  }

  if (status === 'Finished') {
    if (finishedSteps) {
      sortedSteps.push(...finishedSteps)
    }

    if (activeSteps) {
      sortedSteps.push(...activeSteps)
    }

    if (passiveSteps) {
      sortedSteps.push(...passiveSteps)
    }
  }

  return sortedSteps
}
