import React, { useState } from 'react'
import styles from './FormAddObjective.module.scss'
import { Button, Dropdown, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { CreateObjective, Objective } from '../../types/objective'
import { convertNodeToHtml } from '../../../../utils/convertNodeToHtml'
import { addObjective, updateObjective } from '../../store/objective.slice'
import { RootState } from '../../../../redux/store'
import DatePicker from 'react-date-picker'
import Textarea from '../../../../components/Textarea/Textarea'
import { convertHtmlToNode } from '../../../../utils/convertHtmlToNode'
import RightDrawer from '../../../../components/RightDrawer/RightDrawer'
import FormAddWhiteboard from '../../../Whiteboard/components/FormAddObjective/FormAddWhiteboard'
import {
  deleteWhiteboard,
  setWhiteboard,
} from '../../../Whiteboard/store/whiteboard.slice'
import { Whiteboard } from '../../../Whiteboard/types/whiteboard'
import { useNavigate } from 'react-router-dom'

interface FormAddObjectiveProps {
  objective?: Objective
  whiteboard?: Whiteboard
  onClickCancel: () => void
  addObjective?: (objective: CreateObjective) => void
  updateObjective?: (objective: Objective) => void
  setWhiteboard: (whiteboard?: Whiteboard) => void
  deleteWhiteboard: (id: string) => void
}

const FormAddObjective = (props: FormAddObjectiveProps) => {
  const { objective, onClickCancel, addObjective, updateObjective } = props

  const [name, setName] = useState<string>(objective?.name || '')
  const [deadline, setDeadline] = useState<Date | undefined>(
    objective?.deadline ? new Date(objective?.deadline) : undefined,
  )
  const [priority, setPriority] = useState<string>(
    objective?.priority || 'Medium',
  )
  const [status, setStatus] = useState<string>(objective?.status || 'Paused')
  const [description, setDescription] = useState<any>(
    objective?.description ? convertHtmlToNode(objective.description) : '',
  )

  const onAddObjective = async () => {
    if (name && status && priority && addObjective) {
      const createdObjective: CreateObjective = {
        name,
        createdAt: new Date().toISOString(),
        deadline: deadline ? deadline.toISOString() : '',
        steps: [],
        priority,
        status,
        description: description ? convertNodeToHtml(description) : '',
      }

      await addObjective(createdObjective)

      setName('')
      setDeadline(undefined)
      setPriority('')
      setStatus('')
      setDescription('')
      onClickCancel()
    }
  }

  const onUpdateObjective = async () => {
    if (objective && name && status && priority && updateObjective) {
      const updatedObjective: Objective = {
        ...objective,
        name,
        deadline: deadline ? deadline.toISOString() : '',
        priority,
        status,
        description: description ? convertNodeToHtml(description) : '',
      }
      console.log('updatedObjective', updatedObjective)

      await updateObjective(updatedObjective)
      setName('')
      setDeadline(undefined)
      setPriority('')
      setStatus('')
      setDescription('')
      onClickCancel()
    }
  }

  return (
    <div className={styles.root}>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className={styles.label}>Name*</Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Objective name..."
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className={styles.label}>DeadLine</Form.Label>
        <DatePicker
          onChange={setDeadline}
          value={deadline}
          className={styles.picker}
          minDate={new Date(Date.now())}
        />
      </Form.Group>
      <Form.Group className={styles.selectContainer}>
        <Form.Label className={styles.label}>Priority*</Form.Label>
        <Dropdown onSelect={(priority) => priority && setPriority(priority)}>
          <Dropdown.Toggle className={styles.select} id="dropdown-basic">
            {`${priority ? priority : 'Select Priority'}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={'Low'}>Low</Dropdown.Item>
            <Dropdown.Item eventKey={'Medium'}>Medium</Dropdown.Item>
            <Dropdown.Item eventKey={'High'}>High</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group className={styles.selectContainer}>
        <Form.Label className={styles.label}>Status</Form.Label>
        <Dropdown onSelect={(status) => status && setStatus(status)}>
          <Dropdown.Toggle className={styles.select} id="dropdown-basic">
            {`${status ? status : 'Select Status'}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey={'Active'}>Active</Dropdown.Item>
            <Dropdown.Item eventKey={'Paused'}>Paused</Dropdown.Item>
            <Dropdown.Item eventKey={'Finished'}>Finished</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      <Form.Group
        className={styles.textareaContainer}
        controlId="exampleForm.ControlTextarea1"
      >
        <Form.Label className={styles.label}>Description</Form.Label>
        <Textarea
          value={description}
          placeholder="event description..."
          setValue={setDescription}
        />
      </Form.Group>

      <div className={styles.btnContainer}>
        {objective ? (
          <Button
            onClick={onUpdateObjective}
            className={styles.createBtn}
            variant="success"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={onAddObjective}
            className={styles.createBtn}
            variant="success"
          >
            Create
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  whiteboard: state.whiteboard.whiteboard,
})

export default connect(mapStateToProps, {
  addObjective,
  updateObjective,
  setWhiteboard,
  deleteWhiteboard,
})(FormAddObjective)
